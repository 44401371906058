import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Spinner from "../../common/Spinner";
import UserTimesheetRow from "./UserTimesheetRow";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import {
  getTimesheetRows,
  getUsers,
  getTimesheetDates,
  getBambooCSV,
  clearUsers,
  clearUserTimesheets
} from "../../../actions/adminActions";
import DropDownGroup from "../../common/DropDownGroup";
import moment from "moment-timezone";
import flattenDeep from "../../common/flattenDeep";
import {
  Button,
  Row,
  Col,
  Table,
  CardPanel,
  Card,
  Icon,
  Checkbox
} from "react-materialize";
import classnames from "classnames";

const today = new Date(moment());
const Sunday = moment(today).add(-1 * today.getDay(), "day");
const Saturday = moment(today).add(-1 * today.getDay() + 6, "day");
const SundayStart = Sunday;
const SaturdayEnd = Saturday;

class ManageTimesheets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "Submitted",
      bambooHeaders: [
        { label: "Employee #", key: "employeeId" },
        { label: "Time Entry ID (unique)", key: "entryId" },
        { label: "Hours Worked Date", key: "hoursWorkedDate" },
        { label: "Rate Type (REG or OT)", key: "rateType" },
        { label: "Hours Worked", key: "hoursWorked" }
      ],
      headers: [
        { label: "Name", key: "name" },
        { label: "Project", key: "project" },
        { label: "Task", key: "task" },
        { label: "Description", key: "description" },
        { label: "Sunday", key: "sunday" },
        { label: "Monday", key: "monday" },
        { label: "Tuesday", key: "tuesday" },
        { label: "Wednesday", key: "wednesday" },
        { label: "Thursday", key: "thursday" },
        { label: "Friday", key: "friday" },
        { label: "Saturday", key: "saturday" },
        { label: "Total", key: "total" }
      ],
      currentDate: new Date(moment().tz("America/Chicago")),
      Sunday: Sunday,
      Saturday: Saturday,
      thisWeek: `${Sunday.format("YYYY-MM-DD")},${Saturday.format(
        "YYYY-MM-DD"
      )}`,
      datesForDropDown: [],
      currentStartOfWeek: Sunday.format("YYYY-MM-DD"),
      currentEndOfWeek: Saturday.format("YYYY-MM-DD"),
      bulkDownload: [],
      numSelected: 0,
      removeCheck: false,
      addCheck: false,
      isGroupSelected: false
    };
    this.onDateSelect = this.onDateSelect.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.isAdmin) {
        this.props.getTimesheetRows(
          SundayStart.format("YYYY-MM-DD"),
          SaturdayEnd.format("YYYY-MM-DD")
        );
        this.props.getBambooCSV(
          SundayStart.format("YYYY-MM-DD"),
          SaturdayEnd.format("YYYY-MM-DD")
        );
        this.props.getUsers(
          SundayStart.format("YYYY-MM-DD"),
          SaturdayEnd.format("YYYY-MM-DD")
        );
        this.props.getTimesheetDates();
      } else {
        this.props.history.push("/timesheet");
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (
        this.props.admin.adminTimesheetRows !==
        prevProps.admin.adminTimesheetRows
      ) {
        this.setState({
          adminTimesheetRows: this.props.admin.adminTimesheetRows
        });
        let csvData = [];
        this.props.admin.adminTimesheetRows.forEach(csv => {
          if (csv.isSubmitted) {
            csvData.push([
              {
                name: csv.user_name,
                project: csv.project_name,
                task: csv.task_name,
                description: csv.description,
                sunday: csv.sunday !== 0 ? csv.sunday : "",
                monday: csv.monday !== 0 ? csv.monday : "",
                tuesday: csv.tuesday !== 0 ? csv.tuesday : "",
                wednesday: csv.wednesday !== 0 ? csv.wednesday : "",
                thursday: csv.thursday !== 0 ? csv.thursday : "",
                friday: csv.friday !== 0 ? csv.friday : "",
                saturday: csv.saturday !== 0 ? csv.saturday : "",
                total: csv.total !== 0 ? csv.total : ""
              }
            ]);
          }
        });
        this.setState({
          data: flattenDeep(csvData),
          fileName: `Submitted-${moment(this.state.currentEndOfWeek).format(
            "MMDDYYYY"
          )}.csv`
        });
      }
      if (this.props.admin.adminDates !== prevProps.admin.adminDates) {
        let datesForSelection = [];
        flattenDeep(this.props.admin.adminDates).forEach(prettier => {
          datesForSelection.push([
            {
              label:
                moment(prettier._id.startDate).format("MMMM Do YYYY") +
                " - " +
                moment(prettier._id.endDate).format("MMMM Do YYYY"),
              value:
                moment(prettier._id.startDate).format("YYYY-MM-DD") +
                "," +
                moment(prettier._id.endDate).format("YYYY-MM-DD")
            }
          ]);
        });
        this.setState({
          datesForDropDown: flattenDeep(datesForSelection)
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  componentWillUnmount() {
    this.props.clearUsers();
    this.props.clearUserTimesheets();
  }

  onDateSelect = e => {
    this.setState({ dateRange: e });
    let newDateRange = e.split(",");
    this.setState(
      {
        selectedOption: "Submitted",
        currentStartOfWeek: newDateRange[0],
        currentEndOfWeek: newDateRange[1],
        bulkDownload: [],
        numSelected: 0,
        isGroupSelected: false
      },
      () => {
        this.props.getTimesheetRows(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
        this.props.getBambooCSV(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
        this.props.getUsers(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
      }
    );
  };

  onUserSelect = (isChecked, userID, timesheetRows) => {
    if (isChecked) {
      this.setState(prevState => {
        prevState.bulkDownload = prevState.bulkDownload.concat(timesheetRows);
        prevState.numSelected = ++prevState.numSelected;
        return {
          bulkDownload: prevState.bulkDownload,
          numSelected: prevState.numSelected,
          isGroupSelected:
            prevState.numSelected ===
            this.props.admin.adminUserTimesheets
              .map(x => x.user_id)
              .filter(
                (item, index, self) =>
                  self.indexOf(item) === index && item !== undefined
              ).length
              ? true
              : false,
          removeCheck: false
        };
      });
    } else {
      this.setState(prevState => {
        prevState.bulkDownload = prevState.bulkDownload.filter(
          rows => rows.userID !== userID
        );
        prevState.numSelected = --prevState.numSelected;
        return {
          bulkDownload: prevState.bulkDownload,
          numSelected: prevState.numSelected,
          isGroupSelected:
            prevState.numSelected ===
            this.props.admin.adminUserTimesheets
              .map(x => x.user_id)
              .filter(
                (item, index, self) =>
                  self.indexOf(item) === index && item !== undefined
              ).length
              ? true
              : false,
          removeCheck: false,
          addCheck: false
        };
      });
    }
  };

  onAllSelect = () => {
    if (this.state.isGroupSelected) {
      this.clearDownloads();
    } else {
      let returnedRows = this.props.admin.adminTimesheetRows.map(row => {
        return {
          ...row,
          userID: row.user_id,
          name: row.user_name,
          project: row.project_name,
          task: row.task_name,
          sunday: row.sunday !== 0 ? row.sunday : "",
          monday: row.monday !== 0 ? row.monday : "",
          tuesday: row.tuesday !== 0 ? row.tuesday : "",
          wednesday: row.wednesday !== 0 ? row.wednesday : "",
          thursday: row.thursday !== 0 ? row.thursday : "",
          friday: row.friday !== 0 ? row.friday : "",
          saturday: row.saturday !== 0 ? row.saturday : ""
        };
      });

      this.setState({
        bulkDownload: flattenDeep(returnedRows),
        numSelected: flattenDeep(returnedRows)
          .map(x => x.userID)
          .filter((item, index, self) => self.indexOf(item) === index).length,
        isGroupSelected: true,
        addCheck: true,
        removeCheck: false
      });
    }
  };

  clearDownloads = () => {
    this.setState({
      bulkDownload: [],
      numSelected: 0,
      removeCheck: true,
      addCheck: false,
      isGroupSelected: false
    });
  };

  render() {
    const { adminUsers, adminUsersLoading } = this.props.admin;
    let bambooCSV, ManageUserTableContent, ManageUserTable;
    if (adminUsers === null || adminUsersLoading) {
      ManageUserTable = <Spinner />;
    } else {
      ManageUserTableContent = adminUsers.map(userRow => (
        <UserTimesheetRow
          key={userRow._id}
          id={userRow._id}
          name={userRow.lastName + ", " + userRow.firstName}
          firstName={userRow.firstName}
          downloadName={
            userRow.firstName.substring(0, 1) + userRow.lastName.substring(0, 1)
          }
          startOfWeek={this.state.currentStartOfWeek}
          endOfWeek={this.state.currentEndOfWeek}
          onUserSelect={this.onUserSelect}
          removeCheck={this.state.removeCheck}
          addCheck={this.state.addCheck && this.state.isGroupSelected}
          dropdownDates={this.state.datesForDropDown}
          email={userRow.email}
        />
      ));
      if (this.props.admin.adminBambooCSV !== []) {
        bambooCSV = (
          <CSVLink
            data={this.props.admin.adminBambooCSV}
            headers={this.state.bambooHeaders}
            className={classnames("ml-4 btn-flat right", {
              hide: this.state.bulkDownload.length > 0
            })}
            filename={"hours_import.csv"}
          >
            Bamboo Export
          </CSVLink>
        );
      }

      ManageUserTable = (
        <Fragment>
          <Card className="tableCard">
            <CardPanel
              className={classnames("tableHeaderBar", {
                "light-grey": this.state.numSelected > 0
              })}
              style={{ borderBottom: "1px solid #E1E2E1" }}
            >
              <div style={{ fontSize: "16px", height: "36px" }}>
                <Button
                  flat
                  className={classnames("left light-grey blue-text", {
                    hide: this.state.numSelected === 0
                  })}
                  onClick={this.clearDownloads}
                  style={{ textTransform: "none" }}
                >
                  {this.state.numSelected} user
                  {this.state.numSelected > 1 ? "s" : ""} selected
                  <Icon right>close</Icon>
                </Button>
                <CSVLink
                  data={this.state.bulkDownload.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )}
                  headers={this.state.headers}
                  className={classnames("btn-flat light-grey right", {
                    hide: this.state.bulkDownload.length === 0
                  })}
                  filename={"timesheets.csv"}
                >
                  <Icon>get_app</Icon>
                </CSVLink>
                <CSVLink
                  data={flattenDeep(
                    this.props.admin.adminTimesheetRows
                      .map(csv => {
                        if (csv.isSubmitted) {
                          return {
                            ...csv,
                            name: csv.user_name,
                            project: csv.project_name,
                            task: csv.task_name,
                            description: csv.description,
                            sunday: csv.sunday !== 0 ? csv.sunday : "",
                            monday: csv.monday !== 0 ? csv.monday : "",
                            tuesday: csv.tuesday !== 0 ? csv.tuesday : "",
                            wednesday: csv.wednesday !== 0 ? csv.wednesday : "",
                            thursday: csv.thursday !== 0 ? csv.thursday : "",
                            friday: csv.friday !== 0 ? csv.friday : "",
                            saturday: csv.saturday !== 0 ? csv.saturday : "",
                            total: csv.total !== 0 ? csv.total : ""
                          };
                        } else return [];
                      })
                      .sort((a, b) => (a.name > b.name ? 1 : -1))
                  )}
                  headers={this.state.headers}
                  className={classnames("btn white right blue-text", {
                    hide: this.state.bulkDownload.length > 0
                  })}
                  filename={`Submitted-${moment(
                    this.state.currentEndOfWeek
                  ).format("MMDDYYYY")}.csv`}
                >
                  Submitted
                </CSVLink>
                {bambooCSV}
              </div>
            </CardPanel>
            <Table centered hoverable className="white">
              <thead>
                <tr>
                  <th className="center-align" style={{ padding: 0 }}>
                    <Checkbox
                      filledIn
                      checked={this.state.isGroupSelected}
                      className="blue-text center-align headerCheck"
                      label=""
                      value=""
                      key={
                        `${this.props.startOfWeek}` +
                        `${this.props.endOfWeek}` +
                        `${this.state.isGroupSelected}`
                      }
                      onChange={() => this.onAllSelect()}
                      disabled={
                        this.props.admin.adminUserTimesheets
                          .map(x => x.user_id)
                          .filter(
                            (item, index, self) =>
                              self.indexOf(item) === index && item !== undefined
                          ).length === 0
                      }
                    />
                  </th>
                  <th className="text-left">Name</th>
                  <th className="text-left">Timesheet Status</th>
                  <th className="text-left">Last Modified</th>
                  <th className="text-right" style={{ width: "40%" }} />
                </tr>
              </thead>
              <tbody>{ManageUserTableContent}</tbody>
            </Table>
          </Card>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Card className="cardSmallBar mb-4">Timesheets</Card>
        <div className="admin-container">
          <Row className="valign-wrapper">
            <Col s={6} l={1}>
              <span
                style={{
                  fontSize: "16px",
                  textWeight: "500"
                }}
              >
                Viewing Week:
              </span>
            </Col>
            <Col s={6} l={3}>
              <DropDownGroup
                options={this.state.datesForDropDown}
                onSelect={this.onDateSelect}
                selectedOption={this.state.thisWeek}
                clearable={false}
                searchable={true}
              />
            </Col>
            <Col l={8} />
          </Row>
          {ManageUserTable}
        </div>
      </Fragment>
    );
  }
}

ManageTimesheets.propTypes = {
  getTimesheetRows: PropTypes.func,
  getUsers: PropTypes.func,
  getTimesheetDates: PropTypes.func,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  admin: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  admin: state.admin
});

export default connect(
  mapStateToProps,
  {
    getTimesheetRows,
    getUsers,
    getBambooCSV,
    getTimesheetDates,
    clearUsers,
    clearUserTimesheets
  }
)(ManageTimesheets);
