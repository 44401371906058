import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { clearTimesheet } from '../../actions/timesheetActions';
import { clearSuccesses } from '../../actions/timesheetActions';
import { clearHasSeenRelease } from '../../actions/userActions';
import aeon from '../../img/logo_WhiteBackground.svg';
import {
  SideNav,
  Button,
  Icon,
  CardPanel,
  Row,
  Col,
  Collapsible,
  CollapsibleItem,
  Dropdown,
} from 'react-materialize';

import md5 from 'md5';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: null,
      quotes: [],
    };
  }

  componentDidMount() {
    let positiveQuotes = [],
      negativeQuotes = [];
    if (this.state.quote === null) {
      positiveQuotes.push(
        <span>
          Follow your <i className='em em-heart' />
        </span>
      );
      positiveQuotes.push(
        <span>
          Get some <i className='em em-coffee' />
        </span>
      );
      positiveQuotes.push(<span>Way to go</span>);
      positiveQuotes.push(<span>Believe in yourself</span>);
      positiveQuotes.push(
        <span>
          Hang in there <i className='em em-smiley_cat' />
        </span>
      );
      positiveQuotes.push(
        <span>
          Get it! <i className='em em-woman-tipping-hand' />
        </span>
      );
      positiveQuotes.push(<span>You're doing great</span>);
      positiveQuotes.push(<span>You can do anything</span>);
      positiveQuotes.push(<span>Today is the day</span>);
      positiveQuotes.push(<span>Work hard, stay humble</span>);
      positiveQuotes.push(<span>Smile</span>);
      positiveQuotes.push(<span>Make today amazing</span>);
      positiveQuotes.push(<span>Think big</span>);
      positiveQuotes.push(<span>Maybe swearing will help</span>);
      positiveQuotes.push(<span>Good vibes only</span>);
      positiveQuotes.push(<span>Hello</span>);
      positiveQuotes.push(<span>Cheers</span>);
      positiveQuotes.push(<span>Only set the bar low for limbo</span>);
      positiveQuotes.push(<span>Maybe it’s time for a coffee break</span>);
      positiveQuotes.push(<span>Good vibes only</span>);
      positiveQuotes.push(<span>You got this</span>);
      positiveQuotes.push(<span>It’s going to be a great day</span>);
      positiveQuotes.push(<span>You’re doing great things</span>);
      positiveQuotes.push(<span>No guts, no glory</span>);
      positiveQuotes.push(<span>Enjoy today</span>);
      positiveQuotes.push(<span>Keep it cool</span>);
      positiveQuotes.push(<span>No pain, no gain</span>);
      positiveQuotes.push(<span>Does anyone even read these</span>);
      positiveQuotes.push(<span>Live passionately</span>);
      positiveQuotes.push(
        <span>
          It’s never too early for ice cream <i className='em em-ice_cream' />
        </span>
      );
      positiveQuotes.push(<span>Just keep swimming</span>);
      positiveQuotes.push(
        <span>
          BAZINGA! <i className='em em-zap' />
        </span>
      );
      positiveQuotes.push(<span>Choose the positive</span>);
      positiveQuotes.push(<span>May the Force be with you</span>);
      positiveQuotes.push(<span>There's no angry way to say bubbles</span>);
      positiveQuotes.push(
        <span>
          It’s better now that you’re here <i className='em em-sauropod' />
        </span>
      );
      positiveQuotes.push(<span>Kick back, relax</span>);
      positiveQuotes.push(<span>You’ve got awesome in your DNA</span>);
      positiveQuotes.push(<span>You can do it!</span>);
      positiveQuotes.push(<span>Prove them wrong today</span>);
      positiveQuotes.push(
        <span>
          Always eat dessert first <i className='em em-cake' />
        </span>
      );
      positiveQuotes.push(
        <span>
          Good luck on your quest <i className='em em-female_mage' />
        </span>
      );

      negativeQuotes.push(<span>Figure it out already</span>);
      negativeQuotes.push(
        <span>I'm jealous of people that don't know you</span>
      );
      negativeQuotes.push(
        <span>
          You're an oxygen thief <i className='em em-balloon' />
        </span>
      );
      negativeQuotes.push(
        <span>
          Why don't you go play in traffic <i className='em em-bus' />
        </span>
      );
      negativeQuotes.push(
        <span>
          Shock me, say something intelligent <i className='em em-t-rex' />
        </span>
      );
      negativeQuotes.push(<span>I don't want to talk about it</span>);
      negativeQuotes.push(
        <span>
          Why, you stuck up, half-witted, scruffy-looking… Nerf herder
        </span>
      );
      negativeQuotes.push(
        <span>You're about as much use as a condom machine in the Vatican</span>
      );
      negativeQuotes.push(
        <span>
          Knees weak, arms are heavy <i className='em em-spaghetti' />
        </span>
      );
      negativeQuotes.push(
        <span>
          I'm gonna take my <i className='em em-racehorse' /> to the old town
          road
        </span>
      );
      negativeQuotes.push(<span>We all live in a yellow submarine</span>);
      negativeQuotes.push(<span>You have Van Gogh's ear for music</span>);
      negativeQuotes.push(<span>We need a new plague</span>);

      /**
       * Eric did not want this quote for e2i.
       */
      // positiveQuotes.push(
      //   <span>
      //     Oh, for <i className="em em-fox_face" /> sake
      //   </span>
      // );

      let tempQuote =
        positiveQuotes[Math.floor(Math.random() * positiveQuotes.length)];

      let negativeTempQuote =
        negativeQuotes[Math.floor(Math.random() * negativeQuotes.length)];

      this.setState({
        quote: tempQuote,
        negativeQuote: negativeTempQuote,
        quotes: positiveQuotes,
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.auth.isAuthenticated !== prevProps.auth.isAuthenticated) {
      if (this.props.auth.isAuthenticated === false)
        this.setState({ quote: null });
      else {
        let tempQuote =
          this.state.quotes[
            Math.floor(Math.random() * this.state.quotes.length)
          ];

        this.setState({ quote: tempQuote });
      }
    }
  }

  componentWillUnmount() {
    this.setState({ quote: null });
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearTimesheet();
    this.props.clearSuccesses();
    this.props.logoutUser();
    this.props.clearHasSeenRelease();
  }

  render() {
    const { user } = this.props.auth;
    let adminLink, supervisorLink;
    let imageSource = '';
    if (user.isAdmin) {
      adminLink = (
        <Collapsible>
          <CollapsibleItem
            header='Administration'
            icon={<Icon>build</Icon>}
            style={{ paddingLeft: '0', paddingRight: '0' }}
            className='NavCollapsible'
          >
            <ul>
              <li>
                <NavLink
                  className='black-text sidenav-close'
                  to='/admin/projects'
                  style={{ paddingLeft: '88px' }}
                >
                  Projects
                </NavLink>
              </li>
              <li>
                <NavLink
                  className='black-text sidenav-close'
                  to='/admin/timesheets'
                  style={{ paddingLeft: '88px' }}
                >
                  Timesheets
                </NavLink>
              </li>
              <li>
                <NavLink
                  className='black-text sidenav-close'
                  to='/admin/users'
                  style={{ paddingLeft: '88px' }}
                >
                  Users
                </NavLink>
              </li>
            </ul>
          </CollapsibleItem>
        </Collapsible>
      );
    }
    if (user.isSupervisor) {
      supervisorLink = (
        <NavLink to='/myteam' className='sidenav-close'>
          <Icon>group</Icon> My Team
        </NavLink>
      );
    }

    if (this.props.auth.user.email) {
      imageSource = `https://${this.props.auth.user.email.substring(
        this.props.auth.user.email.indexOf('@') + 1,
        this.props.auth.user.email.indexOf('.')
      )}.bamboohr.com/employees/photos/?h=${md5(
        this.props.auth.user.email ? this.props.auth.user.email : ''
      )}`;
    }

    return (
      <CardPanel
        className='blue'
        style={{
          marginTop: '0',
          marginBottom: '0',
          paddingTop: '12px',
          paddingBottom: '0',
          height: '64px',
        }}
      >
        <Row className='valign-wrapper' style={{ marginBottom: '0' }}>
          <Col s={2} className='left'>
            <SideNav
              trigger={
                <Button
                  flat
                  floating
                  className='white-text transparent'
                  icon={<Icon>menu</Icon>}
                />
              }
              options={{ closeOnClick: true }}
            >
              <li>
                <NavLink
                  className='sidenav-close'
                  to='/timesheet'
                  onClick={() => {}}
                >
                  <img
                    src={aeon}
                    style={{
                      width: '30px',
                      marginTop: '10px',
                      marginBottom: '25px',
                    }}
                    alt='Aeon Icon'
                  />
                  <span className='aeon-logo'>Aeon</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  className='sidenav-close'
                  to='/timesheet'
                  onClick={() => {}}
                >
                  <Icon>access_time</Icon> My Timesheet
                </NavLink>
              </li>
              <li>{supervisorLink}</li>
              <li>{adminLink}</li>
              {/* <li>
                <NavLink className="sidenav-close" to="/releaseNotes">
                  <Icon>event_note</Icon> Version History
                </NavLink>
              </li>
              <li>
                {" "}
                <NavLink className="sidenav-close" to="/gettingstarted">
                  <Icon>help_outline</Icon> Help
                </NavLink>
              </li> */}
            </SideNav>
          </Col>
          <Col
            className='right-align'
            offset='3'
            s={10}
            style={{ paddingRight: '24px' }}
          >
            <Dropdown
              trigger={
                <Button
                  flat
                  className='transparent'
                  style={{
                    textTransform: 'none',
                    color: 'white',
                    fontSize: '16px',
                  }}
                >
                  {this.props.auth.user.email === 'swilson@e2i.net'
                    ? this.state.negativeQuote
                    : this.state.quote}
                  {', ' + user.firstName}
                  <Icon right medium className='white-text'>
                    arrow_drop_down
                  </Icon>
                </Button>
              }
              options={{
                hover: false,
                coverTrigger: false,
                alignment: 'right',
                constrainWidth: true,
              }}
            >
              <NavLink className='black-text' to='/usersettings'>
                User Settings
              </NavLink>
              <Button
                className='black-text'
                flat
                onClick={this.onLogoutClick.bind(this)}
              >
                Logout
              </Button>
            </Dropdown>
            <img
              src={imageSource}
              alt='profile'
              style={{ height: '30px', borderRadius: '50%' }}
            />
          </Col>
        </Row>
      </CardPanel>
    );
  }
}

Navbar.propTypes = {
  clearTimesheet: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logoutUser,
  clearTimesheet,
  clearSuccesses,
  clearHasSeenRelease,
})(Navbar);
