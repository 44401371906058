import {
  VERSIONS_LOADING,
  GET_VERSIONS,
  GET_LATEST_VERSION
} from "../actions/types";

const initialState = {
  versionsLoading: false,
  latestVersion: {},
  allVersions: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case VERSIONS_LOADING: {
      return {
        ...state,
        versionsLoading: true
      };
    }
    case GET_VERSIONS: {
      return {
        ...state,
        versionsLoading: false,
        allVersions: action.payload
      };
    }
    case GET_LATEST_VERSION:
      return {
        ...state,
        versionsLoading: false,
        latestVersion: action.payload
      };
    default:
      return state;
  }
}
