import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authActions';
import { clearTimesheet, clearSuccesses } from './actions/timesheetActions';
import { clearHasSeenRelease } from './actions/userActions';

import { Provider } from 'react-redux';
import store from './store';

import PrivateRoute from './components/layout/PrivateRoute';

import Navbar from './components/layout/Navbar';

import UserSettings from './components/user-settings/UserSettings';
import Timesheet from './components/timesheet/Timesheet';
import AuthContainer from './components/auth/AuthContainer';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetForgotPassword from './components/auth/ResetForgotPassword';
import ChangePassword from './components/auth/ChangePassword';
import NotFound from './components/not-found/NotFound';
import TimesheetConsole from './components/admin/timesheets/ManageTimesheets';
import ProjectConsole from './components/admin/projects/ManageProjects';
import UserConsole from './components/admin/users/ManageUsers';
import MyTeam from './components/supervisor/timesheets/SuperviseTimesheets';
import ReviewTimesheet from './components/review-timesheet/ReviewTimesheet';
import ReviewTimesheetConfirmation from './components/review-timesheet/ReviewTimesheetConfirmation';
import GettingStarted from './components/getting-started/gettingStarted';
import RevisionHistoryModal from './components/revision-history/RevisionHistoryModal';
import RevisionHistory from './components/revision-history/RevisionHistory';
import UserDetail from './components/supervisor/timesheets/UserPage';
import ReportingWrapper from './components/supervisor/timesheets/Reporting';
import './App.css';

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Clear Props
    store.dispatch(clearTimesheet());
    store.dispatch(clearSuccesses());
    store.dispatch(clearHasSeenRelease());
    // Redirect to login
    window.location.reload(true);
  }
}

class App extends Component {
  componentDidMount() {
    let timer;
    document.addEventListener('mousemove', function () {
      clearTimeout(timer);
      /**Sets the timer for 5 minutes when there is no mouse movement on the screen.
       * After the 5 minutes it reloads any page part of Aeon.
       */
      timer = setTimeout(() => window.location.reload(true), 300000);

      // Check for token
      if (localStorage.jwtToken) {
        // Decode token and get user info and exp
        const decoded = jwt_decode(localStorage.jwtToken);
        // Check for expired token
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          // Logout user
          store.dispatch(logoutUser());
          // Clear Props
          store.dispatch(clearTimesheet());
          store.dispatch(clearSuccesses());
          store.dispatch(clearHasSeenRelease());
          // Redirect to login
          window.location.reload(true);
        }
      }
    });
    document.addEventListener('keypress', function () {
      clearTimeout(timer);
      /**Sets the timer for 5 minutes when there is no mouse movement on the screen.
       * After the 5 minutes it reloads any page part of Aeon.
       */
      timer = setTimeout(() => window.location.reload(true), 300000);

      // Check for token
      if (localStorage.jwtToken) {
        // Decode token and get user info and exp
        const decoded = jwt_decode(localStorage.jwtToken);
        // Check for expired token
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          // Logout user
          store.dispatch(logoutUser());
          // Clear Props
          store.dispatch(clearTimesheet());
          store.dispatch(clearSuccesses());
          store.dispatch(clearHasSeenRelease());
          // Redirect to login
          window.location.reload(true);
        }
      }
    });
  }

  componentWillUnmount() {
    let timer;
    document.removeEventListener('mousemove', function () {
      clearTimeout(timer);
      /**Sets the timer for 5 minutes when there is no mouse movement on the screen.
       * After the 5 minutes it reloads any page part of Aeon.
       */
      timer = setTimeout(() => window.location.reload(true), 300000);
    });
    document.removeEventListener('keypress', function () {
      clearTimeout(timer);
      /**Sets the timer for 5 minutes when there is no keypresses on the screen.
       * After the 5 minutes it reloads any page part of Aeon.
       */
      timer = setTimeout(() => window.location.reload(true), 300000);
    });
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className='App'>
            <Route
              path='/'
              render={(props) =>
                props.location.pathname !== '/login' &&
                props.location.pathname !== '/changepassword' &&
                props.location.pathname !== '/register' &&
                props.location.pathname !== '/iforgot' &&
                props.location.pathname !== '/azure-redirect' &&
                !props.location.pathname.includes('/passwordreset') && (
                  <Navbar />
                )
              }
            />
            {/* Only render the RevisionHistoryModal when the location is not any of the listed pathnames */}
            <Route
              path='/'
              render={(props) =>
                props.location.pathname !== '/login' &&
                props.location.pathname !== '/changepassword' &&
                props.location.pathname !== '/register' &&
                props.location.pathname !== '/iforgot' &&
                props.location.pathname !== '/azure-redirect' &&
                !props.location.pathname.includes('/passwordreset') && (
                  <RevisionHistoryModal />
                )
              }
            />
            <div className='container-fluid'>
              <Switch>
                {/* <Route exact path="/register" component={Register} /> */}
                <Route
                  exact
                  path='/:auth(login|register|azure-redirect)'
                  component={AuthContainer}
                />
                <Route exact path='/iforgot' component={ForgotPassword} />
                <Route
                  exact
                  path='/passwordreset/:hash'
                  component={ResetForgotPassword}
                />
                <PrivateRoute exact path='/timesheet' component={Timesheet} />
                <PrivateRoute exact path='/' component={Timesheet} />
                <PrivateRoute
                  exact
                  path='/gettingstarted'
                  component={GettingStarted}
                />
                <PrivateRoute
                  exact
                  path='/usersettings'
                  component={UserSettings}
                />
                <PrivateRoute
                  exact
                  path='/admin/projects'
                  component={ProjectConsole}
                />
                <PrivateRoute
                  exact
                  path='/admin/timesheets'
                  component={TimesheetConsole}
                />
                <PrivateRoute
                  exact
                  path='/admin/users'
                  component={UserConsole}
                />
                <PrivateRoute exact path='/myteam' component={MyTeam} />
                <PrivateRoute
                  exact
                  path='/reporting'
                  component={ReportingWrapper}
                />
                <PrivateRoute
                  exact
                  path='/myteam/userdetail'
                  component={UserDetail}
                />
                <PrivateRoute
                  exact
                  path='/changepassword'
                  component={ChangePassword}
                />
                <PrivateRoute
                  exact
                  path='/reviewTimesheet/:id'
                  component={ReviewTimesheet}
                />
                <PrivateRoute
                  exact
                  path='/reviewConfirmation/:id'
                  component={ReviewTimesheetConfirmation}
                />
                <PrivateRoute
                  exact
                  path='/releaseNotes'
                  component={RevisionHistory}
                />
                <Route exact path='*' component={NotFound} />
              </Switch>
            </div>
            {/* Only render the Footer when the location is not any of the listed pathnames */}
            {/* <Route
              path="/"
              render={props =>
                props.location.pathname !== "/login" &&
                props.location.pathname !== "/changepassword" &&
                props.location.pathname !== "/register" &&
                props.location.pathname !== "/iforgot" &&
                props.location.pathname !== "/passwordreset" && <Footer />
              }
            /> */}
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
