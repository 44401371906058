import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Doughnut, Bar, Chart } from 'react-chartjs-2';
import {
  CardPanel,
  Card,
  Button,
  Row,
  Col,
  Dropdown,
  Icon,
} from 'react-materialize';
import 'chartjs-plugin-annotation';
import Spinner from './Spinner';
import {
  createTeamGoal,
  updateTeamGoal,
  deleteTeamGoal,
  createDirectReportGoal,
  updateDirectReportGoal,
  deleteDirectReportGoal,
} from '../../actions/supervisorActions';

import GoalModal from './GoalModal';

// Bar Chart Colors
const primaryColor = '#80CBC4';
const secondaryColor = '#E9ECEE';
const lineColor = '#013B63';

class Goal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      goal: props.goal,
      labels: props.labels,
      datasets: props.datasets,
      timesheetPage: props.timesheetPage,
      directReportPage: props.directReportPage,
      enabled: props.enabled ? props.enabled : false,
      directReport: props.directReport ? props.directReport : null,
      projects: props.projects,
      projectLoading: props.projectLoading,
      loading: props.goalLoading,
    };
    this.onDeleteAction = this.onDeleteAction.bind(this);
    this.onCreateAction = this.onCreateAction.bind(this);
    this.onSubmitAction = this.onSubmitAction.bind(this);
  }

  componentDidMount() {
    let datasets = this.props.datasets;
    if (this.props.datasets && this.props.goal.chart === 'bar') {
      datasets = [];
      datasets = datasets.concat({
        label: 'Goal',
        type: 'line',
        fill: false,
        data: [
          { x: 0, y: this.props.goal.value },
          { x: 1, y: this.props.goal.value },
        ],
        backgroundColor: lineColor,
        borderColor: lineColor,
        xAxisID: 'x-axis-2',
      });
      datasets = datasets.concat(
        this.props.datasets.map((dataset) => {
          return {
            ...dataset,
            label: 'Bar',
            backgroundColor: primaryColor,
            borderColor: primaryColor,
            xAxisID: 'x-axis-1',
          };
        })
      );
    }

    if (this.props.datasets && this.props.goal.chart === 'progress') {
      datasets = datasets.map((dataset) => {
        return {
          ...dataset,
          backgroundColor: [primaryColor, secondaryColor],
        };
      });
    }

    this.setState({ datasets: datasets });

    Chart.pluginService.register({
      beforeDraw: function (chart) {
        if (chart.config.options.elements.center) {
          //Get ctx from string
          var ctx = chart.chart.ctx;

          //Get options from the center object in options
          var centerConfig = chart.config.options.elements.center;
          var fontStyle = centerConfig.fontStyle || 'Arial';
          var txt = centerConfig.text;
          var color = centerConfig.color || '#000';

          //Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.font = '24px ' + fontStyle;
          ctx.fillStyle = color;

          //Draw text in center
          ctx.fillText(txt, centerX, centerY);
        }
      },
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.state.goal !== nextProps.goal ||
      this.state.labels !== nextProps.labels ||
      this.state.datasets !== nextProps.datasets ||
      this.state.timesheetPage !== nextProps.timesheetPage ||
      this.state.directReportPage !== nextProps.directReportPage ||
      this.state.projects !== nextProps.projects ||
      this.state.goalLoading !== nextProps.goalLoading
    ) {
      var datasets = nextProps.datasets;
      if (nextProps.datasets && nextProps.goal.chart === 'bar') {
        datasets = [];
        datasets = datasets.concat({
          label: 'Goal',
          type: 'line',
          fill: false,
          data: [
            { x: 0, y: nextProps.goal.value },
            { x: 1, y: nextProps.goal.value },
          ],
          backgroundColor: lineColor,
          borderColor: lineColor,
          xAxisID: 'x-axis-2',
        });
        datasets = datasets.concat(
          nextProps.datasets.map((dataset) => {
            return {
              ...dataset,
              label: 'Bar',
              backgroundColor: primaryColor,
              borderColor: primaryColor,
              xAxisID: 'x-axis-1',
            };
          })
        );
      }

      if (nextProps.datasets && nextProps.goal.chart === 'progress') {
        datasets = datasets.map((dataset) => {
          return {
            ...dataset,
            backgroundColor: [primaryColor, secondaryColor],
          };
        });
      }

      this.setState({
        goal: nextProps.goal,
        labels: nextProps.labels,
        datasets: datasets,
        timesheetPage: nextProps.timesheetPage,
        directReportPage: nextProps.directReportPage,
        directReport: nextProps.directReport,
        enabled: nextProps.enabled,
        projects: nextProps.projects,
        projectLoading: nextProps.projectLoading,
        loading: nextProps.goalLoading,
      });
    }
  }

  onDeleteAction = () => {
    if (this.state.directReportPage) {
      this.props.deleteDirectReportGoal({ _id: this.state.goal._id });
    } else {
      this.props.deleteTeamGoal({ _id: this.state.goal._id });
    }
  };

  onCreateAction = (e) => {
    if (e) {
      let newGoal;
      if (this.state.directReportPage) {
        //create Direct Report Goal
        newGoal = {
          name: e.name,
          description: e.description,
          project_id: e.project,
          type: e.type,
          value: e.value,
          units: e.units,
          duration: e.duration,
          chart: e.chart,
          isTeamGoal: false,
          user_id: this.state.directReport,
        };
        this.props.createDirectReportGoal(newGoal);
      } else {
        newGoal = {
          name: e.name,
          description: e.description,
          project_id: e.project,
          type: e.type,
          value: e.value,
          units: e.units,
          duration: e.duration,
          chart: e.chart,
          isTeamGoal: true,
        };
        this.props.createTeamGoal(newGoal);
      }
    }
  };

  onSubmitAction = (e) => {
    if (e) {
      let newGoal = {
        ...this.state.goal,
        name: e.name,
        description: e.description,
        project_id: e.project,
        type: e.type,
        value: e.value,
        units: e.units,
        duration: e.duration,
        chart: e.chart,
        user_id: this.state.directReport,
      };
      if (this.state.goal.isTeamGoal) {
        this.props.updateTeamGoal(newGoal);
      } else {
        this.props.updateDirectReportGoal(newGoal);
      }
    }
  };

  render() {
    let chart;
    if (this.state.goal === null && this.state.timesheetPage) {
      chart = null;
    } else if (this.state.goal === null && !this.state.timesheetPage) {
      if (this.state.loading) {
        chart = (
          <CardPanel
            className='valign-wrapper'
            style={{ height: 201, padding: 0 }}
          >
            <div style={{ width: '100%' }}>
              <Spinner />
            </div>
          </CardPanel>
        );
      } else {
        chart = (
          // TO DO: Styling still needed.
          <CardPanel
            className='valign-wrapper'
            style={{ height: 201, padding: 0 }}
          >
            <GoalModal
              isTeamGoal={!this.state.directReportPage}
              enabled={this.state.enabled}
              projects={this.state.projects}
              projectLoading={this.state.projectLoading}
              onSubmit={this.onCreateAction}
            />
          </CardPanel>
        );
      }
    } else if (this.state.goal && this.state.goal.chart === 'progress') {
      chart = (
        <Card
          className='cardGoal'
          header={
            <div>
              <Row className='cardGoalHeader valign-wrapper'>
                <Col s={9}>
                  <div className=' cardGoalText'>{this.state.goal.name}</div>
                </Col>
                <Col s={3} style={{ textAlign: 'right' }}>
                  {!this.state.timesheetPage ? (
                    <Dropdown
                      trigger={
                        <Button
                          flat
                          className='cardGoalDropDown'
                          style={{
                            textTransform: 'center',
                            color: 'rgba(0, 0, 0, 0.54)',
                            padding: 0,
                            marginRight: 5,
                          }}
                        >
                          <Icon className='cardGoalIcon'>more_vert</Icon>
                        </Button>
                      }
                      options={{
                        coverTrigger: false,
                        alignment: 'left',
                      }}
                    >
                      <GoalModal
                        _id={this.state.goal._id}
                        name={this.state.goal.name}
                        description={this.state.goal.description}
                        goalType={
                          this.state.goal.project === null ? 'type' : 'project'
                        }
                        type={this.state.goal.type}
                        project={this.state.goal.project}
                        value={this.state.goal.value}
                        units={this.state.goal.units}
                        duration={this.state.goal.duration}
                        chart={this.state.goal.chart}
                        isTeamGoal={this.state.goal.isTeamGoal}
                        projects={this.state.projects}
                        projectLoading={this.state.projectLoading}
                        onSubmit={this.onSubmitAction}
                        enabled={this.state.enabled}
                      />
                      <Button
                        flat
                        style={{ fontSize: '14px' }}
                        onClick={this.onDeleteAction}
                        disabled={!this.state.enabled}
                      >
                        Delete Goal
                      </Button>
                    </Dropdown>
                  ) : null}
                </Col>
              </Row>
              <div className='divider' />
            </div>
          }
        >
          <Row style={{ padding: '0px 5px', maxHeight: 140 }}>
            <Col xl={8} m={6} s={8} style={{ maxHeight: 140 }}>
              <Doughnut
                height={140}
                data={{
                  labels: this.state.labels,
                  datasets: this.state.datasets,
                }}
                options={{
                  elements: {
                    center: {
                      text: this.state.datasets[0].data[0] + '%',
                    },
                  },
                  legend: {
                    position: 'bottom',
                    onClick: null,
                    fullWidth: false,
                    labels: {
                      boxWidth: 12,
                      fontFamily: "'Roboto', 'sans-serif'",
                      fontColor: 'rgba(0, 0, 0, 0.54)',
                    },
                  },
                  tooltips: {
                    enabled: false,
                  },
                  cutoutPercentage: 75,
                  responsive: true,
                  maintainAspectRatio: false,
                }}
              />
            </Col>
            <Col xl={4} m={6} s={4} className='cardGoalDescription'>
              {this.state.goal.description}
            </Col>
          </Row>
        </Card>
      );
    } else if (this.state.goal && this.state.goal.chart === 'bar') {
      chart = (
        <Card
          className='cardGoal'
          header={
            <div>
              <Row className='cardGoalHeader valign-wrapper'>
                <Col s={9}>
                  <div className=' cardGoalText'>{this.state.goal.name}</div>
                </Col>
                <Col s={3} style={{ textAlign: 'right' }}>
                  {!this.state.timesheetPage ? (
                    <Dropdown
                      trigger={
                        <Button
                          flat
                          className='cardGoalDropDown'
                          style={{
                            textTransform: 'center',
                            color: 'rgba(0, 0, 0, 0.54)',
                            padding: 0,
                            marginRight: 5,
                          }}
                        >
                          <Icon className='cardGoalIcon'>more_vert</Icon>
                        </Button>
                      }
                      options={{
                        coverTrigger: false,
                        alignment: 'left',
                      }}
                    >
                      <GoalModal
                        _id={this.state.goal._id}
                        name={this.state.goal.name}
                        description={this.state.goal.description}
                        goalType={
                          this.state.goal.project === null ? 'type' : 'project'
                        }
                        type={this.state.goal.type}
                        project={this.state.goal.project}
                        value={this.state.goal.value}
                        units={this.state.goal.units}
                        duration={this.state.goal.duration}
                        chart={this.state.goal.chart}
                        isTeamGoal={this.state.goal.isTeamGoal}
                        projects={this.state.projects}
                        projectLoading={this.state.projectLoading}
                        onSubmit={this.onSubmitAction}
                        enabled={this.state.enabled}
                      />
                      <Button
                        flat
                        style={{ fontSize: '14px' }}
                        onClick={this.onDeleteAction}
                        disabled={!this.state.enabled}
                      >
                        Delete Goal
                      </Button>
                    </Dropdown>
                  ) : null}
                </Col>
              </Row>
              <div className='divider' />
            </div>
          }
        >
          <Row style={{ padding: '0px 5px', maxHeight: 140 }}>
            <Col xl={8} m={6} s={8} style={{ maxHeight: 140 }}>
              <Bar
                height={140}
                data={{
                  labels: this.state.labels,
                  datasets: this.state.datasets,
                }}
                options={{
                  title: {
                    display: false,
                    text: this.state.goal.name,
                    position: 'top',
                    fontFamily: "'Roboto', 'sans-serif'",
                    fontStyle: 'regular',
                    fontColor: 'rgba(12, 13, 13, 0.54)',
                    fontSize: 14,
                  },
                  legend: {
                    display: false,
                  },
                  tooltips: {
                    enabled: false,
                  },
                  elements: {
                    line: {
                      fill: false,
                    },
                    point: {
                      radius: 0,
                      hoverRadius: 0,
                    },
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [
                      {
                        id: 'x-axis-1',
                      },
                      {
                        id: 'x-axis-2',
                        type: 'linear',
                        display: false,
                        scaleLabel: {
                          display: false,
                          labelString: 'Days',
                        },
                        ticks: {
                          beginAtZero: true,
                        },
                        labels: ['0', '1'],
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          callback: (value) => {
                            return (
                              value +
                              (this.state.goal.units === 'time' ? ' hrs' : '%')
                            );
                          },
                        },
                      },
                    ],
                  },
                }}
              />
            </Col>
            <Col xl={4} m={6} s={4} className='cardGoalDescription'>
              {this.state.goal.description}
            </Col>
          </Row>
        </Card>
      );
    }
    return <Fragment>{chart}</Fragment>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  createTeamGoal,
  updateTeamGoal,
  deleteTeamGoal,
  createDirectReportGoal,
  updateDirectReportGoal,
  deleteDirectReportGoal,
})(Goal);
