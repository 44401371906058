import {
  GET_SUPERVISOR_USERS,
  GET_SUPERVISOR_USER_TIMESHEET,
  GET_SUPERVISOR_DATES,
  GET_SUPERVISOR_TEAM_GOALS,
  GET_DIRECT_REPORT_GOALS,
  GET_SUPERVISOR_USER_DETAIL_TIMESHEET,
  GET_SUPERVISOR_REPORTING_RESULTS,
  CREATE_TEAM_GOAL,
  CREATE_DIRECT_REPORT_GOAL,
  UPDATE_SUPERVISOR_TEAM_GOAL,
  UPDATE_DIRECT_REPORT_GOAL,
  DELETE_SUPERVISOR_TEAM_GOAL,
  DELETE_DIRECT_REPORT_GOAL,
  CLEAR_SUPERVISOR_USERS,
  CLEAR_USER_TIMESHEETS,
  CLEAR_USER_DETAIL_TIMESHEET,
  CLEAR_SUPERVISOR_TEAM_GOALS,
} from '../actions/types';

// Helper functions
import addDirectReportGoals from '../utils/addDirectReportGoals';

const initialState = {
  supervisorUsers: [],
  supervisorUserTimesheets: [],
  supervisorDates: [],
  supervisorUserDetailTimesheet: null,
  supervisorUserGoals: [],
  supervisorTeamGoals: [],
  supervisorReportingResults: [],
  supervisorTimesheetsLoading: true,
  supervisorUsersLoading: true,
  supervisorUsersTimesheetsLoading: true,
  supervisorDatesLoading: true,
  supervisorUserGoalsLoading: true,
  supervisorUserGoalsUpdated: false,
  supervisorTeamGoalLoading: true,
  supervisorTeamGoalUpdated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SUPERVISOR_USERS:
      return {
        ...state,
        supervisorUsers: action.payload,
        supervisorUsersLoading: false,
      };
    case GET_SUPERVISOR_USER_TIMESHEET:
      return {
        ...state,
        supervisorUserTimesheets: state.supervisorUserTimesheets.concat(
          action.payload
        ),
        supervisorUsersTimesheetsLoading: false,
      };
    case GET_SUPERVISOR_USER_DETAIL_TIMESHEET:
      return {
        ...state,
        supervisorUserDetailTimesheet: action.payload,
      };
    case CLEAR_SUPERVISOR_USERS:
      return {
        ...state,
        supervisorUser: [],
        supervisorUsersLoading: false,
      };
    case CLEAR_USER_TIMESHEETS:
      return {
        ...state,
        supervisorUserTimesheets: [],
        supervisorUsersTimesheetsLoading: false,
      };
    case CLEAR_USER_DETAIL_TIMESHEET:
      return {
        ...state,
        supervisorUsersTimesheetDetail: null,
      };
    case GET_SUPERVISOR_DATES:
      return {
        ...state,
        supervisorDates: action.payload,
        supervisorDatesLoading: false,
      };
    case GET_SUPERVISOR_REPORTING_RESULTS:
      return {
        ...state,
        supervisorReportingResults: action.payload,
      };
    case CREATE_TEAM_GOAL:
      return {
        ...state,
        supervisorTeamGoals: [],
        supervisorTeamGoalLoading: false,
        supervisorTeamGoalUpdated: true,
      };
    case GET_SUPERVISOR_TEAM_GOALS:
      return {
        ...state,
        supervisorTeamGoals: action.payload,
        supervisorTeamGoalLoading: false,
        supervisorTeamGoalUpdated: false,
      };
    case UPDATE_SUPERVISOR_TEAM_GOAL:
      return {
        ...state,
        supervisorTeamGoalUpdated: true,
      };
    case DELETE_SUPERVISOR_TEAM_GOAL:
      return {
        ...state,
        supervisorTeamGoals: [],
        supervisorTeamGoalLoading: false,
      };
    case CREATE_DIRECT_REPORT_GOAL:
      return {
        ...state,
        supervisorUserGoalsLoading: false,
        supervisorUserGoalsUpdated: true,
      };
    case GET_DIRECT_REPORT_GOALS:
      return {
        ...state,
        supervisorUserGoals: addDirectReportGoals(
          state.supervisorUserGoals,
          action.payload
        ),
        supervisorUserGoalsLoading: false,
        supervisorUserGoalsUpdated: false,
      };
    case UPDATE_DIRECT_REPORT_GOAL:
      return {
        ...state,
        supervisorUserGoalsUpdated: true,
      };
    case DELETE_DIRECT_REPORT_GOAL:
      return {
        ...state,
        supervisorUserGoals: state.supervisorUserGoals.map((user) => {
          return {
            ...user,
            goals: user.goals.map((goal) => {
              if (goal.goal) {
                if (goal.goal._id === action.payload._id) {
                  return {
                    goal: null,
                    labels: null,
                    datasets: null,
                  };
                }
              }
              return goal;
            }),
          };
        }),
      };
    case CLEAR_SUPERVISOR_TEAM_GOALS:
      return {
        ...state,
        supervisorTeamGoals: [],
        supervisorTeamGoalLoading: false,
      };
    default:
      return state;
  }
}
