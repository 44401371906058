// General Action Types
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING = 'LOADING';
export const GET_SUCCESSES = 'GET_SUCCESSES';
export const CLEAR_SUCCESSES = 'CLEAR_SUCCESSES';
export const CLEAR_USER_TIMESHEETS = 'CLEAR_USER_TIMESHEETS';

// Admin Action Types
export const GET_ADMIN_TIMESHEETROWS = 'GET_ADMIN_TIMESHEETS';
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const GET_ADMIN_SUPERVISORS = 'GET_ADMIN_SUPERVISORS';
export const GET_ADMIN_DATES = 'GET_ADMIN_DATES';
export const GET_ADMIN_PROJECTS = 'GET_ADMIN_PROJECTS';
export const GET_ADMIN_USER_TIMESHEET = 'GET_ADMIN_USER_TIMESHEET';
export const GET_ADMIN_SUPERVISOR_USERS = 'GET_ADMIN_SUPERVISOR_USERS';
export const GET_BAMBOOCSV = 'GET_BAMBOOCSV';
export const GET_ADMIN_EMPLOYEE_TYPES = 'GET_ADMIN_EMPLOYEE_TYPES';
export const CLEAR_ADMIN_USERS = 'CLEAR_ADMIN_USERS';
export const CLEAR_ADMIN_SUPERVISORS = 'CLEAR_ADMIN_SUPERVISORS';
export const CLEAR_ADMIN_PROJECTS = 'CLEAR_ADMIN_PROJECTS';
export const CLEAR_ADMIN_SUPERVISEES = 'CLEAR_ADMIN_SUPERVISEES';
export const CLEAR_ADMIN_EMPLOYEE_TYPES = 'CLEAR_ADMIN_EMPLOYEE_TYPES';

// Auth Action Types
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Project Action Types
export const GET_ACTIVE_PROJECTS = 'GET_ACTIVE_PROJECTS';

// Supervisor Action Types
export const GET_SUPERVISOR_USERS = 'GET_SUPERVISOR_USERS';
export const GET_SUPERVISOR_DATES = 'GET_SUPERVISOR_DATES';
export const GET_SUPERVISOR_USER_TIMESHEET = 'GET_SUPERVISOR_USER_TIMESHEET';
export const GET_SUPERVISOR_TEAM_GOALS = 'GET_SUPERVISOR_TEAM_GOALS';
export const GET_DIRECT_REPORT_GOALS = 'GET_DIRECT_REPORT_GOALS';
export const GET_SUPERVISOR_USER_DETAIL_TIMESHEET =
  'GET_SUPERVISOR_USER_DETAIL_TIMESHEET';
export const GET_SUPERVISOR_REPORTING_RESULTS =
  'GET_SUPERVISOR_REPORTING_RESULTS';
export const CREATE_TEAM_GOAL = 'CREATE_TEAM_GOAL';
export const CREATE_DIRECT_REPORT_GOAL = 'CREATE_DIRECT_REPORT_GOAL';
export const UPDATE_SUPERVISOR_TEAM_GOAL = 'UPDATE_SUPERVISOR_TEAM_GOAL';
export const UPDATE_DIRECT_REPORT_GOAL = 'UPDATE_DIRECT_REPORT_GOAL';
export const CLEAR_SUPERVISOR_USERS = 'CLEAR_SUPERVISOR_USERS';
export const CLEAR_USER_DETAIL_TIMESHEET = 'CLEAR_USER_DETAIL_TIMESHEET';
export const CLEAR_SUPERVISOR_TEAM_GOALS = 'CLEAR_SUPERVISOR_TEAM_GOALS';
export const DELETE_SUPERVISOR_TEAM_GOAL = 'DELETE_SUPERVISOR_TEAM_GOAL';
export const DELETE_DIRECT_REPORT_GOAL = 'DELETE_DIRECT_REPORT_GOAL';

// Timesheet Action Types
export const GET_TIMESHEET = 'GET_TIMESHEET';
export const GET_CURRENT_TIMESHEET = 'GET_CURRENT_TIMESHEET';
export const GET_AVAILABLE_TIMESHEETS = 'GET_AVAILABLE_TIMESHEETS';
export const GET_TIMESHEET_DASHBOARD = 'GET_TIMESHEET_DASHBOARD';
export const REVIEW_TIMESHEET = 'REVIEW_TIMESHEET';
export const SUBMITTED_TIMESHEET = 'SUBMITTED_TIMESHEET';
export const UNSUBMITTED_TIMESHEET = 'UNSUBMITTED_TIMESHEET';
export const TIMESHEET_LOADING = 'TIMESHEET_LOADING';
export const CLEAR_TIMESHEET = 'CLEAR_TIMESHEET';

// User Action Types
export const GET_TIMESHEET_TEMPLATE = 'GET_TIMESHEET_TEMPLATE';
export const HAS_SEEN_RELEASE = 'HAS_SEEN_RELEASE';
export const CLEAR_HAS_SEEN_RELEASE = 'CLEAR_HAS_SEEN_RELEASE';

// Version Action Types
export const VERSIONS_LOADING = 'VERSIONS_LOADING';
export const GET_VERSIONS = 'GET_VERSIONS';
export const GET_LATEST_VERSION = 'GET_LATEST_VERSION';

// No Longer Used??? lol
export const STOP_REVIEW = 'STOP_REVIEW';
