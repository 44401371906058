import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classnames from 'classnames';

export default class SupervisorSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: props.selectedOption,
      options: props.options,
      isLoadingExternally: '',
      onChange: '',
      title: props.title,
      placeholder: props.placeholder,
      clearable: props.clearable !== null ? props.clearable : true,
      disabled: props.disabled,
      searchable: props.searchable,
      error: props.error ? props.error : null,
      extraCSS: props.extraCSS,
      size: props.size,
      maxMenuHeight: props.height,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.placeholder !== nextProps.placeholder) {
      this.setState({ placeholder: nextProps.placeholder });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.setState({ options: this.props.options });
    }
    if (prevProps.selectedOption !== this.props.selectedOption) {
      this.setState({ selectedOption: this.props.selectedOption });
    }
    if (this.props.error !== prevProps.error) {
      this.setState({ error: this.props.error });
    }
    if (this.props.disabled !== prevProps.disabled) {
      this.setState({ disabled: this.props.disabled });
    }
  }

  sendOption() {
    if (this.state.selectedOption) {
      this.props.onSelect(this.state.selectedOption.value, this.props.id);
    } else {
      this.props.onSelect(null);
    }
  }

  handleChange = (selectedOption) => {
    if (this.state.selectedOption !== selectedOption)
      this.setState({ selectedOption }, () => {
        this.sendOption();
      });
  };

  render() {
    const styles = {
      input: (styles) => {
        return {
          ...styles,
          height: '1.8em',
          maxHeight: '1.8em',
          textSizeAdjust: 'none',
          webkitBorderHorizontalSpace: 'none',
          composes: 'browser-default',
        };
      },
      control: (styles) => {
        return {
          ...styles,
          '&:hover': {
            borderColor: this.state.error ? 'red' : 'hsl(0,0%,70%)',
          },
          borderColor: this.state.error ? 'red' : 'hsl(0,0%,70%)',
          boxShadow: 'none', // no box-shadow
        };
      },
    };

    return (
      <div className={`${this.state.extraCSS} mx-auto`}>
        {this.state.title && <div>{this.state.title}</div>}
        <Select
          classNamePrefix='react-select'
          className={classnames(
            `${this.state.size} basic-single browser-default`,
            {
              'invalid-cell': this.state.error,
            }
          )}
          placeholder={this.state.placeholder}
          value={
            typeof this.state.selectedOption === 'string' ||
            typeof this.state.selectedOption === 'boolean' ||
            typeof this.state.selectedOption === 'number'
              ? this.state.options[
                  this.state.options.findIndex(
                    (index) => index.value === this.state.selectedOption
                  )
                ]
              : this.state.selectedOption
          }
          onChange={this.handleChange}
          options={this.state.options}
          isLoadingExternally={this.state.isLoadingExternally}
          isClearable={this.state.clearable}
          isDisabled={this.state.disabled}
          isSearchable={this.state.searchable}
          backspaceRemoves={this.state.clearable}
          deleteRemoves={this.state.clearable}
          styles={styles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary50: '#c4c4c4',
              primary25: 'rgba(0,0,0,0.05)',
              primary: '#1897ec',
              neutral5: '#DDDDDD',
            },
          })}
          maxMenuHeight={this.state.maxMenuHeight}
        />

        {this.state.error && (
          <div className='invalid-text'>{this.state.error}</div>
        )}
      </div>
    );
  }
}

SupervisorSelection.propTypes = {
  options: PropTypes.array,
};

SupervisorSelection.defaultProps = {
  placeholder: 'Select',
};
