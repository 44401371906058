import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser, azureLogin } from '../../actions/authActions';
import TextFieldGroup from '../common/TextFieldGroup';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'react-materialize';
import msdark from '../../img/ms-dark.svg';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.azLogin = this.azLogin.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/timesheet');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/timesheet');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  }

  azLogin() {
    this.props.azureLogin().then((res) => window.location.assign(res.url));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors } = this.state;

    return (
      <div className='pt-4'>
        <div>
          <form onSubmit={this.onSubmit} noValidate={true}>
            <div className='mb-3'>
              <TextFieldGroup
                placeholder='Email'
                name='email'
                type='email'
                value={this.state.email}
                onChange={this.onChange}
                error={errors.email}
              />
            </div>
            <div>
              <TextFieldGroup
                placeholder='Password'
                name='password'
                type='password'
                value={this.state.password}
                onChange={this.onChange}
                error={errors.password}
              />
            </div>
            <div className='text-right'>
              <Link className='btn-flat forgot-password' to='/iforgot'>
                Forget your Password?
              </Link>
            </div>
            <div className='text-center'>
              <Button type='submit' className='white blue-text'>
                Login
              </Button>
              <Link className='btn-flat' to='/register'>
                Sign Up
              </Link>
            </div>
          </form>
          <div className='text-center pt-4'>
            <img
              className='msft-button'
              onClick={() => this.azLogin()}
              src={msdark}
              alt='Microsoft Icon'
            />
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser, azureLogin })(
  withRouter(Login)
);
