import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { changePassword } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import { Button, Modal } from "react-materialize";

class ChangePassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      newpassword: "",
      newpassword2: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const newPassword = {
      email: this.props.auth.user.email,
      password: this.state.password,
      newpassword: this.state.newpassword,
      newpassword2: this.state.newpassword2
    };

    this.props.changePassword(newPassword, this.props.history);
  }

  render() {
    const { errors } = this.state;

    return (
      <div className="changePassword">
        <Modal
          className="modal-fix changePasswordModal"
          header="Change Password"
          trigger={this.props.button}
          actions={
            <Fragment>
              <Button flat modal="close">
                Cancel
              </Button>
              <Button onClick={this.onSubmit} className="white blue-text">
                Save
              </Button>
            </Fragment>
          }
        >
          <div className="divider" />
          <div style={{ padding: "24px" }}>
            <div className="mb-3">
              <TextFieldGroup
                placeholder="Current Password"
                name="password"
                type="password"
                value={this.state.password}
                onChange={this.onChange}
                error={errors.password}
              />
            </div>
            <div className="mb-3">
              <TextFieldGroup
                placeholder="New Password"
                name="newpassword"
                type="password"
                value={this.state.newpassword}
                onChange={this.onChange}
                error={errors.newpassword}
              />
            </div>
            <div style={{ fontWeight: 600, fontSize: "12px" }}>
              Password Strength:
            </div>
            <div style={{ fontSize: "12px" }}>
              Use at least 8 characters. Don't use a password from another site,
              or something too obvious like your pet's name.
            </div>
            <div className="mt-3">
              <TextFieldGroup
                placeholder="Confirm New Password"
                name="newpassword2"
                type="password"
                value={this.state.newpassword2}
                onChange={this.onChange}
                error={errors.newpassword2}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { changePassword }
)(withRouter(ChangePassword));
