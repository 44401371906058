import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import successReducer from "./successReducer";
import projectReducer from "./projectReducer";
import timesheetReducer from "./timesheetReducer";
import adminReducer from "./adminReducer";
import supervisorReducer from "./supervisorReducer";
import userSettingsReducer from "./userSettingsReducer";
import versionReducer from "./versionReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  successes: successReducer,
  project: projectReducer,
  timesheet: timesheetReducer,
  admin: adminReducer,
  supervisor: supervisorReducer,
  userSettings: userSettingsReducer,
  versions: versionReducer
});
