import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  clearTimesheet,
  reviewTimesheet
} from "../../actions/timesheetActions";
import Spinner from "../common/Spinner";
import ReviewTimesheetTable from "./ReviewTimesheetTable";
import { Card, Button } from "react-materialize";

class ReviewTimesheet extends Component {
  constructor(props) {
    super(props);

    this.returnButtonAction = this.returnButtonAction.bind(this);
  }

  componentDidMount() {
    this.props.reviewTimesheet(this.props.match.params.id);
  }

  returnButtonAction() {
    this.props.history.push(`/timesheet`);
  }
  render() {
    const { timesheet, timesheetLoading } = this.props.timesheet;
    let timesheetContent;
    if (timesheetLoading) {
      timesheetContent = <Spinner />;
    } else if (timesheet === null) {
      timesheetContent = (
        <div>
          <h1>You have no time entered in your timesheet!!!</h1>
        </div>
      );
    } else {
      timesheetContent = (
        <div className="timesheet-container">
          <ReviewTimesheetTable timesheet={timesheet} />
        </div>
      );
    }

    return (
      <Fragment>
        <Card className="cardSmallBar">
          <div style={{ fontSize: "12px", height: "14.4px" }}>
            <Button
              style={{
                fontFamily: "'Roboto', sans-serif",
                textTransform: "none",
                fontSize: "14px",
                height: "14.4px",
                lineHeight: "inherit",
                padding: 0,
                border: 0,
                color: "rgba(0, 0, 0, .54)"
              }}
              flat
              className="white"
              onClick={this.returnButtonAction}
            >
              My Timesheet >{" "}
            </Button>
            <Button
              flat
              style={{
                fontFamily: "'Roboto', sans-serif",
                textTransform: "none",
                fontSize: "14px",
                height: "14.4px",
                lineHeight: "inherit",
                padding: 0,
                border: 0
              }}
            >
              {"  "}
              Review Timesheet
            </Button>
          </div>
        </Card>
        <div className="container-fluid">{timesheetContent}</div>
      </Fragment>
    );
  }
}

ReviewTimesheet.propTypes = {
  reviewTimesheet: PropTypes.func,
  timesheet: PropTypes.object,
  reviewingTimesheet: PropTypes.bool
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  timesheet: state.timesheet
});

export default connect(
  mapStateToProps,
  { clearTimesheet, reviewTimesheet }
)(ReviewTimesheet);
