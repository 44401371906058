import axios from 'axios';

import {
  GET_ADMIN_TIMESHEETROWS,
  GET_ADMIN_USERS,
  GET_ADMIN_DATES,
  GET_ADMIN_USER_TIMESHEET,
  GET_BAMBOOCSV,
  CLEAR_ADMIN_USERS,
  CLEAR_USER_TIMESHEETS,
  CLEAR_ADMIN_PROJECTS,
  LOADING,
  GET_SUCCESSES,
  GET_ERRORS,
  GET_ADMIN_PROJECTS,
  GET_ADMIN_SUPERVISOR_USERS,
  GET_ADMIN_SUPERVISORS,
  CLEAR_ADMIN_SUPERVISORS,
  CLEAR_ADMIN_SUPERVISEES,
  GET_ADMIN_EMPLOYEE_TYPES,
  CLEAR_ADMIN_EMPLOYEE_TYPES,
} from './types';

// Get All Timesheets/Rows.
export const getTimesheetRows = (startDate, endDate) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/admin/getTimesheetRows/${startDate}/${endDate}`)
    .then((res) =>
      dispatch({
        type: GET_ADMIN_TIMESHEETROWS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_TIMESHEETROWS,
        payload: null,
      })
    );
};

// Get Active Users
export const getUsers = (startDate, endDate) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/admin/getUsers/${startDate}/${endDate}`)
    .then((res) =>
      dispatch({
        type: GET_ADMIN_USERS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_USERS,
        payload: null,
      })
    );
};

// Get All Users
export const getAllUsers = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get('/api/admin/getAllUsers')
    .then((res) =>
      dispatch({
        type: GET_ADMIN_USERS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_USERS,
        payload: null,
      })
    );
};

// Get Supervisees
export const getSupervisees = (id) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/admin/getSupervisees/${id}`)
    .then((res) =>
      dispatch({
        type: GET_ADMIN_SUPERVISOR_USERS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_SUPERVISOR_USERS,
        payload: null,
      })
    );
};

// Get Supervisors
export const getSupervisors = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get('/api/admin/getSupervisors')
    .then((res) =>
      dispatch({
        type: GET_ADMIN_SUPERVISORS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_SUPERVISORS,
        payload: null,
      })
    );
};

// Get Timesheet Dates
export const getTimesheetDates = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get('/api/admin/getTimesheetDates')
    .then((res) =>
      dispatch({
        type: GET_ADMIN_DATES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_DATES,
        payload: null,
      })
    );
};

// Get User Timesheet
export const getUserTimesheet = (user, startDate, endDate) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/admin/getUserTimesheet/${user}/${startDate}/${endDate}`)
    .then((res) =>
      dispatch({
        type: GET_ADMIN_USER_TIMESHEET,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_USER_TIMESHEET,
        payload: null,
      })
    );
};

// Get All Timesheet Rows for Bamboo CSV
export const getBambooCSV = (startDate, endDate) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/admin/getBambooCSV/${startDate}/${endDate}`)
    .then((res) =>
      dispatch({
        type: GET_BAMBOOCSV,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_BAMBOOCSV,
        payload: null,
      })
    );
};

// Get EmployeeTypes
export const getEmployeeTypes = () => (dispatch) => {
  axios
    .get('/api/admin/activeEmployeeTypes')
    .then((res) =>
      dispatch({
        type: GET_ADMIN_EMPLOYEE_TYPES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_EMPLOYEE_TYPES,
        payload: null,
      })
    );
};

// Clear Supervisors
export const clearEmployeeTypes = () => {
  return {
    type: CLEAR_ADMIN_EMPLOYEE_TYPES,
  };
};

// Clear Supervisors
export const clearSupervisors = () => {
  return {
    type: CLEAR_ADMIN_SUPERVISORS,
  };
};

// Clear Supervisees
export const clearSupervisees = () => {
  return {
    type: CLEAR_ADMIN_SUPERVISEES,
  };
};

// Clear Users
export const clearUsers = () => {
  return {
    type: CLEAR_ADMIN_USERS,
  };
};

// Clear User Timesheets
export const clearUserTimesheets = () => {
  return {
    type: CLEAR_USER_TIMESHEETS,
  };
};

// Clear Admin Projects
export const clearProjects = () => {
  return {
    type: CLEAR_ADMIN_PROJECTS,
  };
};

// Get All Projects
export const getAllProjects = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get('/api/admin/getAllProjects')
    .then((res) =>
      dispatch({
        type: GET_ADMIN_PROJECTS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ADMIN_PROJECTS,
        payload: null,
      })
    );
};

// edit/create a project
export const editProject = (projectData) => (dispatch) => {
  axios
    .post('/api/admin/editProject', projectData)
    .then((res) => {})
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// edit a user
export const editUser = (userData) => (dispatch) => {
  axios
    .post('/api/admin/editUser', userData)
    .then((res) =>
      dispatch({
        type: GET_SUCCESSES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// update a user's goals
export const updateUserGoals = (data) => (dispatch) => {
  axios
    .post('/api/goals/updateUserGoals', data)
    .then((res) => dispatch({ type: GET_SUCCESSES, payload: null }))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// delete a user's goals
export const deleteUserGoals = (data) => (dispatch) => {
  axios
    .post('/api/goals/deleteUserGoals', data)
    .then((res) => dispatch({ type: GET_SUCCESSES, payload: null }))
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// submit a timesheet
export const submitTimesheet = (id) => (dispatch) => {
  axios
    .post('/api/admin/submitTimesheet', id)
    .then((res) => {})
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Add User
export const addUser = (userData) => (dispatch) => {
  axios
    .post('/api/admin/addUser', userData)
    .then((res) =>
      dispatch({
        type: GET_SUCCESSES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Loading Spinner
export const setLoading = () => {
  return {
    type: LOADING,
  };
};
