import axios from "axios";

import { GET_ACTIVE_PROJECTS, LOADING } from "./types";

// Get Active Projects
export const getActiveProjects = (startDate, endDate) => dispatch => {
  dispatch(setLoading());
  axios
    .get(`/api/projects/getActiveProjects/${startDate}/${endDate}`)
    .then(res =>
      dispatch({
        type: GET_ACTIVE_PROJECTS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ACTIVE_PROJECTS,
        payload: null
      })
    );
};

// Loading Spinner
export const setLoading = () => {
  return {
    type: LOADING
  };
};
