import React, { Component, Fragment } from 'react';
import { Row, Card, Col } from 'react-materialize';
import { connect } from 'react-redux';
import { getReportingResults } from '../../../actions/supervisorActions';
import DropDownGroup from '../../common/DropDownGroup';
import moment from 'moment-timezone';
import {
  DataGridPremium,
  GridRowsProp,
  GridColDef,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { useMovieData } from '@mui/x-data-grid-generator';

const today = new Date(moment());

class Reporting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: moment(today).add(-1 * today.getDay(), 'day'),
      endDate: moment(today).add(-1 * today.getDay() + 6, 'day'),
    };
  }
  render() {
    const data = useMovieData();

    return (
      <Fragment>
        <Card className='cardSmallBar'>Reporting</Card>
        <div
          style={{ marginTop: '24px', marginLeft: '88px', marginRight: '88px' }}
        >
          <Row className='valign-wrapper'>
            <Col s={3} m={2} l={1}>
              <span
                style={{
                  fontSize: '16px',
                  textWeight: '500',
                }}
              >
                Filters
              </span>
            </Col>
            <Col s={4} m={4} l={2}>
              <DropDownGroup options={this.state.startDate} />
            </Col>
            <Col s={4} m={4} l={2}>
              <DropDownGroup options={this.state.endDate} />
            </Col>
            <Col s={4} m={4} l={2}>
              <DropDownGroup options={this.state.users} />
            </Col>
            <Col s={4} m={4} l={2}>
              <DropDownGroup options={this.state.project} />
            </Col>
            <Col s={4} m={4} l={2}>
              <DropDownGroup options={this.state.task} />
            </Col>
          </Row>
          <Row>
            <Col s={12}>
              <div>
                <DataGridPremium
                  {...data}
                  apiRef={this.props.apiRef}
                  initialState={this.props.initialState}
                  groupingColDef={{ leafField: 'title' }}
                  autoHeight={true}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  supervisor: state.supervisor,
});

function ReportingWrapper(props) {
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      columns: {
        columnVisibilityModel: {
          // Hide the column used for leaves
          title: false,
        },
      },
      rowGrouping: {
        model: ['company'],
      },
    },
  });
  return <Reporting {...props} apiRef={apiRef} initialState={initialState} />;
}

export default connect(mapStateToProps, { getReportingResults })(
  ReportingWrapper
);
