import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Spinner from "../../common/Spinner";
import UserTimesheetRow from "./UserTimesheetRow";
import Goal from "../../common/Goal";
import { connect } from "react-redux";
import {
  getSupervisees,
  getTimesheetDates,
  getTeamGoals,
  clearUsers,
  clearUserTimesheets,
  clearTeamGoals
} from "../../../actions/supervisorActions";
import { getActiveProjects } from "../../../actions/projectActions";
import DropDownGroup from "../../common/DropDownGroup";
import moment from "moment-timezone";
import flattenDeep from "../../common/flattenDeep";
import { CSVLink } from "react-csv";
import {
  Button,
  CardPanel,
  Table,
  Icon,
  Checkbox,
  Card,
  Col,
  Row
} from "react-materialize";
import classnames from "classnames";

const today = new Date(moment());
const Sunday = moment(today).add(-1 * today.getDay(), "day");
const Saturday = moment(today).add(-1 * today.getDay() + 6, "day");

class SuperviseTimesheets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(moment()),
      Sunday: this.props.location.state
        ? this.props.location.state.startDate
        : Sunday,
      Saturday: this.props.location.state
        ? this.props.location.state.endDate
        : Saturday,
      thisWeek: this.props.location.state
        ? `${this.props.location.state.startDate},${
            this.props.location.state.endDate
          }`
        : `${Sunday.format("YYYY-MM-DD")},${Saturday.format("YYYY-MM-DD")}`,
      datesForDropDown: [],
      supervisorDropDown: [],
      selectedGroup: "All",
      currentStartOfWeek: this.props.location.state
        ? this.props.location.state.startDate
        : Sunday.format("YYYY-MM-DD"),
      currentEndOfWeek: this.props.location.state
        ? this.props.location.state.endDate
        : Saturday.format("YYYY-MM-DD"),
      supervisorUsers: [],
      projects: [],
      bulkDownload: [],
      numSelected: 0,
      removeCheck: false,
      addCheck: false,
      isGroupSelected: false,
      headers: [
        { label: "Name", key: "name" },
        { label: "Project", key: "project" },
        { label: "Task", key: "task" },
        { label: "Description", key: "description" },
        { label: "Sunday", key: "sunday" },
        { label: "Monday", key: "monday" },
        { label: "Tuesday", key: "tuesday" },
        { label: "Wednesday", key: "wednesday" },
        { label: "Thursday", key: "thursday" },
        { label: "Friday", key: "friday" },
        { label: "Saturday", key: "saturday" },
        { label: "Total", key: "total" }
      ],
      teamGoals: [],
      teamGoalLoading: true
    };
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onGroupSelect = this.onGroupSelect.bind(this);
    this.onUserSelect = this.onUserSelect.bind(this);
    this.onAllSelect = this.onAllSelect.bind(this);
    this.clearDownloads = this.clearDownloads.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (!this.props.auth.user.isSupervisor) {
        this.props.history.push("/timesheet");
      }
    } else {
      this.props.history.push("/login");
    }
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.isSupervisor) {
        this.props.getSupervisees(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
        this.props.getTimesheetDates();
        this.props.getTeamGoals(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
        this.props.getActiveProjects(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (
        this.props.supervisor.supervisorDates !==
        prevProps.supervisor.supervisorDates
      ) {
        let datesForSelection = [];
        flattenDeep(this.props.supervisor.supervisorDates).forEach(prettier => {
          datesForSelection.push([
            {
              label:
                moment(prettier._id.startDate).format("MMM Do YYYY") +
                " - " +
                moment(prettier._id.endDate).format("MMM Do YYYY"),
              value:
                moment(prettier._id.startDate).format("YYYY-MM-DD") +
                "," +
                moment(prettier._id.endDate).format("YYYY-MM-DD")
            }
          ]);
        });
        this.setState({
          datesForDropDown: flattenDeep(datesForSelection)
        });
      }
      if (
        this.props.supervisor.supervisorUsers !==
        prevProps.supervisor.supervisorUsers
      ) {
        let usersForSelection = [];
        let directReports, subDirectReports, supervisor;
        usersForSelection.push({ label: "All", value: "All" });
        directReports = this.props.supervisor.supervisorUsers.filter(
          users => users.steps === 0
        );
        subDirectReports = this.props.supervisor.supervisorUsers.filter(
          users => users.steps === 1
        );
        if (directReports.length > 0 && subDirectReports.length > 0)
          usersForSelection.push({ label: "Direct Reports", value: 0 });
        if (subDirectReports.length > 0) {
          subDirectReports.forEach(users => {
            if (
              !usersForSelection.find(
                dropdown => dropdown.value === users.supervisor
              )
            ) {
              supervisor = this.props.supervisor.supervisorUsers.find(
                props => props._id === users.supervisor
              );
              usersForSelection.push({
                label: `${supervisor.firstName} ${supervisor.lastName}'s Team`,
                value: supervisor._id
              });
            }
          });
        }
        this.setState({
          supervisorUsers: this.props.supervisor.supervisorUsers,
          supervisorDropDown: flattenDeep(usersForSelection)
        });
      }
      if (
        this.props.supervisor.supervisorTeamGoalUpdated &&
        !prevProps.supervisor.supervisorTeamGoalUpdated
      ) {
        this.props.getTeamGoals(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
      }
      if (
        this.props.supervisor.supervisorTeamGoals !==
        prevProps.supervisor.supervisorTeamGoals
      ) {
        this.setState({
          teamGoals: this.props.supervisor.supervisorTeamGoals,
          teamGoalLoading: false
        });
      }
      if (this.props.project !== prevProps.project) {
        if (this.props.project.projects !== prevProps.project.projects) {
          this.setState({ projects: this.props.project.projects });
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.clearUsers();
    this.props.clearUserTimesheets();
    this.props.clearTeamGoals();
  }

  onDateSelect = e => {
    this.setState({
      dateRange: e,
      bulkDownload: [],
      numSelected: 0,
      isGroupSelected: false
    });
    let newDateRange = e.split(",");
    this.setState(
      {
        currentStartOfWeek: newDateRange[0],
        currentEndOfWeek: newDateRange[1]
      },
      () => {
        this.props.getSupervisees(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
        this.props.getTeamGoals(
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
      }
    );
  };

  onGroupSelect = e => {
    this.props.clearUserTimesheets();
    this.setState(
      {
        supervisorUsers: [],
        bulkDownload: [],
        numSelected: 0,
        isGroupSelected: false
      },
      () => {
        switch (e) {
          case "All":
            this.setState({
              supervisorUsers: this.props.supervisor.supervisorUsers
            });
            break;
          case 0:
            this.setState({
              supervisorUsers: this.props.supervisor.supervisorUsers.filter(
                users => users.steps === 0
              )
            });
            break;
          default:
            this.setState({
              supervisorUsers: this.props.supervisor.supervisorUsers.filter(
                users => users.supervisor === e || users._id === e
              )
            });
            break;
        }
      }
    );
  };

  handleOptionChange(e) {
    this.setState({ selectedOption: e.target.value });
  }

  onUserSelect = (isChecked, userID, timesheetRows) => {
    if (isChecked) {
      this.setState(prevState => {
        prevState.bulkDownload = prevState.bulkDownload.concat(timesheetRows);
        prevState.numSelected = ++prevState.numSelected;
        return {
          bulkDownload: prevState.bulkDownload,
          numSelected: prevState.numSelected,
          isGroupSelected:
            prevState.numSelected ===
            this.props.supervisor.supervisorUserTimesheets
              .map(x => x.user_id)
              .filter(
                (item, index, self) =>
                  self.indexOf(item) === index && item !== undefined
              ).length
              ? true
              : false,
          removeCheck: false
        };
      });
    } else {
      this.setState(prevState => {
        prevState.bulkDownload = prevState.bulkDownload.filter(
          rows => rows.userID !== userID
        );
        prevState.numSelected = --prevState.numSelected;
        return {
          bulkDownload: prevState.bulkDownload,
          numSelected: prevState.numSelected,
          isGroupSelected:
            prevState.numSelected ===
            this.props.supervisor.supervisorUserTimesheets
              .map(x => x.user_id)
              .filter(
                (item, index, self) =>
                  self.indexOf(item) === index && item !== undefined
              ).length
              ? true
              : false,
          removeCheck: false,
          addCheck: false
        };
      });
    }
  };

  onAllSelect = () => {
    if (this.state.isGroupSelected) {
      this.clearDownloads();
    } else {
      let timesheetRows = this.props.supervisor.supervisorUserTimesheets.map(
        timesheet => {
          if (timesheet) {
            let returnedRows = timesheet.timesheetRows.map(row => {
              return {
                ...row,
                userID: timesheet.user_id,
                name: timesheet.name,
                project: row.project_name,
                task: row.task_name,
                sunday: row.sunday !== 0 ? row.sunday : "",
                monday: row.monday !== 0 ? row.monday : "",
                tuesday: row.tuesday !== 0 ? row.tuesday : "",
                wednesday: row.wednesday !== 0 ? row.wednesday : "",
                thursday: row.thursday !== 0 ? row.thursday : "",
                friday: row.friday !== 0 ? row.friday : "",
                saturday: row.saturday !== 0 ? row.saturday : ""
              };
            });
            return returnedRows;
          } else {
            return [];
          }
        }
      );
      this.setState({
        bulkDownload: flattenDeep(timesheetRows),
        numSelected: flattenDeep(timesheetRows)
          .map(x => x.userID)
          .filter((item, index, self) => self.indexOf(item) === index).length,
        isGroupSelected: true,
        addCheck: true,
        removeCheck: false
      });
    }
  };

  clearDownloads = () => {
    this.setState({
      bulkDownload: [],
      numSelected: 0,
      removeCheck: true,
      addCheck: false,
      isGroupSelected: false
    });
  };

  render() {
    const { supervisorUsers, supervisorUsersLoading } = this.props.supervisor;
    let ManageUserTableContent, ManageUserTable, teamGoal;
    if (
      supervisorUsers === null ||
      supervisorUsersLoading ||
      this.state.supervisorUsers === null
    ) {
      ManageUserTable = <Spinner />;
    } else {
      ManageUserTableContent = this.state.supervisorUsers.map(userRow => {
        return (
          <UserTimesheetRow
            key={userRow._id}
            id={userRow._id}
            name={userRow.lastName + ", " + userRow.firstName}
            firstName={userRow.firstName}
            lastName={userRow.lastName}
            supervisor_id={userRow.supervisor}
            directReport={userRow.steps === 0}
            downloadName={
              userRow.firstName.substring(0, 1) +
              userRow.lastName.substring(0, 1)
            }
            startOfWeek={this.state.currentStartOfWeek}
            endOfWeek={this.state.currentEndOfWeek}
            onUserSelect={this.onUserSelect}
            removeCheck={this.state.removeCheck}
            addCheck={this.state.addCheck && this.state.isGroupSelected}
            dropdownDates={this.state.datesForDropDown}
            email={userRow.email}
            projects={this.state.projects}
          />
        );
      });

      ManageUserTable = (
        <Fragment>
          <Card className="tableCard">
            <CardPanel
              className={classnames("tableHeaderBar", {
                "light-grey": this.state.numSelected > 0
              })}
              style={{ borderBottom: "1px solid #E1E2E1" }}
            >
              <div style={{ fontSize: "16px", height: "36px" }}>
                <Button
                  flat
                  className={classnames("left light-grey blue-text", {
                    hide: this.state.numSelected === 0
                  })}
                  onClick={this.clearDownloads}
                  style={{ textTransform: "none" }}
                >
                  {this.state.numSelected} user
                  {this.state.numSelected > 1 ? "s" : ""} selected
                  <Icon right>close</Icon>
                </Button>
                <CSVLink
                  data={this.state.bulkDownload.sort((a, b) =>
                    a.name > b.name ? 1 : -1
                  )}
                  headers={this.state.headers}
                  className={classnames("btn-flat right light-grey", {
                    hide: this.state.bulkDownload.length === 0
                  })}
                  filename={"timesheets.csv"}
                >
                  <Icon>get_app</Icon>
                </CSVLink>
              </div>
            </CardPanel>
            <Table hoverable className="white">
              <thead>
                <tr>
                  <th className="center-align" style={{ padding: 0 }}>
                    <Checkbox
                      filledIn
                      checked={this.state.isGroupSelected}
                      className="blue-text center-align headerCheck"
                      label=""
                      value=""
                      key={
                        `${this.props.startOfWeek}` +
                        `${this.props.endOfWeek}` +
                        `${this.state.isGroupSelected}`
                      }
                      onChange={() => this.onAllSelect()}
                      disabled={
                        this.props.supervisor.supervisorUserTimesheets
                          .map(x => x.user_id)
                          .filter(
                            (item, index, self) =>
                              self.indexOf(item) === index && item !== undefined
                          ).length === 0
                      }
                    />
                  </th>
                  <th className="text-left">Name</th>
                  <th className="text-left">Timesheet Status</th>
                  <th className="text-left">Last Modified</th>
                  <th className="text-right" style={{ width: "40%" }} />
                </tr>
              </thead>
              <tbody>{ManageUserTableContent}</tbody>
            </Table>
          </Card>
        </Fragment>
      );
    }

    if (this.state.teamGoalLoading) {
      teamGoal = <Goal goalLoading={true} />;
    } else if (this.state.teamGoals.length === 0) {
      teamGoal = (
        <Goal
          goal={null}
          labels={null}
          datasets={null}
          timesheetPage={false}
          directReportPage={false}
          enabled={true}
          projects={this.state.projects}
          projectsLoading={false}
          goalLoading={false}
        />
      );
    } else {
      teamGoal = (
        <Goal
          goal={this.state.teamGoals[0].goal}
          labels={this.state.teamGoals[0].labels}
          datasets={this.state.teamGoals[0].datasets}
          timesheetPage={false}
          directReportPage={false}
          enabled={true}
          projects={this.state.projects}
          projectsLoading={false}
          goalLoading={false}
        />
      );
    }
    return (
      <Fragment>
        <Card className="cardSmallBar">My Team</Card>
        <div
          style={{ marginTop: "24px", marginLeft: "88px", marginRight: "88px" }}
        >
          <Row className="valign-wrapper">
            <Col s={3} m={2} l={1}>
              <span
                style={{
                  fontSize: "16px",
                  textWeight: "500"
                }}
              >
                Viewing Week:
              </span>
            </Col>
            <Col s={4} m={4} l={2}>
              <DropDownGroup
                options={this.state.datesForDropDown}
                onSelect={this.onDateSelect}
                selectedOption={this.state.thisWeek}
                clearable={false}
                searchable={true}
              />
            </Col>
            <Col s={4} m={4} l={2}>
              <DropDownGroup
                options={this.state.supervisorDropDown}
                onSelect={this.onGroupSelect}
                selectedOption={this.state.selectedGroup}
                clearable={false}
              />
            </Col>
            <Col s={1} m={2} l={7} />
          </Row>
          <Row>
            <Col key={1} xl={3} l={6} m={6} s={12}>
              {teamGoal}
            </Col>

            <Col key={2} xl={9} l={6} m={6} s={0} />
          </Row>
          <div>
            <div>{ManageUserTable}</div>
          </div>
        </div>
      </Fragment>
    );
  }
}

SuperviseTimesheets.propTypes = {
  getSupervisees: PropTypes.func,
  getTimesheetDates: PropTypes.func,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  supervisor: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  project: state.project,
  supervisor: state.supervisor
});

export default connect(
  mapStateToProps,
  {
    getSupervisees,
    getTimesheetDates,
    getTeamGoals,
    getActiveProjects,
    clearUsers,
    clearUserTimesheets,
    clearTeamGoals
  }
)(SuperviseTimesheets);
