import React, { Component } from "react";
import { connect } from "react-redux";
import { getVersions } from "../../actions/versionActions";
import RevisionHistoryRow from "./RevisionHistoryRow";
import Spinner from "../common/Spinner";
import { Collapsible } from "react-materialize";

class RevisionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versions: []
    };
  }
  componentDidMount() {
    this.props.getVersions();
  }

  componentDidUpdate(prevProps) {
    if (this.props.versions.allVersions !== prevProps.versions.allVersions)
      this.setState({ versions: this.props.versions.allVersions });
  }

  render() {
    const { versionsLoading } = this.props.versions;
    let revisionHistoryContent;
    if (versionsLoading) {
      revisionHistoryContent = <Spinner />;
    } else {
      revisionHistoryContent = this.state.versions.map(version => (
        <RevisionHistoryRow
          key={version._id}
          _id={version._id}
          date={version.date}
          number={version.number}
          notes={version.notes}
        />
      ));
    }

    return (
      <div className="container-fluid w-75" id="revisionHistoryAccordion">
        <Collapsible popout>{revisionHistoryContent}</Collapsible>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  versions: state.versions
});

export default connect(
  mapStateToProps,
  { getVersions }
)(RevisionHistory);
