const addDirectReportGoals = (goals, payload) => {
  let test = false;
  if (goals.length === 0) {
    goals = goals.concat(payload);
    return goals;
  } else {
    let updatedGoals = goals.map(goal => {
      if (goal.user_id.toString() === payload.user_id.toString()) {
        test = true;
        return payload;
      }
      return goal;
    });
    if (!test) {
      updatedGoals = updatedGoals.concat(payload);
    }
    return updatedGoals;
  }
};
export default addDirectReportGoals;
