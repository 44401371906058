import React, { Component, Fragment } from 'react';
import TextFieldGroup from '../../common/TextFieldGroup';
import PropTypes from 'prop-types';
import {
  editUser,
  getSupervisees,
  updateUserGoals,
  deleteUserGoals,
} from '../../../actions/adminActions';
import { connect } from 'react-redux';
import DropDownGroup from '../../common/DropDownGroup';
import flattenDeep from '../../common/flattenDeep';
import { Button, Modal, Switch, Row, Col, Icon } from 'react-materialize';
import SupervisorSelection from './SupervisorSelection';

class UserRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      firstName: props.firstName,
      lastName: props.lastName,
      email: props.email,
      isActive: props.isActive,
      isAdmin: props.isAdmin,
      isSupervisor: props.isSupervisor,
      supervisor: props.supervisor,
      createdOn: props.createdOn,
      tempFirst: props.firstName,
      tempLast: props.lastName,
      tempEmail: props.email,
      tempActive: props.isActive,
      tempAdmin: props.isAdmin,
      tempSupervisorStatus: props.isSupervisor,
      tempSupervisor: props.supervisor,
      tempEmployeeType: props.employeeType,
      employeeTypes: props.employeeTypes,
      supervisors: props.supervisors,
      disableDropdowns: !props.isActive,
      errors: {},
      successes: {},
      didSave: false,
    };

    this.onChange = this.onChange.bind(this);
    this.editUserSave = this.editUserSave.bind(this);
    this.resetUser = this.resetUser.bind(this);
    this.onSupervisorEdit = this.onSupervisorEdit.bind(this);
    this.getSupervisorFullName = this.getSupervisorFullName.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name.replace(this.state.id, '')]: e.target.value,
    });
  }

  componentDidMount() {
    if (this.state.isSupervisor) {
      this.props.getSupervisees(this.state.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.didSave) {
      if (nextProps.errors) {
        this.setState({ errors: nextProps.errors });
        this.setState({
          didSave: true,
        });
      }
      if (nextProps.successes) {
        if (Object.keys(nextProps.successes).length > 0) {
          window.location.reload();
        }
      }
    }
  }

  onSelect = () => {
    this.setState((prevState) => {
      return {
        tempActive: !prevState.tempActive,
        tempAdmin: prevState.tempActive ? false : prevState.tempAdmin,
        tempSupervisorStatus: prevState.tempActive
          ? false
          : prevState.tempSupervisorStatus,
        tempSupervisor: prevState.tempActive ? null : prevState.tempSupervisor,
        disableDropdowns: prevState.tempActive
          ? true
          : !prevState.disableDropdowns,
      };
    });
  };

  onAdminSelect = (e) => {
    this.setState((prevState) => {
      return { tempAdmin: !prevState.tempAdmin };
    });
  };

  onSupervisorStatusSelect = () => {
    this.setState((prevState) => {
      return { tempSupervisorStatus: !prevState.tempSupervisorStatus };
    });
  };

  onEmployeeTypeSelect = (e) => {
    this.setState({ tempEmployeeType: e });
  };

  onSupervisorSelect = (e) => {
    this.setState({ tempSupervisor: e });
  };

  onSupervisorEdit = (newSupervisor, superviseeID) => {
    this.setState({ [superviseeID]: newSupervisor });
  };

  resetUser() {
    this.setState({
      tempFirst: this.props.firstName,
      tempLast: this.props.lastName,
      tempEmail: this.props.email,
      tempActive: this.props.isActive,
      tempAdmin: this.props.isAdmin,
      tempSupervisorStatus: this.props.isSupervisor,
      tempSupervisor: this.props.supervisor,
      tempEmployeeType: this.props.employeeType,
      disableDropdowns: false,
      errors: {},
    });
  }

  editUserSave() {
    let supervisees;
    let userData = {
      id: this.state.id,
      firstName: this.state.tempFirst,
      lastName: this.state.tempLast,
      email: this.state.tempEmail,
      isActive: this.state.tempActive,
      isAdmin: this.state.tempAdmin,
      isSupervisor: this.state.tempSupervisorStatus,
      supervisor: this.state.tempSupervisor,
      employeeType: this.state.tempEmployeeType,
    };

    if (
      this.state.isSupervisor &&
      (!this.state.tempSupervisorStatus || !this.state.tempActive)
    ) {
      supervisees = this.props.admin.adminSupervisees.filter(
        (supervisor) => supervisor.supervisor === this.state.id.toString()
      );
      supervisees = supervisees.map((supervisee) => {
        return {
          _id: supervisee._id,
          supervisor:
            this.state[supervisee._id] !== undefined
              ? this.state[supervisee._id]
              : this.state.supervisor,
          old_supervisor: this.state.id,
        };
      });
      userData = {
        ...userData,
        ...{ reassign: supervisees },
      };
    }

    // Update User Information
    this.props.editUser(userData);

    // Update User Team Goal if Supervisor is different
    if (this.state.supervisor !== this.state.tempSupervisor) {
      this.props.updateUserGoals({
        user_id: userData.id,
        old_supervisor: this.state.supervisor,
        new_supervisor: this.state.tempSupervisor,
      });
    }

    // Remove User Goals if user is getting set as Inactive
    if (this.state.isActive && !this.state.tempActive) {
      this.props.deleteUserGoals({
        user_id: userData.id,
      });
    }

    // Update all Supervisee's if the User is losing Supervisor Status after Bulk Reassign
    if (userData.reassign && userData.reassign.length > 0) {
      userData.reassign.forEach((user) => {
        this.props.updateUserGoals({
          user_id: user._id,
          old_supervisor: user.old_supervisor,
          new_supervisor: user.supervisor,
        });
      });
    }

    this.setState({
      didSave: true,
    });
  }

  getSupervisorFullName = (e) => {
    return e.lastName + ', ' + e.firstName;
  };

  render() {
    const { errors, successes } = this.state;
    let supervisees,
      supervisors = [],
      employeeTypes = [],
      supervisorBulkEdit,
      newSupervisors;
    employeeTypes = this.state.employeeTypes.map((employeeType) => {
      return {
        label: employeeType.type,
        value: employeeType._id,
      };
    });
    supervisors = this.state.supervisors.map((supervisor) => {
      return {
        label: supervisor.firstName + ' ' + supervisor.lastName,
        value: supervisor._id,
      };
    });
    supervisors = flattenDeep(supervisors);
    supervisors = supervisors.filter(
      (users) => users.value !== this.state.id.toString()
    );

    if (
      this.state.isSupervisor &&
      (!this.state.tempSupervisorStatus || !this.state.tempActive)
    ) {
      supervisees = this.props.admin.adminSupervisees.filter(
        (supervisor) => supervisor.supervisor === this.state.id.toString()
      );
      if (supervisees.length > 0) {
        supervisorBulkEdit = supervisees.map((supervisee) => (
          <div className='mb-3' key={this.state.id + supervisee._id}>
            <SupervisorSelection
              title={supervisee.firstName + ' ' + supervisee.lastName}
              id={supervisee._id}
              selectedOption={this.state.supervisor}
              options={supervisors.filter(
                (supervisor) => supervisor.value !== supervisee._id.toString()
              )}
              onSelect={this.onSupervisorEdit}
              clearable={false}
            />
          </div>
        ));

        newSupervisors = (
          <div className='text-left'>
            <span className='pb-3'>
              Please select a new supervisor for these user(s):
            </span>
            <br />
            {supervisorBulkEdit}
          </div>
        );
      }
    }
    return (
      <tr>
        <td className='text-left align-middle px-4'>{this.state.firstName}</td>
        <td className='text-left align-middle px-4'>{this.state.lastName}</td>
        <td className='text-left align-middle px-4'>{this.state.email}</td>
        <td className='text-left align-middle px-4'>
          {this.state.isAdmin
            ? 'Admin'
            : this.state.isSupervisor
            ? 'Supervisor'
            : 'User'}
        </td>
        <td className='text-left align-middle px-4'>
          {this.state.isActive ? 'Active' : 'Inactive'}
        </td>
        <td className='text-left align-middle px-4'>
          {typeof this.state.supervisor === 'string'
            ? this.getSupervisorFullName(
                this.state.supervisors[
                  this.state.supervisors.findIndex(
                    (index) => index._id === this.state.supervisor
                  )
                ]
              )
            : ''}
        </td>
        <td className='text-center align-middle'>
          <div className='text-right align-middle'>
            <Modal
              className='modal-fix admin-modal'
              header={this.state.firstName + ' ' + this.state.lastName}
              trigger={
                <Button
                  flat
                  icon={<Icon>edit</Icon>}
                  className='hide showOnHover'
                />
              }
              options={{ onCloseEnd: this.resetUser }}
              actions={
                <Fragment>
                  <Button flat modal='close'>
                    Close
                  </Button>
                  <Button
                    flat
                    className='blue-text'
                    type='submit'
                    onClick={this.editUserSave}
                  >
                    Save
                  </Button>
                </Fragment>
              }
            >
              <div className='divider' />
              <div className='modalPadding'>
                <Row>
                  <Col s={6}>
                    <div className='mb-2'>
                      <h5 className='text-left'>Aeon ID</h5>
                      <TextFieldGroup
                        placeholder='ID'
                        name={this.state.id}
                        type='text'
                        value={'Aeon ID - ' + this.state.id}
                        onChange={this.onChange}
                        size=''
                        disabled={true}
                      />
                    </div>
                    <div className='mb-2'>
                      <h5 className='text-left'>First Name</h5>
                      <TextFieldGroup
                        placeholder='First Name'
                        name={'tempFirst' + this.state.id}
                        type='text'
                        value={this.state.tempFirst}
                        onChange={this.onChange}
                        size=''
                        error={errors.firstName}
                        success={successes.firstName}
                      />
                    </div>
                    <div className='mb-2'>
                      <h5 className='text-left'>Last Name</h5>
                      <TextFieldGroup
                        placeholder='Last Name'
                        name={'tempLast' + this.state.id}
                        type='text'
                        value={this.state.tempLast}
                        onChange={this.onChange}
                        size=''
                        error={errors.lastName}
                        success={successes.lastName}
                      />
                    </div>
                    <div className='mb-2'>
                      <h5 className='text-left'>Email</h5>
                      <TextFieldGroup
                        placeholder='Email'
                        name={'tempEmail' + this.state.id}
                        type='text'
                        value={this.state.tempEmail}
                        onChange={this.onChange}
                        size=''
                        error={errors.email}
                        success={successes.email}
                      />
                    </div>
                    <div className='text-left mb-3'>
                      <Switch
                        id={'Status - ' + this.state.id}
                        checked={this.state.tempActive}
                        onChange={this.onSelect}
                        onLabel='Active'
                        offLabel='Inactive'
                      />
                    </div>
                  </Col>
                  <Col s={6}>
                    <div className='text-left mb-3'>
                      <Switch
                        id={'Admin - ' + this.state.id}
                        checked={this.state.tempAdmin}
                        onChange={this.onAdminSelect}
                        disabled={this.state.disableDropdowns}
                        onLabel='Admin'
                        offLabel='Not an Admin'
                      />
                    </div>
                    <div className='text-left mb-3'>
                      <Switch
                        id={'Supervisor - ' + this.state.id}
                        checked={this.state.tempSupervisorStatus}
                        onChange={this.onSupervisorStatusSelect}
                        onLabel='Supervisor'
                        offLabel='Not a Supervisor'
                        disabled={this.state.disableDropdowns}
                      />
                    </div>
                    <div className='text-left mb-3'>
                      <DropDownGroup
                        selectedOption={this.state.tempEmployeeType}
                        onSelect={this.onEmployeeTypeSelect}
                        options={employeeTypes}
                        title='Employee Types'
                        clearable={true}
                        disabled={this.state.disableDropdowns}
                      />
                    </div>
                    <div className='text-left mb-3'>
                      <DropDownGroup
                        selectedOption={this.state.tempSupervisor}
                        onSelect={this.onSupervisorSelect}
                        options={supervisors}
                        title='Supervisor'
                        clearable={true}
                        disabled={this.state.disableDropdowns}
                      />
                    </div>
                    {newSupervisors}
                  </Col>
                </Row>
              </div>
            </Modal>
          </div>
        </td>
      </tr>
    );
  }
}

UserRow.propTypes = {
  editUser: PropTypes.func.isRequired,
  getSupervisees: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  successes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  auth: state.auth,
  errors: state.errors,
  successes: state.successes,
});

export default connect(mapStateToProps, {
  editUser,
  getSupervisees,
  updateUserGoals,
  deleteUserGoals,
})(UserRow);
