import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DropDownGroup from "../../common/DropDownGroup";
import UserTimesheetRowReview from "./UserTimesheetRowReview";
import Goal from "../../common/Goal";
import moment from "moment-timezone";
import { Button, CardPanel, Table, Card, Col, Row } from "react-materialize";
import classnames from "classnames";
import {
  submitTimesheet,
  getUserDetailTimesheet,
  clearUserDetailTimesheet,
  createDirectReportGoal,
  updateDirectReportGoal,
  getDirectReportGoals
} from "../../../actions/supervisorActions";
import { Link } from "react-router-dom";
import md5 from "md5";

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStartOfWeek: this.props.location.state
        ? this.props.location.state.startDate
        : null,
      currentEndOfWeek: this.props.location.state
        ? this.props.location.state.endDate
        : null,
      firstName: this.props.location.state
        ? this.props.location.state.firstName
        : "",
      lastName: this.props.location.state
        ? this.props.location.state.lastName
        : "",
      email: this.props.location.state ? this.props.location.state.email : "",
      dropdownDates: this.props.location.state
        ? this.props.location.state.dropdownDates
        : [],
      id: this.props.location.state ? this.props.location.state.id : null,
      supervisor_id: this.props.location.state
        ? this.props.location.state.supervisor_id
        : null,
      directReport: this.props.location.state
        ? this.props.location.state.directReport
        : false,
      timesheet: this.props.location.state
        ? this.props.location.state.timesheet
        : null,
      projects: this.props.location.state
        ? this.props.location.state.projects
        : [],
      goals: this.props.location.state ? this.props.location.state.goals : [],
      goalsLoading: false
    };
    this.onDateSelect = this.onDateSelect.bind(this);
    this.submitTimesheet = this.submitTimesheet.bind(this);
  }

  componentWillMount() {}

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (!this.props.auth.user.isSupervisor) {
        this.props.history.push("/timesheet");
      }
    } else {
      this.props.history.push("/login");
    }
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.isSupervisor) {
        if (!this.props.location.state) {
          this.props.history.push("/myteam");
        }
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.supervisor.supervisorUserDetailTimesheet !== null &&
      this.props.supervisor.supervisorUserDetailTimesheet !==
        prevProps.supervisor.supervisorUserDetailTimesheet
    ) {
      this.setState({
        timesheet: this.props.supervisor.supervisorUserDetailTimesheet
      });
    }

    if (
      this.props.supervisor.supervisorUserGoals !==
      prevProps.supervisor.supervisorUserGoals
    ) {
      this.props.supervisor.supervisorUserGoals.forEach(goal => {
        if (goal.user_id.toString() === this.state.id.toString()) {
          this.setState({ goals: goal.goals, goalsLoading: false });
        }
      });
    }

    if (this.props.project.projects !== prevProps.project.projects) {
      this.setState({ projects: this.props.project.projects });
    }

    if (
      this.props.supervisor.supervisorUserGoalsUpdated &&
      !prevProps.supervisor.supervisorUserGoalsUpdated
    ) {
      this.props.getDirectReportGoals(
        this.state.id,
        this.state.supervisor_id,
        this.state.currentStartOfWeek,
        this.state.currentEndOfWeek
      );
    }
  }

  componentWillUnmount() {
    this.props.clearUserDetailTimesheet();
  }

  onDateSelect = e => {
    let newDateRange = e.split(",");
    this.setState(
      {
        currentStartOfWeek: newDateRange[0],
        currentEndOfWeek: newDateRange[1]
      },
      () => {
        this.props.getUserDetailTimesheet(
          this.state.id,
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
        this.props.getDirectReportGoals(
          this.state.id,
          this.state.supervisor_id,
          this.state.currentStartOfWeek,
          this.state.currentEndOfWeek
        );
      }
    );
  };

  submitTimesheet(e) {
    const submitData = {
      id: e
    };
    this.props.submitTimesheet(submitData);
    window.location.reload();
  }

  render() {
    let timesheetTable, userGoals;
    if (this.state.timesheet) {
      timesheetTable = (
        <Fragment>
          <Card className="tableCard">
            <CardPanel
              style={{ marginTop: "0px" }}
              className={classnames("tableHeaderBar", {
                "light-grey": this.state.numSelected > 0
              })}
            >
              <div
                className="valign-wrapper"
                style={{
                  fontSize: "16px",
                  height: "36px",
                  paddingLeft: "26px"
                }}
              >
                Timesheet
              </div>
            </CardPanel>
            <Table hoverable className="white">
              <thead>
                <tr>
                  <th className="text-left pl-4">Project</th>
                  <th className="text-left">Task</th>
                  <th className="text-left">Description</th>
                  <th className="text-center">Su</th>
                  <th className="text-center">Mo</th>
                  <th className="text-center">Tu</th>
                  <th className="text-center">We</th>
                  <th className="text-center">Th</th>
                  <th className="text-center">Fr</th>
                  <th className="text-center">Sa</th>
                  <th className="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                {this.state.timesheet.timesheetRows.map(row => {
                  return (
                    <UserTimesheetRowReview key={row._id} reviewRow={row} />
                  );
                })}
              </tbody>
              <tfoot>
                <tr>
                  <td style={{ height: "40px" }} />
                  <td style={{ height: "40px" }} />
                  <td
                    style={{ height: "40px" }}
                    className="text-right align-middle"
                  >
                    Daily Total
                  </td>
                  <td
                    style={{ height: "40px" }}
                    className="text-center align-middle"
                  >
                    {this.state.timesheet.sundayTotal}
                  </td>
                  <td
                    style={{ height: "40px" }}
                    className="text-center align-middle"
                  >
                    {this.state.timesheet.mondayTotal}
                  </td>
                  <td
                    style={{ height: "40px" }}
                    className="text-center align-middle"
                  >
                    {this.state.timesheet.tuesdayTotal}
                  </td>
                  <td
                    style={{ height: "40px" }}
                    className="text-center align-middle"
                  >
                    {this.state.timesheet.wednesdayTotal}
                  </td>
                  <td
                    style={{ height: "40px" }}
                    className="text-center align-middle"
                  >
                    {this.state.timesheet.thursdayTotal}
                  </td>
                  <td
                    style={{ height: "40px" }}
                    className="text-center align-middle"
                  >
                    {this.state.timesheet.fridayTotal}
                  </td>
                  <td
                    style={{ height: "40px" }}
                    className="text-center align-middle"
                  >
                    {this.state.timesheet.saturdayTotal}
                  </td>
                  <td
                    style={{ height: "40px" }}
                    className="text-center align-middle"
                  >
                    {this.state.timesheet.grandTotal !== 0
                      ? this.state.timesheet.grandTotal
                      : ""}
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Card>
        </Fragment>
      );
    } else {
      timesheetTable = "";
    }
    console.log(this.state.goalsLoading);
    if (this.state.goalsLoading) {
      userGoals = (
        <Fragment>
          <Col s={3}>
            <Goal goalLoading={true} />
          </Col>
          <Col s={3}>
            <Goal goalLoading={true} />
          </Col>
          <Col s={3}>
            <Goal goalLoading={true} />
          </Col>
        </Fragment>
      );
    } else {
      userGoals = (
        <Fragment>
          {this.state.goals.map((obj, index) => {
            return (
              <Col key={index} s={3}>
                <Goal
                  goal={obj.goal}
                  labels={obj.labels}
                  datasets={obj.datasets}
                  timesheetPage={false}
                  directReportPage={true}
                  enabled={this.state.directReport}
                  directReport={this.state.id}
                  projects={this.state.projects}
                  goalLoading={false}
                />
              </Col>
            );
          })}
        </Fragment>
      );
    }

    return (
      <Fragment>
        <Card className="cardSmallBar">
          <Link
            to={{
              pathname: "/myteam",
              state: {
                startDate: this.props.location.state
                  ? this.props.location.state.startDate
                  : "",
                endDate: this.props.location.state
                  ? this.props.location.state.endDate
                  : ""
              }
            }}
            style={{
              fontFamily: "'Roboto', sans-serif",
              textTransform: "none",
              height: "14.4px",
              lineHeight: "inherit",
              padding: 0,
              border: 0,
              color: "rgba(0, 0, 0, .54)"
            }}
            className="btn-flat white"
          >
            My Team
          </Link>
          <Button
            style={{
              fontFamily: "'Roboto', sans-serif",
              textTransform: "none",
              height: "14.4px",
              lineHeight: "inherit",
              padding: 0,
              border: 0
            }}
            flat
          >
            {" "}
            > {this.state.firstName} {this.state.lastName}
          </Button>
        </Card>
        <div
          style={{ marginTop: "24px", marginLeft: "88px", marginRight: "88px" }}
        >
          <Row className="valign-wrapper">
            <Col s={3} m={2} l={1}>
              <span style={{ fontSize: "16px", textWeight: "500" }}>
                Viewing Week:
              </span>
            </Col>
            <Col s={4} m={4} l={2}>
              <DropDownGroup
                options={this.state.dropdownDates}
                onSelect={this.onDateSelect}
                selectedOption={`${this.state.currentStartOfWeek},${
                  this.state.currentEndOfWeek
                }`}
                clearable={false}
                searchable={true}
              />
            </Col>
            <Col s={5} m={6} l={9} />
          </Row>
          <Row className="valign-wrapper" style={{ marginBottom: 0 }}>
            <Col s={3}>
              <Card style={{ height: "201px" }}>
                <Row>
                  <Col s={3} className="text-center">
                    <img
                      src={`https://${this.state.email.substring(
                        this.state.email.indexOf("@") + 1,
                        this.state.email.indexOf(".")
                      )}.bamboohr.com/employees/photos/?h=${md5(
                        this.state.email
                      )}`}
                      alt="profile"
                      style={{
                        height: "50px",
                        borderRadius: "50%",
                        marginTop: "20px"
                      }}
                    />
                  </Col>
                  <Col s={9}>
                    <h5>
                      {this.state.firstName} {this.state.lastName}
                    </h5>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px"
                      }}
                    >
                      {this.state.email}
                    </div>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "14px",
                        color: "rgba(0, 0, 0, 0.54)",
                        paddingTop: "8px"
                      }}
                    >
                      Timesheet Status:{" "}
                      {this.state.timesheet && this.state.timesheet.isSubmitted
                        ? "Submitted"
                        : this.state.timesheet
                        ? "In Progress"
                        : "Not Started"}
                    </div>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "12px",
                        lineHeight: "14px",
                        color: "rgba(0, 0, 0, 0.54)",
                        paddingTop: "6px"
                      }}
                    >
                      Last Modified:{" "}
                      {this.state.timesheet && this.state.timesheet.lastUpdate
                        ? moment(this.state.timesheet.lastUpdate).fromNow()
                        : "Never"}
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
            {userGoals}
          </Row>
          <Row>
            <Col offset="s3" s={9}>
              {timesheetTable}
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

UserPage.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  supervisor: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  supervisor: state.supervisor,
  project: state.project
});

export default connect(
  mapStateToProps,
  {
    submitTimesheet,
    getUserDetailTimesheet,
    clearUserDetailTimesheet,
    createDirectReportGoal,
    updateDirectReportGoal,
    getDirectReportGoals
  }
)(UserPage);
