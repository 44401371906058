import axios from "axios";
import { VERSIONS_LOADING, GET_VERSIONS, GET_LATEST_VERSION } from "./types";

// Get all Versions contained in the database for Revision History Page
export const getVersions = () => dispatch => {
  dispatch(setVersionLoading());
  axios
    .get("/api/versions/getVersions")
    .then(res =>
      dispatch({
        type: GET_VERSIONS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_VERSIONS,
        payload: null
      })
    );
};

// Get the most recent version contained in the database for the Revision History Modal
export const getLatestVersion = () => dispatch => {
  axios
    .get("/api/versions/getLatestVersion")
    .then(res =>
      dispatch({
        type: GET_LATEST_VERSION,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_LATEST_VERSION,
        payload: null
      })
    );
};

// Loading
export const setVersionLoading = () => {
  return {
    type: VERSIONS_LOADING
  };
};
