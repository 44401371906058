import React, { Component } from "react";

class UserTimesheetRowReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: props.reviewRow._id,
      project: props.reviewRow.project_name,
      task: props.reviewRow.task_name,
      description: props.reviewRow.description,
      sunday: props.reviewRow.sunday !== 0 ? props.reviewRow.sunday : "",
      monday: props.reviewRow.monday !== 0 ? props.reviewRow.monday : "",
      tuesday: props.reviewRow.tuesday !== 0 ? props.reviewRow.tuesday : "",
      wednesday:
        props.reviewRow.wednesday !== 0 ? props.reviewRow.wednesday : "",
      thursday: props.reviewRow.thursday !== 0 ? props.reviewRow.thursday : "",
      friday: props.reviewRow.friday !== 0 ? props.reviewRow.friday : "",
      saturday: props.reviewRow.saturday !== 0 ? props.reviewRow.saturday : "",
      total: props.reviewRow.total
    };
  }

  render() {
    return (
      <tr>
        <td style={{ height: "40px" }} className="text-left align-middle pl-4">
          {this.state.project}
        </td>
        <td style={{ height: "40px" }} className="text-left align-middle">
          {this.state.task}
        </td>
        <td style={{ height: "40px" }} className="text-left align-middle">
          {this.state.description}
        </td>
        <td style={{ height: "40px" }} className="text-center align-middle">
          {this.state.sunday}
        </td>
        <td style={{ height: "40px" }} className="text-center align-middle">
          {this.state.monday}
        </td>
        <td style={{ height: "40px" }} className="text-center align-middle">
          {this.state.tuesday}
        </td>
        <td style={{ height: "40px" }} className="text-center align-middle">
          {this.state.wednesday}
        </td>
        <td style={{ height: "40px" }} className="text-center align-middle">
          {this.state.thursday}
        </td>
        <td style={{ height: "40px" }} className="text-center align-middle">
          {this.state.friday}
        </td>
        <td style={{ height: "40px" }} className="text-center align-middle">
          {this.state.saturday}
        </td>
        <td style={{ height: "40px" }} className="text-center align-middle">
          {this.state.total}
        </td>
      </tr>
    );
  }
}

export default UserTimesheetRowReview;
