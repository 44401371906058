import React, { Component } from "react";
import {
  getUserTimesheet,
  clearUserTimesheets,
  submitTimesheet,
  getDirectReportGoals
} from "../../../actions/supervisorActions";
import { connect } from "react-redux";
import moment from "moment";
import UserTimesheetRowReview from "./UserTimesheetRowReview";
import sortRowNumber from "../../common/sortRowNumber";
import { Link } from "react-router-dom";
import { Button, Modal, Table, Checkbox } from "react-materialize";

class UserTimesheetRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      name: props.name,
      firstName: props.firstName,
      downloadName: props.downloadName,
      startDate: props.startOfWeek,
      endDate: props.endOfWeek,
      supervisor: props.supervisor_id,
      directReport: props.directReport,
      project: props.projects,
      goals: [],
      isChecked: false,
      headers: [
        { label: "Name", key: "name" },
        { label: "Project", key: "project" },
        { label: "Task", key: "task" },
        { label: "Description", key: "description" },
        { label: "Sunday", key: "sunday" },
        { label: "Monday", key: "monday" },
        { label: "Tuesday", key: "tuesday" },
        { label: "Wednesday", key: "wednesday" },
        { label: "Thursday", key: "thursday" },
        { label: "Friday", key: "friday" },
        { label: "Saturday", key: "saturday" },
        { label: "Total", key: "total" }
      ]
    };
    this.submitTimesheet = this.submitTimesheet.bind(this);
    this.onSelectRow = this.onSelectRow.bind(this);
  }

  componentDidMount() {
    this.props.getUserTimesheet(
      this.props.id,
      this.state.startDate,
      this.state.endDate
    );
    this.props.getDirectReportGoals(
      this.props.id,
      this.props.supervisor_id,
      this.state.startDate,
      this.state.endDate
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.projects !== nextProps.projects) {
      this.setState({ projects: nextProps.projects });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.supervisor !== prevProps.supervisor) {
      if (this.props.supervisor.supervisorUserGoals.length !== 0) {
        let goals = this.props.supervisor.supervisorUserGoals.filter(
          goal => goal.user_id === this.state.id
        );
        if (goals.length !== 0) {
          this.setState({
            goals: goals[0].goals,
            supervisorUserRows: this.props.supervisor.supervisorUserTimesheets,
            isChecked: false
          });
        }
      } else {
        this.setState({
          supervisorUserRows: this.props.supervisor.supervisorUserTimesheets,
          isChecked: false
        });
      }
    }
    if (this.props.endOfWeek !== prevProps.endOfWeek) {
      this.props.clearUserTimesheets();
      this.props.getUserTimesheet(
        this.props.id,
        this.props.startOfWeek,
        this.props.endOfWeek
      );
      this.props.getDirectReportGoals(
        this.props.id,
        this.props.supervisor_id,
        this.props.startOfWeek,
        this.props.endOfWeek
      );
    }
    if (
      this.props.removeCheck &&
      this.props.removeCheck !== prevProps.removeCheck
    ) {
      this.setState({ isChecked: false });
    }
    if (this.props.addCheck !== prevProps.addCheck) {
      if (this.props.addCheck) this.setState({ isChecked: true });
    }
  }

  submitTimesheet(e) {
    const submitData = {
      id: e
    };
    this.props.submitTimesheet(submitData);
    window.location.reload();
  }

  onSelectRow = e => {
    this.setState(
      prevState => ({
        isChecked: !prevState.isChecked
      }),
      () => {
        let rowTimesheet = this.props.supervisor.supervisorUserTimesheets[
          this.props.supervisor.supervisorUserTimesheets.findIndex(
            index => index.user_id === this.props.id
          )
        ];
        let timesheetRows = rowTimesheet.timesheetRows.map(row => {
          return {
            ...row,
            userID: this.props.id,
            name: this.props.name,
            project: row.project_name,
            task: row.task_name,
            sunday: row.sunday !== 0 ? row.sunday : "",
            monday: row.monday !== 0 ? row.monday : "",
            tuesday: row.tuesday !== 0 ? row.tuesday : "",
            wednesday: row.wednesday !== 0 ? row.wednesday : "",
            thursday: row.thursday !== 0 ? row.thursday : "",
            friday: row.friday !== 0 ? row.friday : "",
            saturday: row.saturday !== 0 ? row.saturday : ""
          };
        });
        this.props.onUserSelect(
          this.state.isChecked,
          this.props.id,
          timesheetRows
        );
      }
    );
  };

  render() {
    const {
      supervisorUserTimesheets,
      supervisorUserTimesheetsLoading
    } = this.props.supervisor;
    let isSubmitted,
      lastUpdated,
      timesheetID,
      sundayTotal,
      mondayTotal,
      tuesdayTotal,
      wednesdayTotal,
      thursdayTotal,
      fridayTotal,
      saturdayTotal,
      grandTotal,
      timesheetLink,
      userTimesheetContent,
      userTimesheetRow = [],
      userTimesheet,
      modalFooter,
      data = [];
    if (!supervisorUserTimesheetsLoading) {
      userTimesheet = supervisorUserTimesheets.filter(
        timesheet => timesheet.user_id === this.state.id
      );
      if (userTimesheet.length > 0) {
        isSubmitted = userTimesheet[0].isSubmitted;
        lastUpdated = userTimesheet[0].lastUpdate;
        timesheetID = userTimesheet[0]._id;
        sundayTotal = userTimesheet[0].sundayTotal;
        mondayTotal = userTimesheet[0].mondayTotal;
        tuesdayTotal = userTimesheet[0].tuesdayTotal;
        wednesdayTotal = userTimesheet[0].wednesdayTotal;
        thursdayTotal = userTimesheet[0].thursdayTotal;
        fridayTotal = userTimesheet[0].fridayTotal;
        saturdayTotal = userTimesheet[0].saturdayTotal;
        grandTotal = userTimesheet[0].grandTotal;

        // Sort the rows by RowNumber
        userTimesheet[0].timesheetRows.sort(sortRowNumber);

        userTimesheet[0].timesheetRows.forEach(row => {
          data.push([
            {
              name: this.state.name,
              project: row.project_name,
              task: row.task_name,
              description: row.description,
              sunday: row.sunday !== 0 ? row.sunday : "",
              monday: row.monday !== 0 ? row.monday : "",
              tuesday: row.tuesday !== 0 ? row.tuesday : "",
              wednesday: row.wednesday !== 0 ? row.wednesday : "",
              thursday: row.thursday !== 0 ? row.thursday : "",
              friday: row.friday !== 0 ? row.friday : "",
              saturday: row.saturday !== 0 ? row.saturday : "",
              total: row.total
            }
          ]);
          userTimesheetRow.push(
            <UserTimesheetRowReview key={row._id} reviewRow={row} />
          );
        });
      }
    }
    if (timesheetID) {
      userTimesheetContent = (
        <Table>
          <thead>
            <tr>
              <th className="text-left">Project</th>
              <th className="text-left">Task</th>
              <th className="text-left">Description</th>
              <th className="text-center">Su</th>
              <th className="text-center">Mo</th>
              <th className="text-center">Tu</th>
              <th className="text-center">We</th>
              <th className="text-center">Th</th>
              <th className="text-center">Fr</th>
              <th className="text-center">Sa</th>
              <th className="text-center">Total</th>
            </tr>
          </thead>
          <tbody>{userTimesheetRow}</tbody>
          <tfoot>
            <tr>
              <td />
              <td />
              <td className="text-right align-middle">Daily Total</td>
              <td className="text-center align-middle">{sundayTotal}</td>
              <td className="text-center align-middle">{mondayTotal}</td>
              <td className="text-center align-middle">{tuesdayTotal}</td>
              <td className="text-center align-middle">{wednesdayTotal}</td>
              <td className="text-center align-middle">{thursdayTotal}</td>
              <td className="text-center align-middle">{fridayTotal}</td>
              <td className="text-center align-middle">{saturdayTotal}</td>
              <td className="text-center align-middle">{grandTotal}</td>
            </tr>
          </tfoot>
        </Table>
      );
      if (!isSubmitted) {
        modalFooter = (
          <Button
            flat
            type="submit"
            onClick={() => this.submitTimesheet(timesheetID)}
          >
            Submit for {this.state.firstName}
          </Button>
        );
      } else {
        modalFooter = (
          <Button flat modal="close">
            Close
          </Button>
        );
      }
      timesheetLink = (
        <Modal
          className="modal-fix admin-modal"
          header={
            this.state.name +
            ": " +
            moment(this.props.startOfWeek).format("MMMM Do YYYY") +
            " - " +
            moment(this.props.endOfWeek).format("MMMM Do YYYY")
          }
          trigger={
            <Button flat>
              <i className="fas fa-external-link-alt" />
            </Button>
          }
          actions={modalFooter}
        >
          <div className="divider" />
          <div className="modalPadding">
            <div>{userTimesheetContent}</div>
          </div>
        </Modal>
      );
    } else {
      timesheetLink = "";
    }
    return (
      <tr>
        <td className="center-align">
          <Checkbox
            filledIn
            checked={timesheetID && this.state.isChecked}
            className="blue-text center-align"
            label=""
            value={`${this.props.id}`}
            key={
              `${this.props.id}` +
              `${this.props.startOfWeek}` +
              `${this.props.endOfWeek}` +
              `${this.state.isChecked}`
            }
            onChange={() => this.onSelectRow(this.props.id)}
            disabled={!timesheetID}
          />
        </td>
        <td className="text-left align-middle">{this.state.name}</td>
        <td className="text-left align-middle">
          {timesheetID && isSubmitted
            ? "Submitted"
            : timesheetID
            ? "In Progress"
            : "Not Started"}
        </td>
        <td className="text-left align-middle">
          {lastUpdated ? moment(lastUpdated).fromNow() : "-"}
        </td>
        <td className="text-right align-middle">
          <span className="hide showOnHover">
            {timesheetLink}
            <Link
              to={{
                pathname: "/myteam/userdetail",
                state: {
                  id: this.state.id,
                  startDate: this.props.startOfWeek,
                  endDate: this.props.endOfWeek,
                  firstName: this.props.firstName,
                  lastName: this.props.lastName,
                  supervisor_id: this.props.supervisor_id,
                  directReport: this.props.directReport,
                  email: this.props.email,
                  dropdownDates: this.props.dropdownDates,
                  timesheet: userTimesheet[0],
                  goals: this.state.goals,
                  projects: this.state.projects
                }
              }}
              className="btn-flat"
            >
              <i className="fas fa-chart-pie" />
            </Link>
          </span>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = state => ({
  supervisor: state.supervisor,
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {
    getUserTimesheet,
    clearUserTimesheets,
    submitTimesheet,
    getDirectReportGoals
  }
)(UserTimesheetRow);
