import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Login from './Login';
import Register from './Register';
import AzureRedirect from './AzureRedirect';
import { Card, Row, Col } from 'react-materialize';
import classnames from 'classnames';
import LoginImage from '../../img/AeonLogin.png';
import E2iLogo from '../../img/e2iLogo.svg';

class AuthContainer extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/timesheet');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/timesheet');
    }

    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    let displayedSetting;
    switch (this.props.match.params.auth) {
      case 'login':
        displayedSetting = <Login />;
        break;
      case 'register':
        displayedSetting = <Register />;
        break;
      case 'azure-redirect':
        displayedSetting = <AzureRedirect />;
        break;
      default:
        break;
    }
    return (
      <Fragment>
        <img className='bannerBackground' src={LoginImage} alt='Aeon Banner' />
        <Row className='authMargin'>
          <Col s={8} m={4} offset={'m4 s2'}>
            <Card>
              <div style={{ fontSize: '20px', lineHeight: '24px' }}>
                Welcome to <span style={{ color: '#1897EC' }}>Aeon</span>
                <div style={{ width: '72px', float: 'right' }}>
                  <img src={E2iLogo} alt='E2i Logo'/>
                </div>
                <div style={{ width: '123px', marginTop: '20px' }}>
                  <div
                    className={classnames('loginLine', {
                      'is-active': this.props.match.params.auth === 'login',
                    })}
                  />
                  <div
                    className={classnames('registerLine', {
                      'is-active': this.props.match.params.auth === 'register',
                    })}
                  />
                </div>
                {displayedSetting}
              </div>
            </Card>
          </Col>
          <Col s={2} m={4} />
        </Row>
      </Fragment>
    );
  }
}

AuthContainer.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(AuthContainer);
