import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setNewPassword } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import { Button, Row, Col, Card } from "react-materialize";
import LoginImage from "../../img/AeonLogin.png";

class ResetForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      password2: "",
      hash: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/timesheet");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const newPassword = {
      hash: this.props.match.params.hash,
      password: this.state.password,
      password2: this.state.password2
    };

    this.props.setNewPassword(newPassword, this.props.history);
  }

  render() {
    const { errors } = this.state;

    return (
      <Fragment>
        <img className="bannerBackground" src={LoginImage} alt="Aeon Banner" />
        <Row className="authMargin">
          <Col s={8} m={4} offset={"m4 s2"}>
            <Card>
              <div className="forgot-password-header">
                Reset your forgotten password.
              </div>
              <p className="forgot-password-description">
                Let's make it something you'll remember this time.
              </p>
              <form onSubmit={this.onSubmit} noValidate={true}>
                <div className="mb-3">
                  <TextFieldGroup
                    placeholder="New Password"
                    name="password"
                    type="password"
                    value={this.state.password}
                    onChange={this.onChange}
                    error={errors.password}
                    additionalCSS="form-control-forgot-password"
                  />
                </div>
                <div className="mb-3">
                  <TextFieldGroup
                    placeholder="Confirm New Password"
                    name="password2"
                    type="password"
                    value={this.state.password2}
                    onChange={this.onChange}
                    error={errors.password2}
                    additionalCSS="form-control-forgot-password"
                  />
                </div>
                <div className="text-center">
                  <Button className="blue-text white">Submit</Button>
                </div>
              </form>
            </Card>
          </Col>
          <Col s={2} m={4} />
        </Row>
      </Fragment>
    );
  }
}

ResetForgotPassword.propTypes = {
  setNewPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { setNewPassword }
)(withRouter(ResetForgotPassword));
