import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { connect } from "react-redux";
import {
  getTimesheet,
  getCurrentTimesheet,
  getAvailableTimesheets,
  clearTimesheet
} from "../../actions/timesheetActions";
import Spinner from "../common/Spinner";
import isEmpty from "../../validation/is-empty";
import { Card } from "react-materialize";
import TimesheetTable from "./TimesheetTable";

const today = new Date(moment());

class Timesheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timesheet: null,
      currentDate: today,
      Sunday: moment(today).add(-1 * today.getDay(), "day"),
      Saturday: moment(today).add(-1 * today.getDay() + 6, "day"),
      oldestTimesheet: moment(today).add(-1 * today.getDay() - 21, "day"),
      newestTimesheet: moment(today).add(-1 * today.getDay() + 6, "day"),
      disabled: false
    };

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.dateButtonFunc = this.dateButtonFunc.bind(this);
  }

  componentDidMount() {
    if (this.props.timesheet.timesheet === null) {
      let formattedTimezone = moment.tz.guess().replace("/", "|");
      this.props.getCurrentTimesheet(
        this.props.auth.user.id,
        formattedTimezone
      );
    } else {
      this.props.getTimesheet(this.props.timesheet.timesheet._id);
    }
    window.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentWillUnmount() {
    window.removeEventListener("visibilitychange", this.handleVisibilityChange);
    this.props.clearTimesheet();
  }

  handleVisibilityChange() {
    if (document.visibilityState === "hidden") {
      this.setState({ visible: false });
    } else {
      if (this.state.visible === false) {
        let formattedTimezone = moment.tz.guess().replace("/", "|");
        this.setState({ visible: true });
        this.props.getCurrentTimesheet(
          this.props.auth.user.id,
          formattedTimezone
        );
        this.props.getAvailableTimesheets(
          this.props.auth.user.id,
          formattedTimezone
        );
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.timesheet.timesheet !== prevProps.timesheet.timesheet) {
      this.setState({
        timesheet: this.props.timesheet.timesheet,
        Sunday: moment(this.props.timesheet.timesheet.startDate),
        Saturday: moment(this.props.timesheet.timesheet.endDate)
      });
      let formattedTimezone = moment.tz.guess().replace("/", "|");
      this.props.getAvailableTimesheets(
        this.props.auth.user.id,
        formattedTimezone
      );
    }
    if (!isEmpty(this.props.errors) && !this.state.disabled) {
      this.setState({ disabled: true });
    }
    if (isEmpty(this.props.errors) && this.state.disabled) {
      this.setState({ disabled: false });
    }
  }

  dateButtonFunc = (command, sunday, saturday) => {
    let stop = false,
      newSunday,
      newSaturday;
    if (command === "next") {
      newSunday = moment(sunday).add(7, "day");
      newSaturday = moment(saturday).add(7, "day");
      this.props.timesheet.timesheets.forEach(timesheet => {
        if (
          moment(timesheet.startDate).format() === newSunday.format() &&
          moment(timesheet.endDate).format() === newSaturday.format()
        ) {
          stop = true;
          this.props.getTimesheet(timesheet._id);
        }
      });
      if (newSaturday < this.state.newestTimesheet && !stop) {
        sunday = newSunday;
        saturday = newSaturday;
        this.dateButtonFunc(command, sunday, saturday);
      }
    } else if (command === "previous") {
      newSunday = moment(sunday).add(-7, "day");
      newSaturday = moment(saturday).add(-7, "day");
      this.props.timesheet.timesheets.forEach(timesheet => {
        if (
          moment(timesheet.startDate).format() === newSunday.format() &&
          moment(timesheet.endDate).format() === newSaturday.format()
        ) {
          stop = true;
          this.props.getTimesheet(timesheet._id);
        }
      });
      if (newSunday > this.state.oldestTimesheet && !stop) {
        saturday = newSaturday;
        sunday = newSunday;
        this.dateButtonFunc(command, sunday, saturday);
      }
    }
  };

  render() {
    const { timesheetLoading } = this.props.timesheet;
    let timesheetContent;
    if (this.state.timesheet === null || timesheetLoading) {
      timesheetContent = <Spinner />;
    } else {
      timesheetContent = (
        <div className="timesheet-container">
          <TimesheetTable
            selectedTimesheet={this.state.timesheet}
            dateButtonFunc={this.dateButtonFunc}
          />
        </div>
      );
    }
    return (
      <div>
        <Card className="cardSmallBar">My Timesheet</Card>
        {timesheetContent}
      </div>
    );
  }
}

TimesheetTable.propTypes = {
  getCurrentTimesheet: PropTypes.func,
  timesheet: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  timesheet: state.timesheet
});

export default connect(
  mapStateToProps,
  {
    getTimesheet,
    getCurrentTimesheet,
    getAvailableTimesheets,
    clearTimesheet
  }
)(Timesheet);
