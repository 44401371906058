import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { submitTimesheet } from "../../actions/timesheetActions";
import ReviewTimesheetRow from ".//ReviewTimesheetRow";
import sortRowNumber from "../common/sortRowNumber";
import { Table, CardPanel, Button } from "react-materialize";

class ReviewTimesheetTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: props.timesheet._id,
      sunday: props.timesheet.startDate,
      saturday: props.timesheet.endDate,
      sundayTotal: props.timesheet.sundayTotal,
      mondayTotal: props.timesheet.mondayTotal,
      tuesdayTotal: props.timesheet.tuesdayTotal,
      wednesdayTotal: props.timesheet.wednesdayTotal,
      thursdayTotal: props.timesheet.thursdayTotal,
      fridayTotal: props.timesheet.fridayTotal,
      saturdayTotal: props.timesheet.saturdayTotal,
      grandTotal: props.timesheet.grandTotal,
      timesheetRows: props.timesheet.timesheetRows,
      submitCheckBox: false
    };

    this.submitCheck = this.submitCheck.bind(this);
    this.submitButtonAction = this.submitButtonAction.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => {
      return {
        timesheetRows: prevState.timesheetRows.sort(sortRowNumber)
      };
    });
  }

  submitCheck(e) {
    this.setState({ submitCheckBox: e.target.checked });
  }

  submitButtonAction() {
    let timesheetRowsNoTotal = this.state.timesheetRows;
    for (let i = 0; i < this.state.timesheetRows.length; i++) {
      timesheetRowsNoTotal[i].rowNumber = i;
      delete timesheetRowsNoTotal[i].total;
    }
    const payload = {
      id: this.state._id,
      submittedDate: moment(),
      timesheetRows: timesheetRowsNoTotal
    };
    this.props.submitTimesheet(payload);
    this.props.history.push(`/reviewConfirmation/${this.state._id}`);
  }

  render() {
    let reviewTimesheetContent, reviewTimesheetMain;
    if (this.state.timesheetRows === null || !this.state.timesheetRows) {
      reviewTimesheetMain = (
        <div>
          <h1>You have no timesheet!!!</h1>
        </div>
      );
    } else {
      reviewTimesheetContent = this.state.timesheetRows.map(timesheetRow => (
        <ReviewTimesheetRow
          key={timesheetRow.rowNumber}
          timesheetRow={timesheetRow}
        />
      ));
      reviewTimesheetMain = (
        <Fragment>
          <div
            style={{
              fontSize: "16px",
              lineHeight: "24px",
              marginBottom: "12px"
            }}
          >
            <span style={{ fontWeight: "bold" }}>Viewing Week:</span>
            <span>
              {" "}
              {moment(this.state.sunday)
                .tz("America/Chicago")
                .format("MMM D") +
                " - " +
                moment(this.state.saturday)
                  .tz("America/Chicago")
                  .format("MMM D")}
            </span>
          </div>
          <CardPanel className="tableHeaderBar">
            <div style={{ fontSize: "16px", height: "36px" }}>
              <Button
                name="submitButton"
                className="right white blue-text"
                onClick={this.submitButtonAction}
              >
                Submit
              </Button>
            </div>
          </CardPanel>
          <Table hoverable className="white" width="100%">
            <thead>
              <tr>
                <th className="align-middle text-left pl-4" width="25%">
                  Project
                </th>
                <th className="align-middle text-left" width="10%">
                  Task
                </th>
                <th
                  className="align-middle text-left form-control-description"
                  width="20%"
                >
                  Description
                </th>
                <th className="align-middle text-center" width="5%">
                  Su
                </th>
                <th className="align-middle text-center" width="5%">
                  Mo
                </th>
                <th className="align-middle text-center" width="5%">
                  Tu
                </th>
                <th className="align-middle text-center" width="5%">
                  We
                </th>
                <th className="align-middle text-center" width="5%">
                  Th
                </th>
                <th className="align-middle text-center" width="5%">
                  Fr
                </th>
                <th className="align-middle text-center" width="5%">
                  Sa
                </th>
                <th className="align-middle text-center" width="5%">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>{reviewTimesheetContent}</tbody>
            <tfoot>
              <tr style={{ height: "30px" }}>
                <td />
                <td />
                <td className="right-align">Daily Total</td>
                <td className="center-align">{this.state.sundayTotal}</td>
                <td className="center-align">{this.state.mondayTotal}</td>
                <td className="center-align">{this.state.tuesdayTotal}</td>
                <td className="center-align">{this.state.wednesdayTotal}</td>
                <td className="center-align">{this.state.thursdayTotal}</td>
                <td className="center-align">{this.state.fridayTotal}</td>
                <td className="center-align">{this.state.saturdayTotal}</td>
                <td className="center-align">{this.state.grandTotal}</td>
              </tr>
            </tfoot>
          </Table>
        </Fragment>
      );
    }
    return <div className="container-fluid">{reviewTimesheetMain}</div>;
  }
}

ReviewTimesheetTable.propTypes = {
  timesheetRows: PropTypes.object,
  submitTimesheet: PropTypes.func.isRequired,
  router: PropTypes.object
};

const mapStateToProps = state => ({
  timesheetRow: state.timesheetRow,
  project: state.project,
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { submitTimesheet }
)(withRouter(ReviewTimesheetTable));
