import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../common/Spinner';
import { editProject } from '../../../actions/adminActions';
import DropDownGroup from '../../common/DropDownGroup';
import TextFieldGroup from '../../common/TextFieldGroup';
import { getAllProjects, clearProjects } from '../../../actions/adminActions';
import ProjectRow from './ProjectRow';
import {
  Button,
  Modal,
  Icon,
  Switch,
  Row,
  Col,
  Table,
  CardPanel,
  Card,
} from 'react-materialize';

class ManageProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      name: '',
      fullName: '',
      tasks: [],
      status: true,
      isBillable: true,
      accruesPTO: true,
      projectSearch: '',
      taskSearch: '',
      statusSearch: true,
    };

    this.onChange = this.onChange.bind(this);
    this.onTaskChange = this.onTaskChange.bind(this);
    this.addProjectSave = this.addProjectSave.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.isAdmin) {
        this.props.getAllProjects();
      } else {
        this.props.history.push('/timesheet');
      }
    }
  }

  componentWillUnmount() {
    this.props.clearProjects();
  }

  onStatusSelect = () => {
    this.setState((prevState) => {
      return { status: !prevState.status };
    });
  };

  onBillableSelect = () => {
    this.setState((prevState) => {
      return { isBillable: !prevState.isBillable };
    });
  };

  onStatusSearchSelect = (e) => {
    this.setState({ statusSearch: e });
  };

  onPTOSelect = (e) => {
    this.setState({ accruesPTO: e });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onTaskChange(e) {
    let savedTasks = [];
    this.state.tasks.forEach((task) => {
      if (task._id.toString() === e.target.name.toString()) {
        task.name = e.target.value;
      }
      savedTasks.push(task);
    });
    this.setState({ tasks: savedTasks });
  }

  addTasks = (e) => {
    const newTask = {
      _id: this.state.tasks.length,
      name: '',
      totalHours: 0,
      isActive: true,
    };
    this.setState((prevState) => {
      prevState.tasks.push(newTask);
      return {
        tasks: prevState.tasks,
      };
    });
  };

  handleOptionChange = (e) => {
    let taskIndex;
    this.setState((prevState) => {
      taskIndex = this.state.tasks.findIndex((task) => task._id === e);
      prevState.tasks[taskIndex].isActive =
        !prevState.tasks[taskIndex].isActive;
      return {
        tasks: prevState.tasks,
      };
    });
  };

  addProjectSave() {
    let savedTasks = [];
    this.state.tasks.forEach((task) => {
      if (Number.isInteger(task._id)) {
        delete task._id;
      }
      savedTasks.push(task);
    });

    const projectData = {
      id: this.state.id,
      name: this.state.name,
      fullName: this.state.fullName,
      tasks: savedTasks,
      isActive: this.state.status,
      isBillable: this.state.isBillable,
      accruesPTO: this.state.accruesPTO,
    };

    this.props.editProject(projectData);

    window.location.reload();
  }

  render() {
    const { adminProjects, adminProjectsLoading } = this.props.admin;
    let ManageProjectTableContent,
      ManageProjectTable,
      displayTasks = [];
    if (adminProjects === null || adminProjectsLoading) {
      ManageProjectTable = <Spinner />;
    } else {
      let adminProjectsList = adminProjects;
      adminProjectsList = adminProjectsList.filter((project) =>
        project.fullName
          .toLowerCase()
          .includes(this.state.projectSearch.toLowerCase())
      );
      adminProjectsList = adminProjectsList.filter((project) =>
        project.tasks
          .map((e) => e.name)
          .join(',')
          .toLowerCase()
          .includes(this.state.taskSearch.toLowerCase())
      );
      adminProjectsList = adminProjectsList.filter(
        (project) =>
          project.isActive === this.state.statusSearch ||
          this.state.statusSearch === null
      );
      ManageProjectTableContent = adminProjectsList.map((projectRow) => (
        <ProjectRow
          key={projectRow._id}
          id={projectRow._id}
          name={projectRow.name}
          fullName={projectRow.fullName}
          tasks={projectRow.tasks}
          isActive={projectRow.isActive}
          isBillable={projectRow.isBillable}
          accruesPTO={projectRow.accruesPTO}
        />
      ));

      ManageProjectTable = (
        <Table hoverable className='white adminTable'>
          <thead>
            <tr>
              <th className='text-left px-4'>Project</th>
              <th className='text-left px-4'>Tasks</th>
              <th className='text-left px-4' width='15%'>
                Status
              </th>
              <th className='text-left' width='10%' />
            </tr>
          </thead>
          <tbody>
            <tr className='searchRow'>
              <td className='px-3'>
                <TextFieldGroup
                  placeholder='Project Search...'
                  name='projectSearch'
                  type='text'
                  value={this.state.projectSearch}
                  onChange={this.onChange}
                  size=''
                  margin='my-auto'
                />
              </td>
              <td className='px-3'>
                <TextFieldGroup
                  placeholder='Task Search...'
                  name='taskSearch'
                  type='text'
                  value={this.state.taskSearch}
                  onChange={this.onChange}
                  size=''
                  margin='my-auto'
                />
              </td>
              <td className='px-3'>
                <DropDownGroup
                  selectedOption={this.state.statusSearch}
                  onSelect={this.onStatusSearchSelect}
                  options={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false },
                  ]}
                  clearable={true}
                  searchable={true}
                />
              </td>
              <td />
            </tr>
            {ManageProjectTableContent}
          </tbody>
        </Table>
      );

      this.state.tasks.forEach((task, index) => {
        displayTasks.push(
          <div key={this.state.id + task._id}>
            <Row>
              <Col s={6}>
                <TextFieldGroup
                  placeholder='Task'
                  name={task._id.toString()}
                  type='text'
                  value={this.state.tasks[index].name}
                  onChange={this.onTaskChange}
                  size=''
                />
              </Col>
              <Col s={6}>
                <Switch
                  id={'Task - ' + task._id}
                  checked={task.isActive}
                  onChange={() => this.handleOptionChange(task._id)}
                  onLabel='Active'
                  offLabel='Inactive'
                />
              </Col>
            </Row>
          </div>
        );
      });
    }
    return (
      <Fragment>
        <Card className='cardSmallBar'>Projects</Card>
        <div className='admin-container'>
          <Modal
            className='modal-fix admin-modal'
            header='New Project'
            trigger={
              <Button
                floating
                large
                icon={<Icon>add</Icon>}
                className='blue adminAddButton'
              />
            }
            actions={
              <Fragment>
                <Button flat modal='close'>
                  Close
                </Button>
                <Button
                  flat
                  className='blue-text'
                  type='submit'
                  onClick={this.addProjectSave}
                  data-dismiss='modal'
                >
                  Save
                </Button>
              </Fragment>
            }
          >
            <div className='divider' />
            <div className='modalPadding'>
              <Row>
                <Col s={6}>
                  <h5 className='text-left'>Full Name</h5>
                  <TextFieldGroup
                    placeholder='Project Full Name'
                    name='fullName'
                    type='text'
                    value={this.state.fullName}
                    onChange={this.onChange}
                    size=''
                  />
                  <h5 className='text-left'>Display Name</h5>
                  <TextFieldGroup
                    placeholder='Display Name'
                    name='name'
                    type='text'
                    value={this.state.name}
                    onChange={this.onChange}
                    size=''
                  />
                  <h5 className='text-left'>Status</h5>
                  <div className='text-left mb-3'>
                    <Switch
                      id={'Status - New'}
                      checked={this.state.status}
                      onChange={this.onStatusSelect}
                      onLabel='Active'
                      offLabel='Inactive'
                    />
                  </div>
                  <h5 className='text-left'>Billable Work</h5>
                  <div className='text-left mb-3'>
                    <Switch
                      id={'Billable - New'}
                      checked={this.state.isBillable}
                      onChange={this.onBillableSelect}
                      onLabel='Billable'
                      offLabel='Non-Billable'
                    />
                  </div>
                </Col>
                <Col s={6}>
                  <Row className='mb-0'>
                    <div className='valign-wrapper'>
                      <h5 className='text-left'>Tasks</h5>
                      <Button
                        small
                        floating
                        icon={<Icon>add</Icon>}
                        onClick={this.addTasks}
                        className='ml-2 blue'
                      />
                    </div>
                  </Row>
                  <div className='text-left'>{displayTasks}</div>
                </Col>
              </Row>
            </div>
          </Modal>
          <Card className='tableCard'>
            <CardPanel
              className='adminHeaderBar'
              style={{ borderBottom: '1px solid #E1E2E1' }}
            >
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '41px',
                  fontWeight: 501,
                }}
              >
                Projects
              </div>
            </CardPanel>
            {ManageProjectTable}
          </Card>
        </div>
      </Fragment>
    );
  }
}

ManageProjects.propTypes = {
  getAllProjects: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getAllProjects,
  editProject,
  clearProjects,
})(ManageProjects);
