import axios from "axios";
import {
  GET_TIMESHEET_TEMPLATE,
  GET_ERRORS,
  GET_SUCCESSES,
  HAS_SEEN_RELEASE,
  CLEAR_HAS_SEEN_RELEASE
} from "./types";

export const getTimesheetTemplate = email => dispatch => {
  axios
    .get(`/api/users/getTimesheetTemplate/${email}`)
    .then(res =>
      dispatch({
        type: GET_TIMESHEET_TEMPLATE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_TIMESHEET_TEMPLATE,
        payload: null
      })
    );
};

export const saveTimesheetTemplate = timesheetTemplate => dispatch => {
  axios
    .post("/api/users/saveTimesheetTemplate/", timesheetTemplate)
    .then(res =>
      dispatch({
        type: GET_SUCCESSES,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

export const getHasSeenRelease = user_id => dispatch => {
  axios
    .get(`/api/users/getHasSeenRelease/${user_id}`)
    .then(res =>
      dispatch({
        type: HAS_SEEN_RELEASE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: HAS_SEEN_RELEASE,
        payload: null
      })
    );
};

export const updateHasSeenRelease = user_id => dispatch => {
  axios
    .post("/api/users/updateHasSeenRelease", user_id)
    .then(res =>
      dispatch({
        type: HAS_SEEN_RELEASE,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: HAS_SEEN_RELEASE,
        payload: null
      })
    );
};

// Clear Auth Has Seen Release
export const clearHasSeenRelease = () => {
  return {
    type: CLEAR_HAS_SEEN_RELEASE
  };
};
