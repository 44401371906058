import axios from 'axios';

import {
  GET_SUPERVISOR_USERS,
  GET_SUPERVISOR_DATES,
  GET_SUPERVISOR_USER_TIMESHEET,
  GET_SUPERVISOR_TEAM_GOALS,
  GET_DIRECT_REPORT_GOALS,
  GET_SUPERVISOR_USER_DETAIL_TIMESHEET,
  GET_SUPERVISOR_REPORTING_RESULTS,
  CREATE_TEAM_GOAL,
  CREATE_DIRECT_REPORT_GOAL,
  UPDATE_SUPERVISOR_TEAM_GOAL,
  UPDATE_DIRECT_REPORT_GOAL,
  CLEAR_SUPERVISOR_USERS,
  CLEAR_USER_TIMESHEETS,
  CLEAR_USER_DETAIL_TIMESHEET,
  CLEAR_SUPERVISOR_TEAM_GOALS,
  DELETE_SUPERVISOR_TEAM_GOAL,
  DELETE_DIRECT_REPORT_GOAL,
  LOADING,
  GET_ERRORS,
} from './types';

// Get Supervisees
export const getSupervisees = (startDate, endDate) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/supervisor/getSupervisees/${startDate}/${endDate}`)
    .then((res) =>
      dispatch({
        type: GET_SUPERVISOR_USERS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_SUPERVISOR_USERS,
        payload: null,
      })
    );
};

// Get Timesheet Dates
export const getTimesheetDates = () => (dispatch) => {
  dispatch(setLoading());
  axios
    .get('/api/supervisor/getTimesheetDates')
    .then((res) =>
      dispatch({
        type: GET_SUPERVISOR_DATES,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_SUPERVISOR_DATES,
        payload: null,
      })
    );
};

// Get User Timesheet
export const getUserTimesheet = (user, startDate, endDate) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/supervisor/getUserTimesheet/${user}/${startDate}/${endDate}`)
    .then((res) =>
      dispatch({
        type: GET_SUPERVISOR_USER_TIMESHEET,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_SUPERVISOR_USER_TIMESHEET,
        payload: null,
      })
    );
};

// Create Team Goal
export const createTeamGoal = (goal) => (dispatch) => {
  axios.post('/api/goals/createGoal', goal).then((res) =>
    dispatch({
      type: CREATE_TEAM_GOAL,
      payload: null,
    })
  );
};

// Create Direct Report Goal
export const createDirectReportGoal = (goal) => (dispatch) => {
  axios
    .post('/api/goals/createGoal', goal)
    .then((res) =>
      dispatch({
        type: CREATE_DIRECT_REPORT_GOAL,
        payload: res.data,
      })
    )
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Delete Team Goal
export const deleteTeamGoal = (goal_id) => (dispatch) => {
  axios
    .post('/api/goals/deleteGoal', goal_id)
    .then((res) => {
      dispatch({ type: DELETE_SUPERVISOR_TEAM_GOAL, payload: null });
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Delete Direct Report Goal
export const deleteDirectReportGoal = (goal_id) => (dispatch) => {
  axios
    .post('/api/goals/deleteGoal', goal_id)
    .then((res) => {
      dispatch({ type: DELETE_DIRECT_REPORT_GOAL, payload: goal_id });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Team Goal
export const updateTeamGoal = (goal) => (dispatch) => {
  axios
    .post('/api/goals/updateGoal', goal)
    .then((res) => {
      dispatch({ type: UPDATE_SUPERVISOR_TEAM_GOAL, payload: res.data });
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Update Direct Report Goal
export const updateDirectReportGoal = (goal) => (dispatch) => {
  axios
    .post('/api/goals/updateGoal', goal)
    .then((res) =>
      dispatch({ type: UPDATE_DIRECT_REPORT_GOAL, payload: res.data })
    )
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};

// Get Team Goals
export const getTeamGoals = (startDate, endDate) => (dispatch) => {
  dispatch(setLoading());
  axios
    .get(`/api/goals/getTeamGoals/${startDate}/${endDate}`)
    .then((res) =>
      dispatch({
        type: GET_SUPERVISOR_TEAM_GOALS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({ type: GET_SUPERVISOR_TEAM_GOALS, payload: null })
    );
};

// Get Direct Report Goals
export const getDirectReportGoals =
  (user_id, supervisor_id, startDate, endDate) => (dispatch) => {
    dispatch(setLoading());
    axios
      .get(
        `/api/goals/getDirectReportGoals/${user_id}/${supervisor_id}/${startDate}/${endDate}`
      )
      .then((res) =>
        dispatch({
          type: GET_DIRECT_REPORT_GOALS,
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_SUPERVISOR_TEAM_GOALS,
          payload: null,
        })
      );
  };

// Get User Detail Timesheet
export const getUserDetailTimesheet =
  (user, startDate, endDate) => (dispatch) => {
    axios
      .get(`/api/supervisor/getUserTimesheet/${user}/${startDate}/${endDate}`)
      .then((res) =>
        dispatch({
          type: GET_SUPERVISOR_USER_DETAIL_TIMESHEET,
          payload: res.data,
        })
      )
      .catch((err) =>
        dispatch({
          type: GET_SUPERVISOR_USER_DETAIL_TIMESHEET,
          payload: null,
        })
      );
  };

// Get reporting results
export const getReportingResults = (payload) => (dispatch) => {
  axios
    .post(`/api/supervisor/filterResults`, payload)
    .then((res) =>
      dispatch({
        type: GET_SUPERVISOR_REPORTING_RESULTS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_SUPERVISOR_REPORTING_RESULTS,
        payload: null,
      })
    );
};

// Clear Users
export const clearUsers = () => {
  return {
    type: CLEAR_SUPERVISOR_USERS,
  };
};

// Clear User Timesheets
export const clearUserTimesheets = () => {
  return {
    type: CLEAR_USER_TIMESHEETS,
  };
};

// Clear User Detail Timesheet
export const clearUserDetailTimesheet = () => {
  return {
    type: CLEAR_USER_DETAIL_TIMESHEET,
  };
};

// Clear Supervisor Team Goals
export const clearTeamGoals = () => {
  return {
    type: CLEAR_SUPERVISOR_TEAM_GOALS,
  };
};

// submit a timesheet
export const submitTimesheet = (id) => (dispatch) => {
  axios
    .post('/api/supervisor/submitTimesheet', id)
    .then((res) => {})
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Loading Spinner
export const setLoading = () => {
  return {
    type: LOADING,
  };
};
