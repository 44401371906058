import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { forgotPassword } from "../../actions/authActions";
import { connect } from "react-redux";
import TextFieldGroup from "../common/TextFieldGroup";
import { Button, Row, Col, Card } from "react-materialize";
import LoginImage from "../../img/AeonLogin.png";

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      errors: {},
      successes: {}
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/timesheet");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.successes) {
      this.setState({ successes: nextProps.successes });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    const userReset = {
      email: this.state.email
    };

    this.props.forgotPassword(userReset, this.props.history);
  }

  render() {
    const { errors } = this.state;
    const { successes } = this.state;

    return (
      <Fragment>
        <img className="bannerBackground" src={LoginImage} alt="Aeon Banner" />
        <Row className="authMargin">
          <Col s={8} m={4} offset={"m4 s2"}>
            <Card>
              <div className="forgot-password-header">Reset Password</div>
              <p className="forgot-password-description">
                Enter the email address associated with your account.
              </p>
              <form onSubmit={this.onSubmit} noValidate={true}>
                <TextFieldGroup
                  placeholder="Email Address"
                  name="email"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={errors.email}
                  success={successes.email}
                  additionalCSS="form-control-forgot-password"
                />
                <div className="text-center mt-3">
                  <Button className="btn white blue-text">Reset</Button>
                  <Link className="btn-flat" to="/login">
                    Login
                  </Link>
                </div>
              </form>
            </Card>
          </Col>
          <Col s={2} m={4} />
        </Row>
      </Fragment>
    );
  }
}

ForgotPassword.propTypes = {
  forgotPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  successes: state.successes
});

export default connect(
  mapStateToProps,
  { forgotPassword }
)(ForgotPassword);
