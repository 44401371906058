import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DropDownGroup from "../common/DropDownGroup";
import NumberFieldGroup from "../common/NumberFieldGroup";
import TextFieldGroup from "../common/TextFieldGroup";
import isEmpty from "../../validation/is-empty";
import isNumber from "../../validation/is-number";
import taskNotEmpty from "../../validation/task-not-empty";
import projectNotEmpty from "../../validation/project-not-empty";
import flattenDeep from "../common/flattenDeep";
import { Checkbox } from "react-materialize";

class TimesheetTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: props._id,
      rowNumber: props.rowNumber,
      timesheet_isSubmitted: props.isSubmitted,
      project_id: props.timesheetRow.project_id,
      tasks: [],
      task_id: props.timesheetRow.task_id,
      description:
        props.timesheetRow.description !== null
          ? props.timesheetRow.description
          : "",
      sunday: props.timesheetRow.sunday !== 0 ? props.timesheetRow.sunday : "",
      monday: props.timesheetRow.monday !== 0 ? props.timesheetRow.monday : "",
      tuesday:
        props.timesheetRow.tuesday !== 0 ? props.timesheetRow.tuesday : "",
      wednesday:
        props.timesheetRow.wednesday !== 0 ? props.timesheetRow.wednesday : "",
      thursday:
        props.timesheetRow.thursday !== 0 ? props.timesheetRow.thursday : "",
      friday: props.timesheetRow.friday !== 0 ? props.timesheetRow.friday : "",
      saturday:
        props.timesheetRow.saturday !== 0 ? props.timesheetRow.saturday : "",
      total: props.timesheetRow.total ? props.timesheetRow.total : 0,
      disableEntry: false,
      isChecked: false,
      //Fix for duplicate "added rows"
      //allowSave: true,
      errors: {
        [props.rowNumber]: {
          project_id: null,
          task_id: null,
          sunday: null,
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null
        }
      }
    };

    this.onBlurSave = this.onBlurSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onProjectSelect = this.onProjectSelect.bind(this);
  }

  componentDidMount() {
    let taskArray = [];
    if (this.props.timesheetRow.project_id) {
      this.props.projectList.forEach(project => {
        if (this.props.timesheetRow.project_id.toString() === project._id) {
          taskArray = project.tasks.map(task => {
            return [
              {
                label: task.name,
                value: task._id
              }
            ];
          });
        }
      });
    }
    this.setState({ tasks: taskArray }, () => {
      this.props.sendTasks(this.state.rowNumber, taskArray);
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.timesheetRow !== prevProps.timesheetRow) {
      let taskArray = [],
        total = 0;
      if (this.props.timesheetRow.project_id) {
        this.props.projectList.forEach(project => {
          if (this.props.timesheetRow.project_id.toString() === project._id) {
            taskArray = project.tasks.map(task => {
              return [
                {
                  label: task.name,
                  value: task._id
                }
              ];
            });
          }
        });
      }
      if (isNumber(this.props.timesheetRow.sunday))
        total += this.props.timesheetRow.sunday;
      if (isNumber(this.props.timesheetRow.monday))
        total += this.props.timesheetRow.monday;
      if (isNumber(this.props.timesheetRow.tuesday))
        total += this.props.timesheetRow.tuesday;
      if (isNumber(this.props.timesheetRow.wednesday))
        total += this.props.timesheetRow.wednesday;
      if (isNumber(this.props.timesheetRow.thursday))
        total += this.props.timesheetRow.thursday;
      if (isNumber(this.props.timesheetRow.friday))
        total += this.props.timesheetRow.friday;
      if (isNumber(this.props.timesheetRow.saturday))
        total += this.props.timesheetRow.saturday;
      this.setState(
        {
          rowNumber: this.props.rowNumber,
          project_id: this.props.timesheetRow.project_id,
          task_id: this.props.timesheetRow.task_id,
          description:
            this.props.timesheetRow.description !== null
              ? this.props.timesheetRow.description
              : "",
          sunday:
            this.props.timesheetRow.sunday !== 0
              ? this.props.timesheetRow.sunday
              : "",
          monday:
            this.props.timesheetRow.monday !== 0
              ? this.props.timesheetRow.monday
              : "",
          tuesday:
            this.props.timesheetRow.tuesday !== 0
              ? this.props.timesheetRow.tuesday
              : "",
          wednesday:
            this.props.timesheetRow.wednesday !== 0
              ? this.props.timesheetRow.wednesday
              : "",
          thursday:
            this.props.timesheetRow.thursday !== 0
              ? this.props.timesheetRow.thursday
              : "",
          friday:
            this.props.timesheetRow.friday !== 0
              ? this.props.timesheetRow.friday
              : "",
          saturday:
            this.props.timesheetRow.saturday !== 0
              ? this.props.timesheetRow.saturday
              : "",
          total: total,
          tasks: taskArray,
          isChecked: false
        },
        () => {
          this.props.sendTasks(this.state.rowNumber, this.state.tasks);
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.auth.hasSeenRelease === nextProps.auth.hasSeenRelease) {
      //Checking if there are errors in the current row and applying them.
      if (
        !isEmpty(nextProps.errors) &&
        nextProps.errors[this.state.rowNumber]
      ) {
        this.setState({
          errors: {
            [this.state.rowNumber]: {
              project_id: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].project_id
                : null,
              task_id: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].task_id
                : null,
              sunday: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].sunday
                : null,
              monday: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].monday
                : null,
              tuesday: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].tuesday
                : null,
              wednesday: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].wednesday
                : null,
              thursday: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].thursday
                : null,
              friday: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].friday
                : null,
              saturday: nextProps.errors[this.state.rowNumber]
                ? nextProps.errors[this.state.rowNumber].saturday
                : null
            }
          }
        });
      } else {
        this.setState({
          errors: {
            [nextProps.rowNumber]: {
              project_id: null,
              task_id: null,
              sunday: null,
              monday: null,
              tuesday: null,
              wednesday: null,
              thursday: null,
              friday: null,
              saturday: null
            }
          }
        });
      }

      //Checking if there are errors in other rows
      //If there are disable this row.
      //If there are not make sure row is enabled.
      if (
        !isEmpty(nextProps.errors) &&
        !nextProps.errors[this.state.rowNumber]
      ) {
        this.setState({ disableEntry: true });
      } else {
        this.setState({ disableEntry: false });
      }

      //Make sure row reflects if it's been submitted???
      this.setState({ timesheet_isSubmitted: nextProps.isSubmitted });
    }
  }

  onFocus(e) {
    if (e.target.value === "0") this.setState({ [e.target.name]: "" });
  }

  sendLastUpdated(e) {
    this.props.lastUpdated(e);
  }

  onBlurSave(e) {
    e.preventDefault();
    this.props.receiveOnBlur(e);
    if (e.target.name === "description") {
      this.props.projTaskDescFunc(
        undefined,
        undefined,
        e.target.value.toString(),
        this.state.rowNumber
      );
    } else {
      this.props.dailyTotalFunc(
        e.target.name,
        e.target.value !== "" ? parseFloat(e.target.value) : 0,
        this.state.rowNumber,
        projectNotEmpty(this.state.project_id),
        taskNotEmpty(this.state.task_id, this.state.tasks)
      );
    }
    this.setState({ isEditting: false });

    //Fix for duplicate "added rows"
    //    if (this.state.allowSave) {

    if (
      isNumber(this.state.sunday) &&
      isNumber(this.state.monday) &&
      isNumber(this.state.tuesday) &&
      isNumber(this.state.wednesday) &&
      isNumber(this.state.thursday) &&
      isNumber(this.state.friday) &&
      isNumber(this.state.saturday)
    ) {
      this.setState({
        total:
          (this.state.sunday !== "" ? this.state.sunday : 0) +
          (this.state.monday !== "" ? this.state.monday : 0) +
          (this.state.tuesday !== "" ? this.state.tuesday : 0) +
          (this.state.wednesday !== "" ? this.state.wednesday : 0) +
          (this.state.thursday !== "" ? this.state.thursday : 0) +
          (this.state.friday !== "" ? this.state.friday : 0) +
          (this.state.saturday !== "" ? this.state.saturday : 0)
      });
    }

    // Fix for duplicate "added rows"
    //this.setState({ allowSave: false });
    //}
  }

  onChange(e) {
    this.props.receiveOnChange(e);
    this.setState({ isEditting: true });
    if (e.target.name === "description") {
      this.setState({ [e.target.name]: e.target.value.toString() });
    } else {
      if (e.target.value) {
        this.setState({
          [e.target.name]: parseFloat(e.target.value)
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value
        });
      }
    }
  }

  onProjectSelect = e => {
    let taskArray = [];
    if (e) {
      this.props.projectList.forEach(project => {
        if (e.toString() === project._id) {
          taskArray = project.tasks.map(task => {
            return [
              {
                label: task.name,
                value: task._id
              }
            ];
          });
        }
      });
    }
    this.setState(
      {
        project_id: e,
        task_id:
          flattenDeep(taskArray).length === 1
            ? flattenDeep(taskArray)[0].value
            : null,
        tasks: taskArray
      },
      () => {
        this.props.sendTasks(this.state.rowNumber, taskArray);
        this.props.projTaskDescFunc(
          this.state.project_id,
          this.state.task_id,
          this.state.description,
          this.state.rowNumber
        );
      }
    );
  };

  onTaskSelect = e => {
    this.setState({ task_id: e }, () => {
      this.props.projTaskDescFunc(
        undefined,
        this.state.task_id,
        undefined,
        this.state.rowNumber
      );
    });
  };

  onDeleteRow = e => {
    this.props.processDeleteRequest(e);
    this.setState({ isChecked: true });
  };

  onKeyPress = e => {
    let value = 0,
      newValue;
    if (document.activeElement.value) {
      value = parseFloat(document.activeElement.value);
    }
    switch (e.key) {
      case "ArrowUp":
      case "ArrowDown":
      case "ArrowRight":
      case "ArrowLeft":
        e.preventDefault();
        break;
      case "-":
      case "_":
        e.preventDefault();
        newValue = value - parseFloat(document.activeElement.step);
        if (newValue < document.activeElement.min)
          newValue = parseFloat(document.activeElement.min);
        this.setState({
          [document.activeElement.name]: newValue
        });
        break;
      case "+":
      case "=":
        e.preventDefault();
        newValue = value + parseFloat(document.activeElement.step);
        if (newValue > document.activeElement.max)
          newValue = parseFloat(document.activeElement.max);
        this.setState({
          [document.activeElement.name]: newValue
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { projectList } = this.props;

    let projectArray = [];
    projectArray.push(
      projectList.map(project => {
        return [
          {
            label: project.name,
            value: project._id
          }
        ];
      })
    );
    const { errors } = this.state;
    return (
      <tr onBlur={this.onBlurSave}>
        <td className="center-align">
          <Checkbox
            filledIn
            checked={this.state.isChecked}
            className="blue-text center-align valign-wrapper"
            label=""
            value={`${this.props.timesheetRow.rowNumber}`}
            key={
              `${this.props.timesheetRow.rowNumber}` +
              `${this.props.timesheetRow._id}` +
              `${this.state.isChecked}`
            }
            onChange={() => this.onDeleteRow(this.props.timesheetRow.rowNumber)}
            disabled={
              this.state.timesheet_isSubmitted || this.state.disableEntry
            }
          />
        </td>
        <td className="pl-0">
          <DropDownGroup
            options={flattenDeep(projectArray)}
            onSelect={this.onProjectSelect}
            selectedOption={this.state.project_id}
            error={errors[this.state.rowNumber].project_id}
            disabled={
              this.state.timesheet_isSubmitted || this.state.disableEntry
            }
            size=""
            extraCSS="my-1"
          />
        </td>
        <td>
          <DropDownGroup
            options={flattenDeep(this.state.tasks)}
            onSelect={this.onTaskSelect}
            selectedOption={this.state.task_id}
            error={errors[this.state.rowNumber].task_id}
            disabled={
              this.state.timesheet_isSubmitted || this.state.disableEntry
            }
            size=""
            extraCSS="my-1"
          />
        </td>
        <td>
          <div className="my-1">
            <TextFieldGroup
              placeholder="Description"
              name="description"
              type="text"
              value={this.state.description}
              onChange={this.onChange}
              disabled={
                this.state.timesheet_isSubmitted || this.state.disableEntry
              }
            />
          </div>
        </td>
        <td style={{ padding: ".3rem" }}>
          <div className="my-1">
            <NumberFieldGroup
              name="sunday"
              type="number"
              step="0.25"
              min="0"
              max="24"
              value={this.state.sunday}
              onChange={this.onChange}
              onFocus={this.onFocus}
              size=""
              error={errors[this.state.rowNumber].sunday}
              disabled={
                this.state.timesheet_isSubmitted || this.state.disableEntry
              }
              onKeyPress={this.onKeyPress}
            />
          </div>
        </td>
        <td>
          <div className="my-1">
            <NumberFieldGroup
              name="monday"
              type="number"
              step="0.25"
              min="0"
              max="24"
              value={this.state.monday}
              onChange={this.onChange}
              onFocus={this.onFocus}
              size=""
              error={errors[this.state.rowNumber].monday}
              disabled={
                this.state.timesheet_isSubmitted || this.state.disableEntry
              }
              onKeyPress={this.onKeyPress}
            />
          </div>
        </td>
        <td>
          <div className="my-1">
            <NumberFieldGroup
              name="tuesday"
              type="number"
              step="0.25"
              min="0"
              max="24"
              value={this.state.tuesday}
              onChange={this.onChange}
              onFocus={this.onFocus}
              size=""
              error={errors[this.state.rowNumber].tuesday}
              disabled={
                this.state.timesheet_isSubmitted || this.state.disableEntry
              }
              onKeyPress={this.onKeyPress}
            />
          </div>
        </td>
        <td>
          <div className="my-1">
            <NumberFieldGroup
              name="wednesday"
              type="number"
              step="0.25"
              min="0"
              max="24"
              value={this.state.wednesday}
              onChange={this.onChange}
              onFocus={this.onFocus}
              size=""
              error={errors[this.state.rowNumber].wednesday}
              disabled={
                this.state.timesheet_isSubmitted || this.state.disableEntry
              }
              onKeyPress={this.onKeyPress}
            />
          </div>
        </td>
        <td>
          <div className="my-1">
            <NumberFieldGroup
              name="thursday"
              type="number"
              step="0.25"
              min="0"
              max="24"
              value={this.state.thursday}
              onChange={this.onChange}
              onFocus={this.onFocus}
              size=""
              error={errors[this.state.rowNumber].thursday}
              disabled={
                this.state.timesheet_isSubmitted || this.state.disableEntry
              }
              onKeyPress={this.onKeyPress}
            />
          </div>
        </td>
        <td>
          <div className="my-1">
            <NumberFieldGroup
              name="friday"
              type="number"
              step="0.25"
              min="0"
              max="24"
              value={this.state.friday}
              onChange={this.onChange}
              onFocus={this.onFocus}
              size=""
              error={errors[this.state.rowNumber].friday}
              disabled={
                this.state.timesheet_isSubmitted || this.state.disableEntry
              }
              onKeyPress={this.onKeyPress}
            />
          </div>
        </td>
        <td>
          <div className="my-1">
            <NumberFieldGroup
              name="saturday"
              type="number"
              step="0.25"
              min="0"
              max="24"
              value={this.state.saturday}
              onChange={this.onChange}
              onFocus={this.onFocus}
              size=""
              error={errors[this.state.rowNumber].saturday}
              disabled={
                this.state.timesheet_isSubmitted || this.state.disableEntry
              }
              onKeyPress={this.onKeyPress}
            />
          </div>
        </td>
        <td className="text-center align-middle">
          {parseFloat(this.state.total)}
        </td>
      </tr>
    );
  }
}

TimesheetTableRow.propTypes = {
  tasks: PropTypes.array,
  onBlur: PropTypes.func,
  onProjectSelect: PropTypes.func,
  onBlurSave: PropTypes.func,
  auth: PropTypes.object.isRequired,
  project_id: PropTypes.any,
  task_id: PropTypes.any,
  sunday: PropTypes.any,
  monday: PropTypes.any,
  tuesday: PropTypes.any,
  wednesday: PropTypes.any,
  thursday: PropTypes.any,
  friday: PropTypes.any,
  saturday: PropTypes.any,
  total: PropTypes.number,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  successes: state.successes
});

export default connect(
  mapStateToProps,
  {}
)(TimesheetTableRow);
