import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from '../../../validation/is-empty';
import DropDownGroup from '../../common/DropDownGroup';
import flattenDeep from '../../common/flattenDeep';
import { Checkbox } from 'react-materialize';
import TextFieldGroup from '../../common/TextFieldGroup';

class TimesheetTemplateRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      projects: [],
      project: props.selectedProject ? props.selectedProject : null,
      task: props.task ? props.task : null,
      description: props.description ? props.description : '',
      isChecked: !props.uncheck,
      errors: { [this.props.row]: {} },
    };
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.onDescriptionChange = this.onDescriptionChange.bind(this);
  }

  componentDidMount() {
    let taskArray = [];
    if (this.props.selectedProject) {
      this.props.project.projects.forEach((project) => {
        if (this.props.selectedProject.toString() === project._id) {
          taskArray.push(
            project.tasks.map((task) => {
              return [
                {
                  label: task.name,
                  value: task._id,
                },
              ];
            })
          );
        }
      });
    }
    this.setState({ tasks: taskArray });
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.uncheck !== prevProps.uncheck) {
        this.setState({ isChecked: !this.props.uncheck });
        this.props.resetCheck();
      }
      if (
        this.state.project !== prevProps.selectedProject ||
        this.state.task !== prevProps.task ||
        this.state.description !== prevProps.description ||
        this.state.row !== prevProps.row
      ) {
        let taskArray = [];
        if (this.props.selectedProject) {
          this.props.project.projects.forEach((project) => {
            if (this.props.selectedProject.toString() === project._id) {
              taskArray.push(
                project.tasks.map((task) => {
                  return [
                    {
                      label: task.name,
                      value: task._id,
                    },
                  ];
                })
              );
            }
          });
        }
        this.setState({
          tasks: taskArray,
          project: this.props.selectedProject,
          task: this.props.task,
          description: this.props.description,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEmpty(nextProps.errors) && nextProps.errors[this.props.row]) {
      this.setState({
        errors: {
          [this.props.row]: {
            project: nextProps.errors[this.props.row].project
              ? nextProps.errors[this.props.row].project
              : null,
          },
        },
      });
    } else {
      this.setState({
        errors: {
          [this.props.row]: {
            project: null,
          },
        },
      });
    }
  }

  onProjectSelect = (e) => {
    let taskArray = [];
    this.setState({ project: e, task: null }, () => {
      if (e) {
        this.props.project.projects.forEach((project) => {
          if (e.toString() === project._id) {
            taskArray.push(
              project.tasks.map((task) => {
                return [
                  {
                    label: task.name,
                    value: task._id,
                  },
                ];
              })
            );
          }
        });
      }
      this.setState({ tasks: taskArray });
      this.props.updateProjectInRow(this.props.row, e);
    });
  };

  onTaskSelect = (e) => {
    this.setState({ task: e }, () => {
      this.props.updateTaskInRow(this.props.row, e);
    });
  };

  onDescriptionChange = (e) => {
    let description = e.target.value;
    this.setState({ description: description }, () => {
      this.props.updateDescriptionInRow(this.props.row, description);
    });
  };

  onDeleteRow = () => {
    this.props.processDeleteRequest(this.props.row);
  };

  render() {
    const { projects } = this.props.project;
    const { errors } = this.state;
    let projectArray = [];
    projectArray.push(
      projects.map((project) => {
        return [
          {
            label: project.name,
            value: project._id,
          },
        ];
      })
    );
    return (
      <tr>
        <td className='align-middle'>
          <span className='removeCheckMargin'>
            <Checkbox
              filledIn
              checked={false}
              className='blue-text center-align'
              label=''
              value={`${this.props.row}`}
              key={
                `${this.props.row.toString()}` +
                `${this.state.isChecked.toString()}`
              }
              onChange={() => this.onDeleteRow()}
            />
          </span>
        </td>
        <td>
          <DropDownGroup
            options={flattenDeep(projectArray)}
            onSelect={this.onProjectSelect}
            selectedOption={this.state.project}
            error={errors[this.props.row].project}
            disabled={
              this.state.timesheet_isSubmitted || this.state.disableEntry
            }
            // size="form-control form-control-xs"
            extraCSS='m-2'
          />
        </td>
        <td>
          <DropDownGroup
            options={flattenDeep(this.state.tasks)}
            onSelect={this.onTaskSelect}
            selectedOption={this.state.task}
            disabled={
              this.state.timesheet_isSubmitted || this.state.disableEntry
            }
            // size="form-control form-control-ss"
            extraCSS='m-2'
          />
        </td>
        <td>
          <div className='my-2'>
            <TextFieldGroup
              key={
                `${this.props.row.toString()}` +
                `${this.state.isChecked.toString()}` +
                'description'
              }
              placeholder='Description'
              name='description'
              type='text'
              value={this.state.description}
              onChange={this.onDescriptionChange}
            />
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(TimesheetTemplateRow);
