import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Row, Col } from "react-materialize";
import classnames from "classnames";
import TextFieldGroup from "../common/TextFieldGroup";
import flattenDeep from "../common/flattenDeep";
import DropDownGroup from "./DropDownGroup";

export default class GoalModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: props._id,
      name: props.name,
      description: props.description,
      goalType: props.goalType,
      type: props.type === "" ? null : props.type,
      project: props.project,
      value: props.value,
      units: props.units,
      duration: props.duration,
      chart: props.chart,
      isTeamGoal: props.isTeamGoal,
      projects: props.projects,
      projectLoading: props.projectLoading,
      enabled: props.enabled
    };
    this.onChange = this.onChange.bind(this);
    this.onGoalTypeSelect = this.onGoalTypeSelect.bind(this);
    this.onProjectSelect = this.onProjectSelect.bind(this);
    this.onTypeSelect = this.onTypeSelect.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onUnitsSelect = this.onUnitsSelect.bind(this);
    this.onDurationSelect = this.onDurationSelect.bind(this);
    this.onChartSelect = this.onChartSelect.bind(this);
    this.submit = this.submit.bind(this);
    this.typeSelectionText = this.typeSelectionText.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      !nextProps.projectLoading &&
      this.props.projects !== nextProps.projectLoading
    ) {
      this.setState({
        projects: nextProps.projects,
        projectLoading: nextProps.projectLoading
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onGoalTypeSelect = value => {
    this.setState({ goalType: value });
  };

  onProjectSelect = value => {
    this.setState({ project: value });
  };

  onTypeSelect = value => {
    this.setState({ type: value });
  };

  onValueChange = e => {
    this.setState({ [e.target.name]: Number(e.target.value) });
  };

  onUnitsSelect = value => {
    this.setState({ units: value });
  };

  onDurationSelect = value => {
    this.setState({ duration: value });
  };

  onChartSelect = value => {
    this.setState({ chart: value });
  };

  submit = () => {
    var tempProject, tempType;
    if (this.state.goalType === "type") {
      tempProject = null;
      tempType = this.state.type;
    } else if (this.state.goalType === "project") {
      tempProject = this.state.project;
      tempType = null;
    }
    this.setState({ project: tempProject, type: tempType }, () => {
      this.props.onSubmit(this.state);
    });
  };

  typeSelectionText = text => {
    if (text) {
      return text.substr(0, 1).toUpperCase() + text.slice(1) + " Selection";
    }
  };

  render() {
    var typeSelection;
    if (this.state.goalType === "type") {
      typeSelection = (
        <DropDownGroup
          // title="Type Selection"
          options={[{ label: "Billable", value: "isBillable" }]}
          selectedOption={this.state.type}
          placeholder={this.typeSelectionText(this.state.goalType)}
          clearable
          onSelect={this.onTypeSelect}
        />
      );
    } else if (this.state.goalType === "project") {
      let projectArray = [];
      if (!this.state.projectLoading) {
        projectArray.push(
          this.state.projects.map(project => {
            return [
              {
                label: project.name,
                value: project._id
              }
            ];
          })
        );
      }
      typeSelection = (
        <DropDownGroup
          // title="Project Selection"
          options={flattenDeep(projectArray)}
          selectedOption={this.state.project}
          placeholder={this.typeSelectionText(this.state.goalType)}
          clearable
          onSelect={this.onProjectSelect}
          height={200}
        />
      );
    } else {
      typeSelection = <div style={{ height: 38 }} />;
    }

    return (
      <Modal
        className="modal-fix editGoalModal"
        header={
          this.state._id === null
            ? this.state.isTeamGoal
              ? "New Team Goal"
              : "New Direct Report Goal"
            : this.state.isTeamGoal
            ? "Team Goal"
            : "Direct Report Goal"
        }
        fixedFooter={true}
        trigger={
          <Button
            className={classnames("mx-auto", {
              addGoalText: this.state._id === null,
              editGoalText: this.state._id !== null
            })}
            disabled={!this.state.enabled}
            flat
          >
            {this.state._id === null
              ? this.state.isTeamGoal
                ? "ADD TEAM GOAL"
                : "ADD GOAL"
              : "Edit Goal"}
          </Button>
        }
        actions={
          <span>
            <Button flat style={{ color: "rgba(0,0,0,0.54)" }} modal="close">
              Close
            </Button>
            <Button
              flat
              style={{ color: "#1897EC" }}
              onClick={this.submit}
              modal="close"
            >
              Save
            </Button>
          </span>
        }
      >
        <div className="divider" />
        <div className="modalPadding">
          <Row>
            <Col s={6}>
              <div className="mb-2">
                <h5 className="text-left">Title</h5>
                <TextFieldGroup
                  placeholder="Title"
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={this.onChange}
                  size=""
                  maxLength={60}
                />
                <span style={{ float: "right", fontSize: "14px" }}>
                  Max characters: 60
                </span>
              </div>
              <div className="mb-2">
                <h5 className="text-left">Description</h5>
                <textarea
                  className="form-control goalModalDescription"
                  placeholder="Description"
                  name="description"
                  type="text"
                  value={this.state.description}
                  onChange={this.onChange}
                  maxLength={120}
                />
                <span style={{ float: "right", fontSize: "14px" }}>
                  Max characters: 120
                </span>
              </div>
            </Col>
            <Col s={6}>
              <div className="text-left mb-3">
                <h5 className="text-left">Goal Type</h5>
                <DropDownGroup
                  // title="Goal Type"
                  options={[
                    { label: "Project", value: "project" },
                    { label: "Type", value: "type" }
                  ]}
                  placeholder="Goal Type"
                  selectedOption={this.state.goalType}
                  // extraCSS="editGoalDropDown"
                  onSelect={this.onGoalTypeSelect}
                />
              </div>
              <div className="text-left mb-3">
                <h5 className="text-left">Type / Project Selection</h5>
                {typeSelection}
              </div>
              <Row style={{ margin: 0 }}>
                <Col s={2} style={{ paddingLeft: 0 }}>
                  <div className="text-left mb-3">
                    <h5 className="text-left">Value</h5>
                    <TextFieldGroup
                      // className="goalModalInputFields"
                      placeholder="0"
                      name="value"
                      type="number"
                      label="Value"
                      value={this.state.value.toString()}
                      onChange={this.onValueChange}
                    />
                  </div>
                </Col>
                <Col s={4}>
                  <div className="text-left mb-3">
                    <h5 className="text-left">Units</h5>
                    <DropDownGroup
                      selectedOption={this.state.units}
                      placeholder=""
                      options={[
                        { label: "%", value: "percent" },
                        { label: "hrs", value: "time" }
                      ]}
                      onSelect={this.onUnitsSelect}
                    />
                  </div>
                </Col>
                <Col s={6} style={{ paddingRight: 0 }}>
                  <div className="text-left mb-3">
                    <h5 className="text-left">Duration</h5>
                    <DropDownGroup
                      name="duration"
                      selectedOption={this.state.duration}
                      options={[
                        { label: "One Week", value: 1 },
                        { label: "Two Weeks", value: 2 },
                        { label: "Three Weeks", value: 3 },
                        { label: "Four Weeks", value: 4 }
                      ]}
                      onSelect={this.onDurationSelect}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col s={12}>
                  <div className="text-left mb-3">
                    <h5 className="text-left" style={{ marginTop: 0 }}>
                      Chart
                    </h5>
                    <DropDownGroup
                      selectedOption={this.state.chart}
                      options={[
                        { label: "Progress", value: "progress" },
                        { label: "Bar", value: "bar" }
                      ]}
                      // extraCSS="editGoalDropDown"
                      onSelect={this.onChartSelect}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

GoalModal.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  goalType: PropTypes.string,
  typeSelection: PropTypes.string,
  value: PropTypes.number,
  units: PropTypes.string,
  duration: PropTypes.number,
  chart: PropTypes.string
};

GoalModal.defaultProps = {
  _id: null,
  name: "",
  description: "",
  goalType: null,
  type: null,
  project: null,
  value: 0,
  units: null,
  duration: 0,
  chart: null
};
