import React, { Component, Fragment } from "react";
import TimesheetTemplate from "./timesheet-template/TimesheetTemplate";
import { connect } from "react-redux";
import { Button, Icon, Card, Col, Row } from "react-materialize";
import ChangePassword from "../auth/ChangePassword";
import md5 from "md5";

class UserSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSetting: "timesheetTemplate"
    };
  }
  render() {
    let imageSource;
    if (this.props.auth.user.email) {
      imageSource = `https://${this.props.auth.user.email.substring(
        this.props.auth.user.email.indexOf("@") + 1,
        this.props.auth.user.email.indexOf(".")
      )}.bamboohr.com/employees/photos/?h=${md5(
        this.props.auth.user.email ? this.props.auth.user.email : ""
      )}`;
    }
    return (
      <Fragment>
        <Card className="cardSmallBar">User Settings</Card>
        <div
          style={{ marginTop: "24px", marginLeft: "88px", marginRight: "88px" }}
        >
          <Row className="" style={{ marginBottom: 0 }}>
            <Col s={3}>
              <Card className="removePadding removeRowMargin">
                <Row className="removeRowMargin">
                  <Col>
                    <div
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1.25rem"
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "#C4C4C4",
                          padding: ".25rem"
                        }}
                      >
                        {this.props.auth.user.isAdmin
                          ? "ADMIN"
                          : this.props.auth.user.isSupervisor
                          ? "SUPERVISOR"
                          : "USER"}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col s={3} className="text-center">
                    <img
                      src={imageSource}
                      alt="profile"
                      style={{
                        height: "50px",
                        borderRadius: "50%",
                        marginTop: "20px"
                      }}
                    />
                  </Col>
                  <Col s={9}>
                    <h5>
                      {this.state.firstName} {this.state.lastName}
                    </h5>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px"
                      }}
                    >
                      {this.state.email}
                    </div>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "16px",
                        lineHeight: "19px",
                        color: "rgba(0, 0, 0, 0.87)",
                        paddingTop: "8px"
                      }}
                    >
                      {this.props.auth.user.firstName +
                        " " +
                        this.props.auth.user.lastName}
                    </div>
                    <div
                      style={{
                        fontFamily: "Roboto",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: "14px",
                        lineHeight: "16px",
                        color: "rgba(0, 0, 0, 0.87)",
                        paddingTop: "6px"
                      }}
                    >
                      {this.props.auth.user.email}
                    </div>
                  </Col>
                </Row>
                <div className="divider" />
                <ChangePassword
                  button={
                    <Button flat large className="px-auto fullLink">
                      <Icon left>vpn_key</Icon>
                      Change Password
                    </Button>
                  }
                />
              </Card>
            </Col>
            <Col s={9}>
              <TimesheetTemplate />
            </Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps)(UserSettings);
