import React, { Component } from "react";
import moment from "moment";
import { CollapsibleItem } from "react-materialize";
class RevisionHistoryRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: props._id,
      date: props.date,
      number: props.number,
      notes: props.notes
    };
  }
  render() {
    let versionNotes;
    if (this.state.notes) versionNotes = this.state.notes;
    function createMarkup() {
      return {
        __html: versionNotes
      };
    }
    return (
      <CollapsibleItem
        header={`Version: ${this.state.number}, Released on:${" "}
              ${moment(this.state.date).format("M-D-YYYY")}`}
      >
        <div dangerouslySetInnerHTML={createMarkup()} />
      </CollapsibleItem>
    );
  }
}

export default RevisionHistoryRow;
