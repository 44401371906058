import React, { Fragment } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextFieldGroup = ({
  name,
  placeholder,
  step,
  min,
  max,
  value,
  error,
  success,
  info,
  type,
  onFocus,
  onBlur,
  onChange,
  disabled,
  size,
  additionalCSS,
  infoCSS,
  margin,
  maxLength
}) => {
  return (
    <Fragment>
      <div className="m-auto">
        <input
          type={type}
          className={classnames(
            `${additionalCSS} form-control browser-default m-auto`,
            {
              "invalid-feedback-border": error
            }
          )}
          placeholder={placeholder}
          step={step}
          min={min}
          max={max}
          name={name}
          id={name}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          disabled={disabled ? true : null}
          maxLength={maxLength}
        />
        {info && (
          <small className={classnames("form-text", infoCSS)}>{info}</small>
        )}
        {error && <div className="invalid-feedback">{error}</div>}
        {success && <div className="valid-feedback">{success}</div>}
      </div>
    </Fragment>
  );
};

TextFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  step: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  value: PropTypes.string.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  type: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired
};

TextFieldGroup.defaultProps = {
  type: "text",
  size: "form-control-lg",
  additionalCSS: "",
  margin: ""
};

export default TextFieldGroup;
