import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../common/Spinner';
import TextFieldGroup from '../../common/TextFieldGroup';
import {
  addUser,
  getAllUsers,
  clearUsers,
  clearSupervisees,
  clearEmployeeTypes,
  getSupervisors,
  getEmployeeTypes,
} from '../../../actions/adminActions';
import UserRow from './UserRow';
import DropDownGroup from '../../common/DropDownGroup';
import flattenDeep from '../../common/flattenDeep';
import {
  Button,
  Modal,
  Icon,
  Switch,
  Row,
  Col,
  Table,
  CardPanel,
  Card,
} from 'react-materialize';

class ManageUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      password2: '',
      isActive: true,
      isAdmin: false,
      isSupervisor: false,
      supervisor: null,
      didSave: false,
      firstSearch: '',
      lastSearch: '',
      emailSearch: '',
      statusSearch: true,
      roleSearch: null,
      errors: {},
    };

    this.onChange = this.onChange.bind(this);
    this.addUserSave = this.addUserSave.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.isAdmin) {
        this.props.getAllUsers();
        this.props.getSupervisors();
        this.props.getEmployeeTypes();
      } else {
        this.props.history.push('/timesheet');
      }
    }
  }

  componentWillUnmount() {
    this.props.clearUsers();
    this.props.clearSupervisees();
    this.props.clearEmployeeTypes();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.didSave) {
      if (nextProps.errors) {
        this.setState({ errors: nextProps.errors });
        this.setState({
          didSave: true,
        });
      }
      if (nextProps.successes) {
        if (Object.keys(nextProps.successes).length > 0) {
          window.location.reload();
        }
      }
    }
  }

  onStatusSelect = () => {
    this.setState((prevState) => {
      return { isActive: !prevState.isActive };
    });
  };

  onRoleSearchSelect = (e) => {
    this.setState({ roleSearch: e });
  };

  onStatusSearchSelect = (e) => {
    this.setState({ statusSearch: e });
  };

  onAdminSelect = () => {
    this.setState((prevState) => {
      return { isAdmin: !prevState.isAdmin };
    });
  };

  onSupervisorStatusSelect = () => {
    this.setState((prevState) => {
      return { isSupervisor: !prevState.isSupervisor };
    });
  };

  onEmployeeTypeSelect = (e) => {
    this.setState({ employeeType: e });
  };

  onSupervisorSelect = (e) => {
    this.setState({ supervisor: e });
  };

  addUserSave() {
    const userData = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      employeeType: this.state.employeeType,
      password: this.state.password,
      password2: this.state.password2,
      isActive: this.state.isActive,
      isAdmin: this.state.isAdmin,
      isSupervisor: this.state.isSupervisor,
      supervisor: this.state.supervisor,
    };

    this.props.addUser(userData);

    this.setState({ didSave: true });
  }

  render() {
    const {
      adminUsers,
      adminUsersLoading,
      adminSupervisors,
      adminSupervisorsLoading,
      adminEmployeeTypes,
      adminEmployeeTypesLoading,
    } = this.props.admin;
    const { errors } = this.props;
    let ManageUserTableContent, ManageUserTable;
    let supervisors = [];
    let employeeTypes = [];
    if (
      adminUsers === null ||
      adminUsersLoading ||
      adminSupervisors === null ||
      adminSupervisorsLoading ||
      adminEmployeeTypes === null ||
      adminEmployeeTypesLoading
    ) {
      ManageUserTable = <Spinner />;
    } else {
      let adminUsersList = adminUsers;
      adminUsersList = adminUsersList.filter((user) =>
        user.firstName
          .toLowerCase()
          .includes(this.state.firstSearch.toLowerCase())
      );
      adminUsersList = adminUsersList.filter((user) =>
        user.lastName
          .toLowerCase()
          .includes(this.state.lastSearch.toLowerCase())
      );
      adminUsersList = adminUsersList.filter((user) =>
        user.email.toLowerCase().includes(this.state.emailSearch.toLowerCase())
      );
      adminUsersList = adminUsersList.filter(
        (user) =>
          user.isActive === this.state.statusSearch ||
          this.state.statusSearch === null
      );
      adminUsersList = adminUsersList.filter(
        (user) =>
          (user.isAdmin && this.state.roleSearch === 'Admin') ||
          (user.isSupervisor && this.state.roleSearch === 'Supervisor') ||
          this.state.roleSearch === null
      );
      ManageUserTableContent = adminUsersList.map((userRow) => (
        <UserRow
          key={userRow._id}
          id={userRow._id}
          firstName={userRow.firstName}
          lastName={userRow.lastName}
          email={userRow.email}
          isActive={userRow.isActive}
          isAdmin={userRow.isAdmin}
          createdOn={userRow.createdOn}
          employeeTypes={adminEmployeeTypes}
          supervisors={adminSupervisors}
          isSupervisor={userRow.isSupervisor}
          supervisor={userRow.supervisor}
          employeeType={userRow.employeeType}
        />
      ));

      ManageUserTable = (
        <Table centered hoverable className='white adminTable'>
          <thead>
            <tr>
              <th className='text-left px-4'>First Name</th>
              <th className='text-left px-4'>Last Name</th>
              <th className='text-left px-4'>Email</th>
              <th className='text-left px-4' width='15%'>
                Role
              </th>
              <th className='text-left px-4' width='15%'>
                Status
              </th>
              <th className='text-left px-4' width='12%'>
                Supervisor
              </th>
              <th className='text-center' />
            </tr>
          </thead>
          <tbody>
            <tr className='searchRow'>
              <td className='px-3'>
                <TextFieldGroup
                  placeholder='First Search...'
                  name='firstSearch'
                  type='text'
                  value={this.state.firstSearch}
                  onChange={this.onChange}
                  size=''
                  margin='my-auto'
                />
              </td>
              <td className='px-3'>
                <TextFieldGroup
                  placeholder='Last Search...'
                  name='lastSearch'
                  type='text'
                  value={this.state.lastSearch}
                  onChange={this.onChange}
                  size=''
                  margin='my-auto'
                />
              </td>
              <td className='px-3'>
                <TextFieldGroup
                  placeholder='Email Search...'
                  name='emailSearch'
                  type='text'
                  value={this.state.emailSearch}
                  onChange={this.onChange}
                  size=''
                  margin='my-auto'
                />
              </td>
              <td className='text-left px-3'>
                <DropDownGroup
                  selectedOption={this.state.roleSearch}
                  onSelect={this.onRoleSearchSelect}
                  options={[
                    { label: 'Admin', value: 'Admin' },
                    { label: 'Supervisor', value: 'Supervisor' },
                  ]}
                  clearable={true}
                  searchable={true}
                />
              </td>
              <td className='text-left px-3'>
                <DropDownGroup
                  selectedOption={this.state.statusSearch}
                  onSelect={this.onStatusSearchSelect}
                  options={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false },
                  ]}
                  clearable={true}
                  searchable={true}
                />
              </td>
              <td />
              <td width='5%' />
            </tr>
            {ManageUserTableContent}
          </tbody>
        </Table>
      );

      employeeTypes = adminEmployeeTypes.map((employeeType) => {
        return {
          label: employeeType.type,
          value: employeeType._id,
        };
      });

      supervisors = adminSupervisors.map((supervisor) => {
        return {
          label: supervisor.firstName + ' ' + supervisor.lastName,
          value: supervisor._id,
        };
      });
    }
    return (
      <Fragment>
        <Card className='cardSmallBar'>Users</Card>
        <div className='admin-container'>
          <Modal
            className='modal-fix admin-modal'
            header='New User'
            trigger={
              <Button
                floating
                large
                icon={<Icon>add</Icon>}
                className='blue adminAddButton'
              />
            }
            actions={
              <Fragment>
                <Button flat modal='close'>
                  Close
                </Button>
                <Button
                  flat
                  className='blue-text'
                  type='submit'
                  onClick={this.addUserSave}
                  data-dismiss='modal'
                >
                  Save
                </Button>
              </Fragment>
            }
          >
            <div className='divider' />
            <div className='modalPadding'>
              <Row>
                <Col s={6}>
                  <div className='mb-2'>
                    <h5 className='text-left'>First Name</h5>
                    <TextFieldGroup
                      placeholder='First Name'
                      name='firstName'
                      type='text'
                      value={this.state.firstName}
                      onChange={this.onChange}
                      error={errors.firstName}
                      size=''
                    />
                  </div>
                  <div className='mb-2'>
                    <h5 className='text-left'>Last Name</h5>
                    <TextFieldGroup
                      placeholder='Last Name'
                      name='lastName'
                      type='text'
                      value={this.state.lastName}
                      onChange={this.onChange}
                      error={errors.lastName}
                      size=''
                    />
                  </div>
                  <div className='mb-2'>
                    <h5 className='text-left'>Email</h5>
                    <TextFieldGroup
                      placeholder='Email'
                      name='email'
                      type='text'
                      value={this.state.email}
                      onChange={this.onChange}
                      error={errors.email}
                      size=''
                    />
                  </div>
                  <div className='mb-2'>
                    <h5 className='text-left'>Password</h5>
                    <TextFieldGroup
                      placeholder='Password'
                      name='password'
                      type='password'
                      value={this.state.password}
                      onChange={this.onChange}
                      error={errors.password}
                      size=''
                    />
                  </div>
                  <div className='mb-2'>
                    <TextFieldGroup
                      placeholder='Confirm Password'
                      name='password2'
                      type='password'
                      value={this.state.password2}
                      onChange={this.onChange}
                      error={errors.password2}
                      size=''
                    />
                  </div>
                  <span className='text-left'>Status</span>
                  <Switch
                    id={'Status - New'}
                    checked={this.state.isActive}
                    onChange={this.onStatusSelect}
                    onLabel='Active'
                    offLabel='Inactive'
                  />
                </Col>
                <Col s={6}>
                  <span className='text-left mb-1'>Roles</span>
                  <div className='mb-3'>
                    <Switch
                      id={'Admin - New'}
                      checked={this.state.isAdmin}
                      onChange={this.onAdminSelect}
                      onLabel='Admin'
                      offLabel='Not an Admin'
                    />
                  </div>
                  <div className='mb-3'>
                    <Switch
                      checked={this.state.isSupervisor}
                      onChange={this.onSupervisorStatusSelect}
                      onLabel='Supervisor'
                      offLabel='Not a Supervisor'
                    />
                  </div>
                  <div className='mb-3'>
                    <DropDownGroup
                      selectedOption={this.state.employeeType}
                      onSelect={this.onEmployeeTypeSelect}
                      options={employeeTypes}
                      title='Employee Type'
                      clearable={true}
                    />
                  </div>
                  <div className='mb-3'>
                    <DropDownGroup
                      selectedOption={this.state.supervisor}
                      onSelect={this.onSupervisorSelect}
                      options={supervisors}
                      title='Supervisor'
                      clearable={true}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
          <Card className='tableCard'>
            <CardPanel
              className='adminHeaderBar'
              style={{ borderBottom: '1px solid #E1E2E1' }}
            >
              <div
                style={{
                  fontSize: '16px',
                  lineHeight: '41px',
                  fontWeight: 501,
                }}
              >
                Users
              </div>
            </CardPanel>
            {ManageUserTable}
          </Card>
        </div>
      </Fragment>
    );
  }
}

ManageUsers.propTypes = {
  getAllUsers: PropTypes.func.isRequired,
  admin: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  admin: state.admin,
  auth: state.auth,
  errors: state.errors,
  successes: state.successes,
});

export default connect(mapStateToProps, {
  getAllUsers,
  clearUsers,
  addUser,
  getSupervisors,
  getEmployeeTypes,
  clearEmployeeTypes,
  clearSupervisees,
})(ManageUsers);
