import React, { Component } from "react";

class ReviewTimesheetRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: props.timesheetRow._id,
      project: props.timesheetRow.project_name,
      task: props.timesheetRow.task_name,
      description: props.timesheetRow.description,
      sunday: props.timesheetRow.sunday !== 0 ? props.timesheetRow.sunday : "",
      monday: props.timesheetRow.monday !== 0 ? props.timesheetRow.monday : "",
      tuesday:
        props.timesheetRow.tuesday !== 0 ? props.timesheetRow.tuesday : "",
      wednesday:
        props.timesheetRow.wednesday !== 0 ? props.timesheetRow.wednesday : "",
      thursday:
        props.timesheetRow.thursday !== 0 ? props.timesheetRow.thursday : "",
      friday: props.timesheetRow.friday !== 0 ? props.timesheetRow.friday : "",
      saturday:
        props.timesheetRow.saturday !== 0 ? props.timesheetRow.saturday : "",
      total: props.timesheetRow.total
    };
  }

  render() {
    return (
      <tr height="44px">
        <td className="text-left align-middle pl-4">{this.state.project}</td>
        <td className="text-left align-middle">{this.state.task}</td>
        <td className="text-left align-middle">{this.state.description}</td>
        <td className="text-center align-middle">{this.state.sunday}</td>
        <td className="text-center align-middle">{this.state.monday}</td>
        <td className="text-center align-middle">{this.state.tuesday}</td>
        <td className="text-center align-middle">{this.state.wednesday}</td>
        <td className="text-center align-middle">{this.state.thursday}</td>
        <td className="text-center align-middle">{this.state.friday}</td>
        <td className="text-center align-middle">{this.state.saturday}</td>
        <td className="text-center align-middle">{this.state.total}</td>
      </tr>
    );
  }
}

export default ReviewTimesheetRow;
