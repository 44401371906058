import axios from "axios";
import {
  GET_TIMESHEET,
  GET_CURRENT_TIMESHEET,
  GET_AVAILABLE_TIMESHEETS,
  GET_TIMESHEET_DASHBOARD,
  GET_ERRORS,
  GET_SUCCESSES,
  REVIEW_TIMESHEET,
  SUBMITTED_TIMESHEET,
  UNSUBMITTED_TIMESHEET,
  TIMESHEET_LOADING,
  CLEAR_TIMESHEET,
  CLEAR_ERRORS,
  CLEAR_SUCCESSES
} from "./types";

// Post Save Timesheet
export const saveTimesheet = timesheetData => dispatch => {
  axios
    .post("/api/timesheets/saveTimesheet", timesheetData)
    .then(res => {
      dispatch({ type: GET_SUCCESSES, payload: res.data });
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Post Timesheet Totals
export const saveTimesheetTotals = timesheetTotalData => dispatch => {
  axios
    .post("/api/timesheets/saveTimesheetTotals", timesheetTotalData)
    .then(res => {})
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Get Timesheet By ID
export const getTimesheet = id => dispatch => {
  dispatch(setTimesheetLoading());
  axios
    .get(`/api/timesheets/getTimesheet/${id}`)
    .then(res =>
      dispatch({
        type: GET_TIMESHEET,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_TIMESHEET,
        payload: null
      })
    );
};

// Get Timesheet By ID
export const getTimesheetDashboard = (startDate, endDate) => dispatch => {
  axios
    .get(`/api/goals/getUserGoals/${startDate}/${endDate}`)
    .then(res => {
      dispatch({
        type: GET_TIMESHEET_DASHBOARD,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: GET_TIMESHEET,
        payload: {}
      })
    );
};

// Get Current Timesheet
export const getCurrentTimesheet = (id, formattedTimezone) => dispatch => {
  dispatch(setTimesheetLoading());
  axios
    .get(`/api/timesheets/getCurrentTimesheet/${id}/${formattedTimezone}`)
    .then(res =>
      dispatch({
        type: GET_CURRENT_TIMESHEET,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_CURRENT_TIMESHEET,
        payload: null
      })
    );
};

// Get the last 4 weeks of timesheets
export const getAvailableTimesheets = (id, formattedTimezone) => dispatch => {
  dispatch(setTimesheetLoading());
  axios
    .get(`/api/timesheets/getAvailableTimesheets/${id}/${formattedTimezone}`)
    .then(res =>
      dispatch({
        type: GET_AVAILABLE_TIMESHEETS,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_AVAILABLE_TIMESHEETS,
        payload: null
      })
    );
};

// Review Timesheet By ID
export const reviewTimesheet = id => dispatch => {
  dispatch(setTimesheetLoading());
  axios
    .get(`/api/timesheets/reviewTimesheet/${id}`)
    .then(res =>
      dispatch({
        type: REVIEW_TIMESHEET,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: REVIEW_TIMESHEET,
        payload: null
      })
    );
};

//Set Timesheet to Submitted
export const submitTimesheet = submitData => dispatch => {
  dispatch(setTimesheetLoading());
  axios
    .post(`/api/timesheets/submitTimesheet`, submitData)
    .then(res =>
      dispatch({
        type: SUBMITTED_TIMESHEET,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

//Set Timesheet to not Submitted
export const unsubmitTimesheet = unsubmitData => dispatch => {
  axios
    .post(`/api/timesheets/unsubmitTimesheet`, unsubmitData)
    .then(res =>
      dispatch({
        type: UNSUBMITTED_TIMESHEET,
        payload: res.data
      })
    )
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Loading Spinner
export const setTimesheetLoading = () => {
  return {
    type: TIMESHEET_LOADING
  };
};

// Clear Timesheet
export const clearTimesheet = () => {
  return {
    type: CLEAR_TIMESHEET
  };
};

// Clear Timesheet Errors
export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS
  };
};

export const clearSuccesses = () => {
  return {
    type: CLEAR_SUCCESSES
  };
};
