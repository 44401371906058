import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const NumberFieldGroup = ({
  name,
  placeholder,
  step,
  min,
  max,
  value,
  label,
  error,
  success,
  info,
  type,
  onFocus,
  onBlur,
  onChange,
  disabled,
  size,
  onKeyPress
}) => {
  return (
    <div className="form-group form-group-number m-auto">
      <input
        type={type}
        className={classnames(
          "form-control browser-default text-center",
          size,
          {
            "is-invalid": error,
            "is-valid": success
          }
        )}
        placeholder={placeholder}
        step={step}
        min={min}
        max={max}
        name={name}
        id={name}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled ? true : null}
        onKeyDown={onKeyPress}
      />
      {info && <small className="form-text text-muted">{info}</small>}
      {error && <div className="invalid-feedback">{error}</div>}
      {success && <div className="valid-feedback">{success}</div>}
    </div>
  );
};

NumberFieldGroup.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  step: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  value: PropTypes.any.isRequired,
  info: PropTypes.string,
  error: PropTypes.string,
  success: PropTypes.string,
  type: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onkeyPress: PropTypes.func
};

NumberFieldGroup.defaultProps = {
  type: "text",
  size: "form-control-lg",
  onKeyPress: null
};

export default NumberFieldGroup;
