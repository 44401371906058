import React, { Component } from "react";

class gettingStarted extends Component {
  render() {
    return (
      <div className="container pb-5 w-75">
        <div className=" text-center template-title pb-3">How to use Aeon</div>
        <div className="row">
          <div className="col" />
          <div className="col-8">
            <div
              className="wistia_responsive_padding"
              style={{ padding: "56.25% 0 0 0", position: "relative" }}
            >
              <div
                className="wistia_responsive_wrapper"
                style={{
                  height: "100%",
                  left: "0",
                  position: "absolute",
                  top: "0",
                  width: "100%"
                }}
              >
                <iframe
                  src="https://fast.wistia.net/embed/iframe/djd3ifv7qm?videoFoam=true"
                  title="Wistia video player"
                  allowtransparency="true"
                  frameBorder="0"
                  scrolling="no"
                  className="wistia_embed"
                  name="wistia_embed"
                  allowFullScreen
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                  oallowfullscreen="true"
                  msallowfullscreen="true"
                  width="100%"
                  height="100%"
                />
              </div>
            </div>
            <script
              src="https://fast.wistia.net/assets/external/E-v1.js"
              async
            />
          </div>
          <div className="col" />
        </div>
        <div id="accordion">
          <div className=" text-center template-title pb-5 pt-5">
            Frequently Asked Questions
          </div>
          <div className="row">
            <div className="col">
              <div className="card">
                <button
                  className="collapsed text-center p-auto link-button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <div className="card-header" id="headingOne">
                    <div className="text-left">
                      How do I know if my timesheet has been saved?
                      <div className="float-right">
                        <i className="fas fa-chevron-down" />
                      </div>
                    </div>
                  </div>
                </button>

                <div
                  id="collapseOne"
                  className="collapse"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    The autosave feature saves your timesheet after your cursor
                    leaves a field. Aeon also displays when your current
                    timesheet was last updated above the Saturday column.
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <button
                  className="collapsed text-center p-auto link-button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <div className="card-header" id="headingTwo">
                    <div className="text-left">
                      How do I create a template?
                      <div className="float-right">
                        <i className="fas fa-chevron-down" />
                      </div>
                    </div>
                  </div>
                </button>
                <div
                  id="collapseTwo"
                  className="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Select the drop-down list next to your name, and navigate to
                    Settings. On the Timesheet Template page, add the rows and
                    projects you want to appear in your timesheet for the next
                    week. Unlike, the current timesheet page, “Task” is not a
                    required field and you must manually save your template with
                    the exception of when you delete a row. Your template will
                    not take effect until the next Sunday.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card">
                <button
                  className="collapsed text-center p-auto link-button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                  display="block"
                >
                  <div className="card-header" id="headingThree">
                    <div className="text-left">
                      When do I review and submit my timesheet?
                      <div className="float-right">
                        <i className="fas fa-chevron-down" />
                      </div>
                    </div>
                  </div>
                </button>
                <div
                  id="collapseThree"
                  className="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Since Aeon autosaves your information after your cursor
                    leaves a field, you only have to Review and Submit your
                    timesheet on the last day you work. This is the equivalent
                    to sending your timesheet to time@e2iautomation.com.
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <button
                  className="collapsed text-center p-auto link-button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  display="block"
                >
                  <div className="card-header" id="headingFour">
                    <div className="text-left">
                      How do I view my previous timesheets?
                      <div className="float-right">
                        <i className="fas fa-chevron-down" />
                      </div>
                    </div>
                  </div>
                </button>
                <div
                  id="collapseFour"
                  className="collapse"
                  aria-labelledby="headingFour"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    You can view the current week, plus the previous three
                    weeks. You can navigate to different weeks by using the left
                    and right arrows above the Project column.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="card">
                <button
                  className="collapsed text-center p-auto link-button"
                  data-toggle="collapse"
                  data-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                  display="block"
                >
                  <div className="card-header" id="headingFive">
                    <div className="text-left">
                      What browsers can I use?
                      <div className="float-right">
                        <i className="fas fa-chevron-down" />
                      </div>
                    </div>
                  </div>
                </button>
                <div
                  id="collapseFive"
                  className="collapse"
                  aria-labelledby="headingFive"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    Aeon works best in Google Chrome, but will also work in
                    Firefox and Edge. Aeon does not support Internet Explorer.
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <button
                  className="collapsed text-center p-auto link-button"
                  data-toggle="collapse"
                  data-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                  display="block"
                >
                  <div className="card-header" id="headingSix">
                    <div className="text-left">
                      Which fields are required in my Timesheet?
                      <div className="float-right">
                        <i className="fas fa-chevron-down" />
                      </div>
                    </div>
                  </div>
                </button>
                <div
                  id="collapseSix"
                  className="collapse"
                  aria-labelledby="headingSix"
                  data-parent="#accordion"
                >
                  <div className="card-body">
                    The Project and Task fields are required.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default gettingStarted;
