import {
  GET_TIMESHEET,
  GET_CURRENT_TIMESHEET,
  GET_AVAILABLE_TIMESHEETS,
  GET_TIMESHEET_DASHBOARD,
  TIMESHEET_LOADING,
  CLEAR_TIMESHEET,
  SUBMITTED_TIMESHEET,
  UNSUBMITTED_TIMESHEET,
  REVIEW_TIMESHEET,
  STOP_REVIEW
} from "../actions/types";

const initialState = {
  timesheet: null,
  reviewingTimesheet: false,
  timesheetLoading: false,
  timesheetDashboard: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TIMESHEET_LOADING:
      return {
        ...state,
        timesheetLoading: true
      };
    case GET_TIMESHEET:
      return {
        ...state,
        timesheet: action.payload,
        timesheetLoading: false
      };
    case GET_CURRENT_TIMESHEET:
      return {
        ...state,
        timesheet: action.payload,
        timesheetLoading: false
      };
    case GET_AVAILABLE_TIMESHEETS:
      return {
        ...state,
        timesheets: action.payload,
        timesheetLoading: false
      };
    case GET_TIMESHEET_DASHBOARD:
      return {
        ...state,
        timesheetDashboard: action.payload
      };
    case REVIEW_TIMESHEET:
      return {
        ...state,
        timesheet: action.payload,
        reviewingTimesheet: true,
        timesheetLoading: false
      };
    case STOP_REVIEW:
      return {
        ...state,
        reviewingTimesheet: false,
        timesheetLoading: false
      };
    case SUBMITTED_TIMESHEET:
      return {
        ...state,
        timesheet: action.payload,
        timesheetLoading: false
      };
    case UNSUBMITTED_TIMESHEET:
      return {
        ...state,
        timesheet: action.payload,
        timesheetLoading: false
      };
    case CLEAR_TIMESHEET:
      return {
        ...state,
        timesheet: null,
        timesheets: null,
        timesheetDashboard: []
      };
    default:
      return state;
  }
}
