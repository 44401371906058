import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { getLatestVersion } from "../../actions/versionActions";
import {
  getHasSeenRelease,
  updateHasSeenRelease
} from "../../actions/userActions";
import Spinner from "../common/Spinner";
import { Modal, Button } from "react-materialize";

class RevisionHistoryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      versionNumber: "",
      releaseNotes: "",
      hasSeenRelease: true
    };
    this.closeModal = this.closeModal.bind(this);
    this.remindMeLater = this.remindMeLater.bind(this);
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.getLatestVersion();
      this.props.getHasSeenRelease(this.props.auth.user.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.versions.latestVersion !== prevProps.versions.latestVersion ||
        this.props.auth.hasSeenRelease !== prevProps.auth.hasSeenRelease) &&
      this.props.versions.latestVersion !== null &&
      this.props.auth.hasSeenRelease !== null
    ) {
      this.setState({
        versionNumber: this.props.versions.latestVersion.number,
        releaseNotes: this.props.versions.latestVersion.notes,
        hasSeenRelease: this.props.auth.hasSeenRelease
      });
    }
  }

  closeModal() {
    this.props.updateHasSeenRelease({ id: this.props.auth.user.id });
  }

  remindMeLater() {
    this.setState({ hasSeenRelease: true });
  }

  render() {
    const { versionsLoading } = this.props.versions;
    let releaseNotes;
    if (
      this.state.versionNumber === "" ||
      this.state.releaseNotes === "" ||
      versionsLoading
    )
      releaseNotes = <Spinner />;
    else {
      releaseNotes = this.state.releaseNotes;
    }
    function createMarkup() {
      return {
        __html: releaseNotes
      };
    }

    return (
      <Modal
        open={!this.state.hasSeenRelease ? true : false}
        className="modal-fix admin-modal"
        id="revisionHistory"
        header="Just Released!"
        actions={
          <Fragment>
            <Button flat onClick={this.remindMeLater}>
              Remind me later
            </Button>
            <Button className="blue-text white" onClick={this.closeModal}>
              Got it
            </Button>
          </Fragment>
        }
      >
        <div className="divider" />
        <div className="modalPadding">
          <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  versions: state.versions
});

export default connect(
  mapStateToProps,
  { getLatestVersion, getHasSeenRelease, updateHasSeenRelease }
)(RevisionHistoryModal);
