import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getTimesheet } from "../../actions/timesheetActions";
import Spinner from "../common/Spinner";
import { Card, Col, Row } from "react-materialize";
import happyAstronaut from "../../img/happyAstronaut.svg";
import satellite from "../../img/satellite.svg";

class ReviewTimesheetConfirmation extends Component {
  render() {
    const { timesheet, timesheetLoading } = this.props.timesheet;
    let submissionMessage;
    if (!timesheet || timesheetLoading) {
      submissionMessage = <Spinner />;
    } else if (timesheet.isSubmitted) {
      submissionMessage = (
        <Fragment>
          <div className="center-align">
            <img
              style={{ position: "relative", top: "-24px", left: "-40px" }}
              src={happyAstronaut}
              alt="Astronaut"
            />
          </div>
          <h4 className="center-align">Success!</h4>
          <div className="center-align" style={{ fontSize: "16px" }}>
            Your timesheet was submitted successfully
          </div>
        </Fragment>
      );
    } else {
      submissionMessage = (
        <Fragment>
          <div className="center-align">
            <img
              style={{ position: "relative", top: "", left: "" }}
              src={satellite}
              alt="Satellite"
            />
          </div>
          <h4 className="center-align">Something Went Wrong</h4>
          <div className="center-align" style={{ fontSize: "16px" }}>
            Your timesheet has not submitted.
          </div>
        </Fragment>
      );
    }

    return (
      <Row
        className="valign-wrapper"
        style={{ position: "relative", top: "20vh" }}
      >
        <Col offset="s4" s={4}>
          <Card>
            <div>{submissionMessage}</div>
            <div className="center-align">
              <Link
                className="btn white blue-text center-align mt-4"
                to="/timesheet"
              >
                Return
              </Link>
            </div>
          </Card>
        </Col>
        <Col s={4} />
      </Row>
    );
  }
}

ReviewTimesheetConfirmation.propTypes = {
  timesheet: PropTypes.object,
  getTimesheet: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  timesheet: state.timesheet
});

export default connect(
  mapStateToProps,
  { getTimesheet }
)(ReviewTimesheetConfirmation);
