import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { azureRedirect } from '../../actions/authActions';
import { withRouter, Link } from 'react-router-dom';
import Spinner from '../common/Spinner';

class AzureRedirect extends Component {
  componentDidMount() {
    const code = {
      code: new URLSearchParams(window.location.search).get('code'),
    };

    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/timesheet');
    }
    if (code) {
      this.props.azureRedirect(code);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/timesheet');
    }
  }

  render() {
    let displayedOutput;

    if (this.props.errors.length > 0) {
      displayedOutput = (
        <div>
          An error has been encountered, please contact your administrator.
          <br />
          <Link className='white blue-text' to='/login'>
            Return to Login
          </Link>
        </div>
      );
    } else {
      displayedOutput = <Spinner />;
    }

    return <div className='pt-4'>{displayedOutput}</div>;
  }
}

AzureRedirect.propTypes = {
  azureRedirect: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { azureRedirect })(
  withRouter(AzureRedirect)
);
