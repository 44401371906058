import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TimesheetTableRow from "./TimesheetTableRow";
import { withRouter } from "react-router-dom";
import Spinner from "../common/Spinner";
import Goal from "../common/Goal";
import { getActiveProjects } from "../../actions/projectActions";
import {
  unsubmitTimesheet,
  clearErrors,
  saveTimesheetTotals,
  saveTimesheet,
  getTimesheetDashboard
} from "../../actions/timesheetActions";
import moment from "moment";
import isNumber from "../../validation/is-number";
import sortRowNumber from "../common/sortRowNumber";
import {
  Table,
  Button,
  Modal,
  CardPanel,
  Row,
  Col,
  Card
} from "react-materialize";
import classnames from "classnames";

class TimesheetTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: props.selectedTimesheet.isSubmitted,
      submittedOn: props.selectedTimesheet.submittedOn,
      lastUpdatedTime: props.selectedTimesheet.lastUpdate,
      sundayTotal: props.selectedTimesheet.sundayTotal,
      mondayTotal: props.selectedTimesheet.mondayTotal,
      tuesdayTotal: props.selectedTimesheet.tuesdayTotal,
      wednesdayTotal: props.selectedTimesheet.wednesdayTotal,
      thursdayTotal: props.selectedTimesheet.thursdayTotal,
      fridayTotal: props.selectedTimesheet.fridayTotal,
      saturdayTotal: props.selectedTimesheet.saturdayTotal,
      grandTotal: props.selectedTimesheet.grandTotal,
      id: props.selectedTimesheet._id,
      sunday: props.selectedTimesheet.startDate,
      saturday: props.selectedTimesheet.endDate,
      timesheetRows: props.selectedTimesheet.timesheetRows,
      counter: props.selectedTimesheet.timesheetRows.length,
      timesheetDashboard: [],
      timesheetDeleteArray: [],
      isErrors: false,
      isEditting: false,
      initialLoad: true,
      empty: false
    };

    this.lastUpdated = this.lastUpdated.bind(this);
    this.projTaskDescFunc = this.projTaskDescFunc.bind(this);
    this.dailyTotalFunc = this.dailyTotalFunc.bind(this);
    this.saveTotals = this.saveTotals.bind(this);
    this.addRows = this.addRows.bind(this);
    this.receiveDeleteRequest = this.receiveDeleteRequest.bind(this);
    this.processDeleteRequest = this.processDeleteRequest.bind(this);
    this.editTimesheet = this.editTimesheet.bind(this);
    this.reviewTimesheetAction = this.reviewTimesheetAction.bind(this);
    this.reviewModalClose = this.reviewModalClose.bind(this);
    this.receiveOnChange = this.receiveOnChange.bind(this);
    this.receiveOnBlur = this.receiveOnBlur.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.sendDateChange = this.sendDateChange.bind(this);
    this.addCurrentDayCircle = this.addCurrentDayCircle.bind(this);
    this.sendTasks = this.sendTasks.bind(this);
  }

  componentDidMount() {
    this.props.getActiveProjects(this.state.sunday, this.state.saturday);
    this.props.clearErrors();
    this.props.getTimesheetDashboard(this.state.sunday, this.state.saturday);
    this.setState(prevState => {
      return {
        timesheetRows: prevState.timesheetRows.sort(sortRowNumber)
      };
    });
    this.interval = setInterval(
      () => this.setState({ lastUpdatedTime: this.state.lastUpdatedTime }),
      1000
    );
    window.addEventListener("keyup", this.handleKeyPress);
    window.addEventListener("keydown", this.handleAddRow);

    // TO DO: See if this can move out of the timesheet Table and into the Goal.js component.
    // Chart.pluginService.register({
    //   beforeDraw: function(chart) {
    //     if (chart.config.options.elements.center) {
    //       //Get ctx from string
    //       var ctx = chart.chart.ctx;

    //       //Get options from the center object in options
    //       var centerConfig = chart.config.options.elements.center;
    //       var fontStyle = centerConfig.fontStyle || "Arial";
    //       var txt = centerConfig.text;
    //       var color = centerConfig.color || "#000";
    //       var sidePadding = centerConfig.sidePadding || 20;
    //       var sidePaddingCalculated =
    //         (sidePadding / 100) * (chart.innerRadius * 2);
    //       //Start with a base font of 30px
    //       ctx.font = "30px " + fontStyle;

    //       //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    //       var stringWidth = ctx.measureText(txt).width;
    //       var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

    //       // Find out how much the font can grow in width.
    //       var widthRatio = elementWidth / stringWidth;
    //       var newFontSize = Math.floor(30 * widthRatio);
    //       var elementHeight = chart.innerRadius * 2;

    //       // Pick a new font size so it will not be larger than the height of label.
    //       var fontSizeToUse = Math.min(newFontSize, elementHeight);

    //       //Set font settings to draw it correctly.
    //       ctx.textAlign = "center";
    //       ctx.textBaseline = "middle";
    //       var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
    //       var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
    //       ctx.font = fontSizeToUse + "px " + fontStyle;
    //       ctx.fillStyle = color;

    //       //Draw text in center
    //       ctx.fillText(txt, centerX, centerY);
    //     }
    //   }
    // });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.selectedTimesheet.timesheetRows !==
      prevProps.selectedTimesheet.timesheetRows
    ) {
      this.setState({
        timesheetRows: this.props.selectedTimesheet.timesheetRows.sort(
          sortRowNumber
        ),
        timesheetRowLoading: this.props.selectedTimesheet.timesheetLoading
      });
      this.props.getTimesheetDashboard(this.state.sunday, this.state.saturday);
    }
    if (this.props.selectedTimesheet) {
      if (this.props.selectedTimesheet.isSubmitted !== this.state.isSubmitted) {
        this.setState({
          isSubmitted: this.props.timesheet.isSubmitted,
          submittedOn: this.props.timesheet.submittedOn
        });
      }
    }
    if (prevProps.successes !== this.props.successes) {
      this.props.getTimesheetDashboard(this.state.sunday, this.state.saturday);
    }

    if (
      this.props.timesheet.timesheetDashboard !==
      prevProps.timesheet.timesheetDashboard
    ) {
      this.setState({
        timesheetDashboard: this.props.timesheet.timesheetDashboard
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    window.removeEventListener("keyup", this.handleKeyPress);
    window.removeEventListener("keydown", this.handleAddRow);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors.length > 0) {
      this.setState({ isErrors: true });
    } else {
      this.setState({ isErrors: false });
    }
    if (
      this.state.initialLoad ||
      this.state.id !== nextProps.selectedTimesheet._id
    ) {
      this.setState(
        {
          id: nextProps.selectedTimesheet._id,
          isSubmitted: nextProps.selectedTimesheet.isSubmitted,
          submittedOn: nextProps.selectedTimesheet.submittedOn,
          lastUpdatedTime: nextProps.selectedTimesheet.lastUpdate,
          sundayTotal: nextProps.selectedTimesheet.sundayTotal,
          mondayTotal: nextProps.selectedTimesheet.mondayTotal,
          tuesdayTotal: nextProps.selectedTimesheet.tuesdayTotal,
          wednesdayTotal: nextProps.selectedTimesheet.wednesdayTotal,
          thursdayTotal: nextProps.selectedTimesheet.thursdayTotal,
          fridayTotal: nextProps.selectedTimesheet.fridayTotal,
          saturdayTotal: nextProps.selectedTimesheet.saturdayTotal,
          grandTotal: nextProps.selectedTimesheet.grandTotal,
          sunday: nextProps.selectedTimesheet.startDate,
          saturday: nextProps.selectedTimesheet.endDate,
          timesheetRows: nextProps.selectedTimesheet.timesheetRows,
          counter: nextProps.selectedTimesheet.timesheetRows.length,
          initialLoad: false
        },
        () => {
          this.props.getTimesheetDashboard(
            this.state.sunday,
            this.state.saturday
          );
        }
      );
    }
  }

  findNextTabStop(e) {
    let inputs = document.querySelectorAll("input[type='number']");
    let list = Array.prototype.filter.call(inputs, function(item) {
      return item.tabIndex >= "0";
    });
    let index = list.indexOf(e);
    return list[index + 1] || list[0];
  }

  findPreviousTabStop(e) {
    let inputs = document.querySelectorAll("input[type='number']");
    let list = Array.prototype.filter.call(inputs, function(item) {
      return item.tabIndex >= "0";
    });
    let index = list.indexOf(e);
    return list[index - 1] || list[0];
  }

  findBelowTabStop(e) {
    let inputs = document.querySelectorAll("input[type='number']");
    let list = Array.prototype.filter.call(inputs, function(item) {
      return item.tabIndex >= "0";
    });
    let index = list.indexOf(e);
    return list[index + 7] || list[0];
  }

  findAboveTabStop(e) {
    let inputs = document.querySelectorAll("input[type='number']");
    let list = Array.prototype.filter.call(inputs, function(item) {
      return item.tabIndex >= "0";
    });
    let index = list.indexOf(e);
    return list[index - 7] || list[0];
  }

  handleAddRow = e => {
    if (e.altKey && e.key === "a" && this.state.isSubmitted === false) {
      this.addRows();
    }
  };

  handleKeyPress = e => {
    let focusedElement = document.activeElement;
    if (focusedElement.type === "number") {
      switch (e.key) {
        case "ArrowRight":
          let nextEl = this.findNextTabStop(focusedElement);
          nextEl.focus();
          break;
        case "ArrowDown":
          let belowEl = this.findBelowTabStop(focusedElement);
          belowEl.focus();
          break;
        case "ArrowLeft":
          let previousEl = this.findPreviousTabStop(focusedElement);
          previousEl.focus();
          break;
        case "ArrowUp":
          let aboveEl = this.findAboveTabStop(focusedElement);
          aboveEl.focus();
          break;
        default:
          break;
      }
    }
  };

  saveTotals(row) {
    let timesheetRowsNoTotal = this.state.timesheetRows;
    for (let i = 0; i < this.state.timesheetRows.length; i++) {
      delete timesheetRowsNoTotal[i].total;
    }
    const timesheetData = {
      _id: this.state.id,
      lastUpdated: this.state.lastUpdatedTime,
      timesheetRows: timesheetRowsNoTotal,
      rowNumber: row
    };
    this.props.saveTimesheet(timesheetData);
  }

  lastUpdated = (e, row) => {
    let now = new Date(e);
    this.setState({ lastUpdatedTime: now }, () => this.saveTotals(row));
  };

  /**
   * This funtion is to set the tasks in the timesheet row for validation later.
   */
  sendTasks = (rowNumber, taskArray) => {
    let timesheetRowsTemp = this.state.timesheetRows;
    timesheetRowsTemp[rowNumber].tasks = taskArray;
    this.setState({
      timesheetRows: timesheetRowsTemp
    });
  };

  /**
   * This function is for updating and saving the Project, Task, and Description fields.
   * @param project is the project_id.
   * @param task is the task_id.
   * @param description is the description.
   * @param rowNumber the timesheetRow._id being modified
   */
  projTaskDescFunc = (project, task, description, rowNumber) => {
    this.setState(
      prevState => {
        for (let i = 0; i < prevState.timesheetRows.length; i++) {
          if (prevState.timesheetRows[i].rowNumber === rowNumber) {
            if (project !== undefined)
              prevState.timesheetRows[i]["project_id"] = project;
            if (task !== undefined)
              prevState.timesheetRows[i]["task_id"] = task;
            if (description !== undefined)
              prevState.timesheetRows[i]["description"] =
                description !== "" ? description : null;
            break;
          }
        }
        return {
          timesheetRows: prevState.timesheetRows
        };
      },
      () => {
        this.lastUpdated(moment(), rowNumber);
      }
    );
  };

  dailyTotalFunc = (name, value, rowNum, project, task) => {
    let dailyTotal = 0;
    this.setState(
      prevState => {
        for (let i = 0; i < prevState.timesheetRows.length; i++) {
          if (prevState.timesheetRows[i].rowNumber === rowNum) {
            prevState.timesheetRows[i][name] = value;
            break;
          }
        }
        return {
          timesheetRows: prevState.timesheetRows
        };
      },
      () => {
        if (isNumber(value)) {
          for (let j = 0; j < this.state.timesheetRows.length; j++) {
            dailyTotal += this.state.timesheetRows[j][name];
          }
          this.setState({ [name + "Total"]: dailyTotal }, () => {
            this.setState(
              {
                grandTotal:
                  this.state.sundayTotal +
                  this.state.mondayTotal +
                  this.state.tuesdayTotal +
                  this.state.wednesdayTotal +
                  this.state.thursdayTotal +
                  this.state.fridayTotal +
                  this.state.saturdayTotal
              },
              () => {
                if (project && task) this.lastUpdated(moment(), rowNum);
              }
            );
          });
        } else {
          this.lastUpdated(moment(), rowNum);
        }
      }
    );
  };

  addRows() {
    const newRow = {
      rowNumber: this.state.counter,
      project_id: null,
      task_id: null,
      description: "",
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      total: 0
    };
    this.setState(prevState => {
      prevState.timesheetRows.push(newRow);
      prevState.counter++;
      return {
        timesheetRows: prevState.timesheetRows,
        counter: prevState.counter
      };
    });
  }

  sendDateChange(e) {
    this.props.dateButtonFunc(e, this.state.sunday, this.state.saturday);
  }

  editTimesheet() {
    const payload = {
      id: this.state.id
    };

    this.props.unsubmitTimesheet(payload);
  }

  reviewTimesheetAction() {
    if (this.state.grandTotal === 0) {
      this.setState({ empty: true });
    } else {
      this.props.history.push(`/reviewTimesheet/${this.state.id}`);
    }
  }

  reviewModalClose = () => {
    this.setState({ empty: false });
  };

  receiveOnChange = e => {
    this.setState({ isEditting: true });
  };

  receiveOnBlur = e => {
    this.setState({ isEditting: false });
  };

  receiveDeleteRequest = () => {
    if (
      this.state.timesheetRows.length > this.state.timesheetDeleteArray.length
    ) {
      this.setState(
        prevState => {
          prevState.timesheetDeleteArray.forEach(element => {
            {
              let foundIndex = this.state.timesheetRows.findIndex(
                index => index.rowNumber === element
              );
              prevState.sundayTotal -=
                prevState.timesheetRows[foundIndex].sunday;
              prevState.mondayTotal -=
                prevState.timesheetRows[foundIndex].monday;
              prevState.tuesdayTotal -=
                prevState.timesheetRows[foundIndex].tuesday;
              prevState.wednesdayTotal -=
                prevState.timesheetRows[foundIndex].wednesday;
              prevState.thursdayTotal -=
                prevState.timesheetRows[foundIndex].thursday;
              prevState.fridayTotal -=
                prevState.timesheetRows[foundIndex].friday;
              prevState.saturdayTotal -=
                prevState.timesheetRows[foundIndex].saturday;

              prevState.grandTotal =
                prevState.sundayTotal +
                prevState.mondayTotal +
                prevState.tuesdayTotal +
                prevState.wednesdayTotal +
                prevState.thursdayTotal +
                prevState.fridayTotal +
                prevState.saturdayTotal;
              prevState.timesheetRows.splice(foundIndex, 1);
            }
          });
          for (let j = 0; j < prevState.timesheetRows.length; j++) {
            prevState.timesheetRows[j].rowNumber = j;
          }
          return {
            timesheetRows: prevState.timesheetRows,
            counter: prevState.timesheetRows.length,
            timesheetDeleteArray: []
          };
        },
        () => {
          this.lastUpdated(moment());
        }
      );
      this.props.clearErrors();
    } else {
      this.setState({ tableErrorInfo: true });
    }
  };

  processDeleteRequest = rowNum => {
    let foundIndex = this.state.timesheetDeleteArray.findIndex(
      index => index === rowNum
    );
    if (foundIndex > -1) {
      this.setState(prevState => {
        prevState.timesheetDeleteArray.splice(foundIndex, 1);
        return {
          timesheetDeleteArray: prevState.timesheetDeleteArray
        };
      });
    } else {
      this.setState(prevState => {
        prevState.timesheetDeleteArray.push(rowNum);
        return {
          timesheetDeleteArray: prevState.timesheetDeleteArray
        };
      });
    }
  };

  addCurrentDayCircle(e) {
    if (
      moment().isAfter(this.state.sunday) &&
      moment().isBefore(this.state.saturday) &&
      moment().day() === e
    ) {
      return <i className="fas fa-circle fa-stack-2x" />;
    }
  }

  render() {
    const { projects, loading } = this.props.project;
    const { timesheet } = this.props;
    let timesheetTableContent,
      timesheetStatusContent,
      timesheetMain,
      tableInfoContent;

    if (projects === null || timesheet === null || loading) {
      timesheetMain = <Spinner />;
    } else {
      timesheetTableContent = this.state.timesheetRows.map(timesheetRow => {
        return (
          <TimesheetTableRow
            key={timesheetRow.rowNumber + `${timesheetRow._id}`}
            _id={timesheetRow._id}
            rowNumber={timesheetRow.rowNumber}
            isSubmitted={this.state.isSubmitted}
            timesheetRow={timesheetRow}
            projectList={projects}
            lastUpdated={this.lastUpdated}
            projTaskDescFunc={this.projTaskDescFunc}
            dailyTotalFunc={this.dailyTotalFunc}
            processDeleteRequest={this.processDeleteRequest}
            receiveOnChange={this.receiveOnChange}
            receiveOnBlur={this.receiveOnBlur}
            sendTasks={this.sendTasks}
          />
        );
      });

      timesheetMain = (
        <Fragment>
          <Card className="tableCard">
            <CardPanel
              className="tableHeaderBar"
              style={{ borderBottom: "1px solid #E1E2E1" }}
            >
              <Button
                flat
                className="blue-text"
                disabled={this.state.isSubmitted || this.state.isErrors}
                onClick={this.addRows}
              >
                Add
              </Button>
              <Button
                flat
                className="blue-text"
                disabled={this.state.isSubmitted || this.state.isErrors}
                onClick={this.receiveDeleteRequest}
              >
                Delete
              </Button>
              <Button
                className={classnames("right white blue-text", {
                  hide: !this.state.isSubmitted
                })}
                onClick={this.editTimesheet}
              >
                Edit Timesheet
              </Button>
              <Button
                className={classnames("right white blue-text", {
                  hide: this.state.isSubmitted
                })}
                disabled={
                  this.state.isErrors ||
                  this.state.isSubmitted ||
                  this.state.isEditting
                }
                onClick={this.reviewTimesheetAction}
              >
                Review &amp; Submit
              </Button>
            </CardPanel>
            <Table hoverable className="white">
              <thead>
                <tr>
                  <th className="center-align" width="3%" />
                  <th className="text-left align-middle" width="25%">
                    <span>Project</span>
                  </th>
                  <th className="text-left align-middle" width="10%">
                    <span>Task</span>
                  </th>
                  <th className="text-left align-middle" width="20%">
                    <span>Description</span>
                  </th>
                  <th
                    className="text-center align-middle"
                    width="5%"
                    data-balloon={moment(this.state.sunday).format("MM/DD")}
                    data-balloon-pos="up"
                  >
                    <span className="fa-stack">
                      {this.addCurrentDayCircle(0)}
                      <span
                        className={classnames("fa-stack-1x", {
                          "white-text":
                            moment().isAfter(this.state.sunday) &&
                            moment().isBefore(this.state.saturday) &&
                            moment().day() === 0
                        })}
                      >
                        Su
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-center"
                    width="5%"
                    data-balloon={moment(this.state.sunday)
                      .add(1, "days")
                      .format("MM/DD")}
                    data-balloon-pos="up"
                  >
                    <span className="fa-stack">
                      {this.addCurrentDayCircle(1)}
                      <span
                        className={classnames("fa-stack-1x", {
                          "white-text":
                            moment().isAfter(this.state.sunday) &&
                            moment().isBefore(this.state.saturday) &&
                            moment().day() === 1
                        })}
                      >
                        Mo
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-center"
                    width="5%"
                    data-balloon={moment(this.state.sunday)
                      .add(2, "days")
                      .format("MM/DD")}
                    data-balloon-pos="up"
                  >
                    <span className="fa-stack">
                      {this.addCurrentDayCircle(2)}
                      <span
                        className={classnames("fa-stack-1x", {
                          "white-text":
                            moment().isAfter(this.state.sunday) &&
                            moment().isBefore(this.state.saturday) &&
                            moment().day() === 2
                        })}
                      >
                        Tu
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-center"
                    width="5%"
                    data-balloon={moment(this.state.sunday)
                      .add(3, "days")
                      .format("MM/DD")}
                    data-balloon-pos="up"
                  >
                    <span className="fa-stack">
                      {this.addCurrentDayCircle(3)}
                      <span
                        className={classnames("fa-stack-1x", {
                          "white-text":
                            moment().isAfter(this.state.sunday) &&
                            moment().isBefore(this.state.saturday) &&
                            moment().day() === 3
                        })}
                      >
                        We
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-center"
                    width="5%"
                    data-balloon={moment(this.state.sunday)
                      .add(4, "days")
                      .format("MM/DD")}
                    data-balloon-pos="up"
                  >
                    <span className="fa-stack">
                      {this.addCurrentDayCircle(4)}
                      <span
                        className={classnames("fa-stack-1x", {
                          "white-text":
                            moment().isAfter(this.state.sunday) &&
                            moment().isBefore(this.state.saturday) &&
                            moment().day() === 4
                        })}
                      >
                        Th
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-center"
                    width="5%"
                    data-balloon={moment(this.state.sunday)
                      .add(5, "days")
                      .format("MM/DD")}
                    data-balloon-pos="up"
                  >
                    <span className="fa-stack">
                      {this.addCurrentDayCircle(5)}
                      <span
                        className={classnames("fa-stack-1x", {
                          "white-text":
                            moment().isAfter(this.state.sunday) &&
                            moment().isBefore(this.state.saturday) &&
                            moment().day() === 5
                        })}
                      >
                        Fr
                      </span>
                    </span>
                  </th>
                  <th
                    className="text-center"
                    width="5%"
                    data-balloon={moment(this.state.sunday)
                      .add(6, "days")
                      .format("MM/DD")}
                    data-balloon-pos="up"
                  >
                    <span className="fa-stack">
                      {this.addCurrentDayCircle(6)}
                      <span
                        className={classnames("fa-stack-1x", {
                          "white-text":
                            moment().isAfter(this.state.sunday) &&
                            moment().isBefore(this.state.saturday) &&
                            moment().day() === 6
                        })}
                      >
                        Sa
                      </span>
                    </span>
                  </th>
                  <th className="text-center align-middle" width="5%">
                    <span>Total</span>
                  </th>
                </tr>
              </thead>
              <tbody>{timesheetTableContent}</tbody>
              <tfoot>
                <tr style={{ height: "30px" }}>
                  <td />
                  <td />
                  <td />
                  <td className="right-align">Daily Total</td>
                  <td className="center-align">{this.state.sundayTotal}</td>
                  <td className="center-align">{this.state.mondayTotal}</td>
                  <td className="center-align">{this.state.tuesdayTotal}</td>
                  <td className="center-align">{this.state.wednesdayTotal}</td>
                  <td className="center-align">{this.state.thursdayTotal}</td>
                  <td className="center-align">{this.state.fridayTotal}</td>
                  <td className="center-align">{this.state.saturdayTotal}</td>
                  <td className="center-align">{this.state.grandTotal}</td>
                </tr>
              </tfoot>
            </Table>
          </Card>
        </Fragment>
      );
    }
    if (this.state.isSubmitted) {
      tableInfoContent = (
        <Modal
          bottomSheet
          open
          header="Info"
          actions={
            <Button waves="light" modal="close" flat>
              Close
            </Button>
          }
        >
          To update your timesheet, select the Edit Timesheet button. This will
          unsubmit your timesheet.
        </Modal>
      );
      timesheetStatusContent = (
        <div className="timesheet-status">
          <i>
            Submitted: {moment(this.state.submittedOn).format("MMMM Do, YYYY")}{" "}
            at {moment(this.state.submittedOn).format("h:mm a")}
          </i>
        </div>
      );
    } else if (this.state.empty) {
      tableInfoContent = (
        <Modal
          bottomSheet
          open
          header="Info"
          options={{ onCloseEnd: () => this.reviewModalClose() }}
        >
          You are unable to Review &amp; Submit an empty Timesheet. Please enter
          time in at least one of the rows.
        </Modal>
      );
      if (this.state.lastUpdatedTime) {
        timesheetStatusContent = (
          <span className="timesheet-status">
            <i>Last Modified: {moment(this.state.lastUpdatedTime).fromNow()}</i>
          </span>
        );
      } else {
        timesheetStatusContent = (
          <span className="timesheet-status">
            <i>Last Modified: Never</i>
          </span>
        );
      }
    } else {
      if (this.state.lastUpdatedTime) {
        timesheetStatusContent = (
          <span className="timesheet-status">
            <i>Last Modified: {moment(this.state.lastUpdatedTime).fromNow()}</i>
          </span>
        );
      } else {
        timesheetStatusContent = (
          <span className="timesheet-status">
            <i>Last Modified: Never</i>
          </span>
        );
      }
    }
    if (this.state.tableErrorInfo) {
      tableInfoContent = (
        <Modal bottomSheet open header="Alert">
          You must have at least one row.
        </Modal>
      );
    }
    return (
      <div
        className="container-fluid remove-padding"
        onKeyPress={this.handleKeyPress}
      >
        <span
          style={{
            fontSize: "16px",
            lineHeight: "24px"
          }}
        >
          <Button
            flat
            style={{ fontSize: "16px", lineHeight: "24px", height: "100%" }}
            onClick={() => this.sendDateChange("previous")}
          >
            <i className="fas fa-angle-left" />
          </Button>
          <Button
            style={{ fontSize: "16px", lineHeight: "24px", height: "100%" }}
            flat
            onClick={() => this.sendDateChange("next")}
          >
            <i className="fas fa-angle-right" />
          </Button>

          <span style={{ fontWeight: "bold" }}>Viewing Week:</span>
          <span>
            {" "}
            {moment(this.state.sunday)
              .local(false)
              .format("MMM D") +
              " - " +
              moment(this.state.saturday)
                .local(false)
                .format("MMM D")}
          </span>
        </span>
        <Row>
          {this.state.timesheetDashboard.map((obj, index) => {
            return (
              <Col key={index} xl={3} l={6} m={6} s={12}>
                <Goal
                  goal={obj.goal}
                  labels={obj.labels}
                  datasets={obj.datasets}
                  timesheetPage={true}
                />
              </Col>
            );
          })}
        </Row>
        <div>{tableInfoContent}</div>
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col s2 offset-s10 right-align">
            {timesheetStatusContent}
          </div>
        </div>
        {timesheetMain}
      </div>
    );
  }
}

TimesheetTable.propTypes = {
  selectedTimesheet: PropTypes.object,
  timesheetRows: PropTypes.object,
  getActiveProjects: PropTypes.func.isRequired,
  saveTimesheetTotals: PropTypes.func.isRequired,
  unsubmitTimesheet: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  receiveDeleteRequest: PropTypes.func,
  lastUpdated: PropTypes.func,
  dailyTotalFunc: PropTypes.func,
  saveTotals: PropTypes.func,
  error: PropTypes.string
};

const mapStateToProps = state => ({
  timesheet: state.timesheet,
  timesheetRow: state.timesheetRow,
  successes: state.successes,
  project: state.project,
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  {
    getActiveProjects,
    saveTimesheetTotals,
    saveTimesheet,
    unsubmitTimesheet,
    clearErrors,
    getTimesheetDashboard
  }
)(withRouter(TimesheetTable));
