import React from "react";
import { Preloader } from "react-materialize";

export default () => {
  return (
    <div className="text-center">
      <Preloader
        size="big"
        color="blue"
        style={{ margin: "auto", display: "block" }}
      />
    </div>
  );
};
