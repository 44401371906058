import React, { Component, Fragment } from 'react';
import TextFieldGroup from '../../common/TextFieldGroup';
import { editProject } from '../../../actions/adminActions';
import { connect } from 'react-redux';
import { Button, Modal, Switch, Row, Col, Icon } from 'react-materialize';

class ProjectRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      name: props.name,
      fullName: props.fullName,
      tasks: props.tasks,
      tasksNames: props.tasks.map((e) => e.name).join(','),
      status: props.isActive,
      isBilliable: props.isBillable,
      accruesPTO: props.accruesPTO,
      tempName: props.name,
      tempFullName: props.fullName,
      tempTasks: props.tasks,
      tempStatus: props.isActive,
      tempBillable: props.isBillable,
      tempAccruesPTO: props.accruesPTO,
    };

    this.onChange = this.onChange.bind(this);
    this.onTaskChange = this.onTaskChange.bind(this);
    this.editProjectSave = this.editProjectSave.bind(this);
    this.addTasks = this.addTasks.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onTaskChange(e) {
    let savedTasks = [];
    this.state.tempTasks.forEach((task) => {
      if (task._id.toString() === e.target.name.toString()) {
        task.name = e.target.value;
      }
      savedTasks.push(task);
    });
    this.setState({ tempTasks: savedTasks });
  }

  onStatusSelect = () => {
    this.setState((prevState) => {
      return { tempStatus: !prevState.tempStatus };
    });
  };

  onBillableSelect = () => {
    this.setState((prevState) => {
      return { tempBillable: !prevState.tempBillable };
    });
  };

  onAccrualSelect = () => {
    this.setState((prevState) => {
      return { tempAccruesPTO: !prevState.tempAccruesPTO };
    });
  };

  addTasks = (e) => {
    const newTask = {
      _id: this.state.tempTasks.length,
      name: '',
      totalHours: 0,
      isActive: true,
    };
    this.setState((prevState) => {
      prevState.tempTasks.push(newTask);
      return {
        tempTasks: prevState.tempTasks,
      };
    });
  };

  // onDeleteTask = e => {
  //   let taskIndex;
  //   if (Number.isInteger(e)) {
  //     this.setState(prevState => {
  //       taskIndex = this.state.tempTasks.findIndex(task => task._id === e);
  //       prevState.tempTasks.splice(taskIndex, 1);
  //       return {
  //         tempTasks: prevState.tempTasks
  //       };
  //     });
  //   } else {
  //     this.setState(prevState => {
  //       taskIndex = this.state.tempTasks.findIndex(task => task._id === e);
  //       prevState.tempTasks[taskIndex].isActive = false;
  //       return {
  //         tempTasks: prevState.tempTasks
  //       };
  //     });
  //   }
  // };

  handleOptionChange = (e) => {
    let taskIndex;
    this.setState((prevState) => {
      taskIndex = this.state.tempTasks.findIndex((task) => task._id === e);
      prevState.tempTasks[taskIndex].isActive =
        !prevState.tempTasks[taskIndex].isActive;
      return {
        tempTasks: prevState.tempTasks,
      };
    });
  };

  editProjectSave() {
    let savedTasks = [];
    this.state.tempTasks.forEach((task) => {
      if (Number.isInteger(task._id)) {
        delete task._id;
      }
      savedTasks.push(task);
    });

    const projectData = {
      id: this.state.id,
      fullName: this.state.tempFullName,
      name: this.state.tempName,
      tasks: savedTasks,
      isActive: this.state.tempStatus,
      isBillable: this.state.tempBillable,
      accruesPTO: this.state.tempAccruesPTO,
    };

    this.props.editProject(projectData);

    window.location.reload();
  }

  render() {
    let displayTasks = [];

    this.state.tempTasks.forEach((task, index) => {
      displayTasks.push(
        <div key={this.state.id + task._id}>
          <Row>
            <Col s={6}>
              <TextFieldGroup
                placeholder='Task'
                name={task._id.toString()}
                type='text'
                value={this.state.tempTasks[index].name}
                onChange={this.onTaskChange}
                size=''
              />
            </Col>
            <Col s={6}>
              <Switch
                id={'Task - ' + this.state.id + task._id}
                checked={task.isActive}
                onChange={() => this.handleOptionChange(task._id)}
                onLabel='Active'
                offLabel='Inactive'
              />
            </Col>
          </Row>
        </div>
      );
    });

    return (
      <tr>
        <td className='text-left px-4'>{this.state.fullName}</td>
        <td className='text-left align-middle px-4'>{this.state.tasksNames}</td>
        <td className='text-left align-middle px-4'>
          {this.state.status ? 'Active' : 'Inactive'}
        </td>
        <td className='text-center align-middle'>
          <div className='text-right align-middle'>
            <Modal
              className='modal-fix admin-modal'
              header={this.state.name}
              trigger={
                <Button
                  flat
                  icon={<Icon>edit</Icon>}
                  className='hide showOnHover'
                />
              }
              actions={
                <Fragment>
                  <Button flat modal='close'>
                    Close
                  </Button>
                  <Button
                    flat
                    className='blue-text'
                    type='submit'
                    onClick={this.editProjectSave}
                    data-dismiss='modal'
                  >
                    Save
                  </Button>
                </Fragment>
              }
            >
              <div className='divider' />
              <div className='modalPadding'>
                <Row>
                  <Col s={6}>
                    <h5 className='text-left'>Full Name</h5>
                    <TextFieldGroup
                      placeholder='Project Full Name'
                      name='tempFullName'
                      type='text'
                      value={this.state.tempFullName}
                      onChange={this.onChange}
                      size=''
                    />
                    <h5 className='text-left'>Display Name</h5>
                    <TextFieldGroup
                      placeholder='Display Name'
                      name='tempName'
                      type='text'
                      value={this.state.tempName}
                      onChange={this.onChange}
                      size=''
                    />
                    <h5 className='text-left'>Status</h5>
                    <div className='text-left mb-3'>
                      <Switch
                        id={'Status - ' + this.props.id}
                        checked={this.state.tempStatus}
                        onChange={this.onStatusSelect}
                        onLabel='Active'
                        offLabel='Inactive'
                      />
                    </div>
                    <h5 className='text-left'>Billable Work</h5>
                    <div className='text-left mb-3'>
                      <Switch
                        id={'Billable - ' + this.props.id}
                        checked={this.state.tempBillable}
                        onChange={this.onBillableSelect}
                        onLabel='Billable'
                        offLabel='Non-Billable'
                      />
                    </div>
                  </Col>
                  <Col s={6}>
                    <Row className='mb-0'>
                      <div className='valign-wrapper'>
                        <h5 className='text-left'>Tasks</h5>
                        <Button
                          small
                          floating
                          icon={<Icon>add</Icon>}
                          onClick={this.addTasks}
                          className='ml-2 blue'
                        />
                      </div>
                    </Row>
                    <div className='text-left'>{displayTasks}</div>
                  </Col>
                </Row>
              </div>
            </Modal>
          </div>
        </td>
      </tr>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { editProject })(ProjectRow);
