import {
  GET_ADMIN_TIMESHEETROWS,
  GET_ADMIN_USERS,
  GET_ADMIN_USER_TIMESHEET,
  CLEAR_ADMIN_USERS,
  CLEAR_USER_TIMESHEETS,
  CLEAR_ADMIN_PROJECTS,
  GET_ADMIN_DATES,
  GET_ADMIN_PROJECTS,
  GET_ADMIN_SUPERVISORS,
  CLEAR_ADMIN_SUPERVISORS,
  GET_ADMIN_SUPERVISOR_USERS,
  CLEAR_ADMIN_SUPERVISEES,
  GET_BAMBOOCSV,
  GET_ADMIN_EMPLOYEE_TYPES,
  CLEAR_ADMIN_EMPLOYEE_TYPES,
} from '../actions/types';

const initialState = {
  adminTimesheetRows: [],
  adminUsers: [],
  adminUserTimesheets: [],
  adminDates: [],
  adminProjects: [],
  adminSupervisors: [],
  adminSupervisees: [],
  adminBambooCSV: [],
  adminEmployeeTypes: [],
  adminTimesheetsLoading: true,
  adminUsersLoading: true,
  adminUsersTimesheetsLoading: true,
  adminDatesLoading: true,
  adminProjectsLoading: true,
  adminSupervisorsLoading: true,
  adminSuperviseesLoading: true,
  adminBambooLoading: true,
  adminEmployeeTypesLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_TIMESHEETROWS:
      return {
        ...state,
        adminTimesheetRows: action.payload,
        adminTimesheetsLoading: false,
      };
    case GET_ADMIN_USERS:
      return {
        ...state,
        adminUsers: action.payload,
        adminUsersLoading: false,
      };
    case GET_ADMIN_SUPERVISORS:
      return {
        ...state,
        adminSupervisors: action.payload,
        adminSupervisorsLoading: false,
      };
    case GET_ADMIN_SUPERVISOR_USERS:
      return {
        ...state,
        adminSupervisees: state.adminSupervisees.concat(action.payload),
        adminSuperviseesLoading: false,
      };
    case GET_ADMIN_USER_TIMESHEET:
      return {
        ...state,
        adminUserTimesheets: state.adminUserTimesheets.concat(action.payload),
        adminUsersTimesheetsLoading: false,
      };

    case GET_BAMBOOCSV:
      return {
        ...state,
        adminBambooCSV: action.payload,
        adminBambooLoading: false,
      };
    case GET_ADMIN_EMPLOYEE_TYPES:
      return {
        ...state,
        adminEmployeeTypes: action.payload,
        adminEmployeeTypesLoading: false,
      };
    case CLEAR_ADMIN_USERS:
      return {
        ...state,
        adminUsers: [],
        adminUsersLoading: false,
      };
    case CLEAR_ADMIN_SUPERVISORS:
      return {
        ...state,
        adminSupervisors: [],
        adminSupervisorsLoading: false,
      };
    case CLEAR_ADMIN_SUPERVISEES:
      return {
        ...state,
        adminSupervisees: [],
        adminSuperviseesLoading: false,
      };
    case CLEAR_USER_TIMESHEETS:
      return {
        ...state,
        adminUserTimesheets: [],
        adminUsersTimesheetsLoading: false,
      };
    case GET_ADMIN_DATES:
      return {
        ...state,
        adminDates: action.payload,
        adminDatesLoading: false,
      };
    case GET_ADMIN_PROJECTS:
      return {
        ...state,
        adminProjects: action.payload,
        adminProjectsLoading: false,
      };
    case CLEAR_ADMIN_PROJECTS:
      return {
        ...state,
        adminProjects: [],
        adminProjectsLoading: false,
      };
    case CLEAR_ADMIN_EMPLOYEE_TYPES:
      return {
        ...state,
        adminEmployeeTypes: [],
        adminEmployeeTypesLoading: false,
      };
    default:
      return state;
  }
}
