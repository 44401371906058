import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearErrors } from '../../../actions/timesheetActions';
import { getActiveProjects } from '../../../actions/projectActions';
import {
  getTimesheetTemplate,
  saveTimesheetTemplate,
} from '../../../actions/userActions';
import TimesheetTemplateRow from './TimesheetTemplateRow';
import isEmpty from '../../../validation/is-empty';
import { Button, CardPanel, Table } from 'react-materialize';
import classnames from 'classnames';

class TimesheetTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowTemplate: props.userSettings.timesheetTemplate
        ? props.userSettings.timesheetTemplate
        : [{ row: 0, project_id: null, task_id: null, description: '' }],
      isSaved: true,
      removeCheck: false,
      rowTemplateDeleteArray: [],
    };
    this.addRows = this.addRows.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.receiveDeleteRequest = this.receiveDeleteRequest.bind(this);
    this.updateProjectInRow = this.updateProjectInRow.bind(this);
    this.updateTaskInRow = this.updateTaskInRow.bind(this);
    this.updateDescriptionInRow = this.updateDescriptionInRow.bind(this);
    this.resetCheck = this.resetCheck.bind(this);
  }
  componentDidMount() {
    this.props.getActiveProjects(null, null);
    this.props.clearErrors();
    this.props.getTimesheetTemplate(this.props.auth.user.email);
    window.addEventListener('keydown', this.handleAddRow);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleAddRow);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userSettings.timesheetTemplate !==
      prevProps.userSettings.timesheetTemplate
    ) {
      this.setState({ rowTemplate: this.props.userSettings.timesheetTemplate });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.successes !== nextProps.successes) {
      if (!isEmpty(nextProps.successes)) {
        this.setState({ isSaved: true });
      }
    }
  }

  addRows() {
    const newRow = {
      row: this.state.rowTemplate.length,
      project_id: null,
      task_id: null,
      description: null,
    };
    this.setState((prevState) => {
      prevState.rowTemplate.push(newRow);
      return {
        rowTemplate: prevState.rowTemplate,
      };
    });
  }

  saveTemplate() {
    let savePayload = {
      timesheetTemplate: this.state.rowTemplate,
      email: this.props.auth.user.email,
    };
    this.props.saveTimesheetTemplate(savePayload);
  }

  updateProjectInRow = (rowNumber, projectId) => {
    if (projectId === '') {
      projectId = null;
    }
    this.setState((prevState) => {
      for (let i = 0; i < prevState.rowTemplate.length; i++) {
        if (prevState.rowTemplate[i].row === rowNumber) {
          prevState.rowTemplate[i].project_id = projectId;
          prevState.rowTemplate[i].task_id = null;
        }
      }
      return {
        rowTemplate: prevState.rowTemplate,
      };
    });
    this.setState({ isSaved: false });
  };

  updateTaskInRow = (rowNumber, taskId) => {
    this.setState((prevState) => {
      for (let i = 0; i < prevState.rowTemplate.length; i++) {
        if (prevState.rowTemplate[i].row === rowNumber) {
          prevState.rowTemplate[i].task_id = taskId;
        }
      }
      return {
        rowTemplate: prevState.rowTemplate,
      };
    });
    this.setState({ isSaved: false });
  };

  updateDescriptionInRow = (rowNumber, description) => {
    this.setState((prevState) => {
      for (let i = 0; i < prevState.rowTemplate.length; i++) {
        if (prevState.rowTemplate[i].row === rowNumber) {
          prevState.rowTemplate[i].description = description;
        }
      }
      return {
        rowTemplate: prevState.rowTemplate,
      };
    });
    this.setState({ isSaved: false });
  };

  handleAddRow = (e) => {
    if (e.altKey && e.key === 'a') {
      this.addRows();
    }
    this.setState({ isSaved: false });
  };

  resetCheck = () => {
    this.setState({ removeCheck: false });
  };

  receiveDeleteRequest = (e) => {
    let initialSize = this.state.rowTemplate.length;
    let setUncheck =
      this.state.rowTemplateDeleteArray.length === initialSize ? true : false;
    this.setState(
      (prevState) => {
        prevState.rowTemplateDeleteArray.forEach((element) => {
          let foundIndex = prevState.rowTemplate.findIndex(
            (index) => index.row === element
          );
          if (
            initialSize === this.state.rowTemplateDeleteArray.length &&
            element === 0
          ) {
            prevState.rowTemplate[foundIndex].project_id = null;
            prevState.rowTemplate[foundIndex].task_id = null;
            prevState.rowTemplate[foundIndex].description = '';
          } else {
            prevState.rowTemplate.splice(foundIndex, 1);
          }
        });
        return {
          rowTemplate: prevState.rowTemplate,
          removeCheck: setUncheck,
        };
      },
      () =>
        this.setState(
          (prevState) => {
            for (let j = 0; j < prevState.rowTemplate.length; j++) {
              prevState.rowTemplate[j].row = j;
            }
            return {
              rowTemplate: prevState.rowTemplate,
              rowTemplateDeleteArray: [],
            };
          },
          () => {
            this.saveTemplate();
          }
        )
    );
    this.setState({ isSaved: false });
  };

  processDeleteRequest = (rowNum) => {
    let foundIndex = this.state.rowTemplateDeleteArray.findIndex(
      (index) => index === rowNum
    );
    if (foundIndex > -1) {
      this.setState((prevState) => {
        prevState.rowTemplateDeleteArray.splice(foundIndex, 1);
        return {
          rowTemplateDeleteArray: prevState.rowTemplateDeleteArray,
        };
      });
    } else {
      this.setState((prevState) => {
        prevState.rowTemplateDeleteArray.push(rowNum);
        return {
          rowTemplateDeleteArray: prevState.rowTemplateDeleteArray,
        };
      });
    }
  };

  render() {
    let templateRowsContent, saveMessage;
    if (this.state.isSaved) {
      saveMessage = (
        <i className={classnames('successfully-saved hide-opacity')}>
          All changes saved
        </i>
      );
    } else {
      saveMessage = <i>Changes not saved</i>;
    }

    templateRowsContent = this.state.rowTemplate.map((Row) => (
      <TimesheetTemplateRow
        key={Row.row}
        row={Row.row}
        selectedProject={Row.project_id}
        task={Row.task_id}
        description={Row.description}
        processDeleteRequest={this.processDeleteRequest}
        updateProjectInRow={this.updateProjectInRow}
        updateTaskInRow={this.updateTaskInRow}
        updateDescriptionInRow={this.updateDescriptionInRow}
        uncheck={this.state.removeCheck}
        resetCheck={this.resetCheck}
      />
    ));
    return (
      <div className='text-center'>
        <CardPanel
          className='tableHeaderBar text-left'
          style={{
            fontSize: '16px',
            lineHeight: '36px',
            paddingLeft: '1.5rem',
          }}
        >
          Timesheet Template
        </CardPanel>
        <CardPanel className='tableHeaderBar'>
          <div
            className='text-right'
            style={{ fontSize: '16px', height: '36px' }}
          >
            <Button flat className='blue-text left' onClick={this.addRows}>
              Add
            </Button>
            <Button
              flat
              className='blue-text left'
              disabled={this.state.isSubmitted || this.state.isErrors}
              onClick={this.receiveDeleteRequest}
            >
              Remove
            </Button>
            <span className='mr-3 text-right' style={{ lineHeight: '36px' }}>
              {saveMessage}
            </span>
            <Button
              className='right white blue-text px-3'
              onClick={this.saveTemplate}
            >
              Save
            </Button>
          </div>
        </CardPanel>
        <Table centered hoverable className='table white'>
          <thead>
            <tr>
              <th className='center-align' width='4%' />
              <th className='text-center'>Project</th>
              <th className='text-center'>Task</th>
              <th className='text-center'>Description</th>
            </tr>
          </thead>
          <tbody>{templateRowsContent}</tbody>
          <tfoot />
        </Table>
        <div className='text-right pb-3'>
          <i>
            Note: This template will automatically be used for next week’s
            timesheet.
          </i>
        </div>
        <div className='text-right' />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userSettings: state.userSettings,
  successes: state.successes,
  project: state.project,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getActiveProjects,
  getTimesheetTemplate,
  saveTimesheetTemplate,
  clearErrors,
})(TimesheetTemplate);
